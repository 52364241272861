import http from "src/http";

class ChatService {
  getRooms() {
    return new Promise((resolve, reject) => {
      http
        .get("/chat/rooms")
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getRoomMessages(hash) {
    return new Promise((resolve, reject) => {
      http
        .get(`/chat/room-messages/${hash}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  join({ hash }) {
    return new Promise((resolve, reject) => {
      console.log(hash);
      http
        .post("/chat/join", { hash: hash })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new ChatService();
