<script setup>
import { ref, computed, reactive, onMounted, watch, nextTick } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'
import { useTranslations } from '@/composables/translations'

const { translate } = useTranslations()

const props = defineProps({
  field: {
    default: () => {},
    type: Object
  },
  validationErrors: {
    default: () => {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  multipleChoiceValue: [],
  singleChoiceValue: {}
})

const multipleChoiceParentDiv = ref(null)

onMounted(() => {
  data.fieldData = { ...props.field }
  if (isSingleChoice.value) {
    data.singleChoiceValue = data.fieldData.value && data.fieldData.value.length > 0 ? data.fieldData.value[0] : {}
  } else {
    data.multipleChoiceValue = data.fieldData.value ?? []
  }
})

const rules = computed(() => ({
  multipleChoiceValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, data)

const isSingleChoice = computed(() => data.fieldData.max_choices === 1)

const singleChoiceOptions = computed(() => {
  const options = data.fieldData.options ? [...data.fieldData.options] : []

  options.unshift({
    id: null,
    description: '',
    max: null
  })

  return options
})

const assistiveText = computed(() => {
  if (data.fieldData.min_choices && !data.fieldData.max_choices) {
    return translate('multichoice_assistive_min', { min: data.fieldData.min_choices })
  }

  if (!data.fieldData.min_choices && data.fieldData.max_choices) {
    return translate('multichoice_assistive_max', { max: data.fieldData.max_choices })
  }

  if (Number.isInteger(data.fieldData.min_choices) && Number.isInteger(data.fieldData.max_choices)) {
    const translationsValues = { min: data.fieldData.min_choices, max: data.fieldData.max_choices }

    return data.fieldData.min_choices === data.fieldData.max_choices ?
      translate('multichoice_assistive_min_max_equal', translationsValues) :
      translate('multichoice_assistive_min_max_different', translationsValues)
  }

  return ""
})

const maxChoicesAchieved = computed(() => {
  if (!data.fieldData.max_choices || isSingleChoice.value) {
    return false
  }

  return data.multipleChoiceValue.length >= data.fieldData.max_choices
})

const multipleChoicePanelWidth = computed(() => {
  return multipleChoiceParentDiv.value ? multipleChoiceParentDiv.value.offsetWidth : 687
})

const disableOption = (option) => {
  if (maxChoicesAchieved.value) {
    return true
  }

  if (option.max) {
    return option.max <= option.count
  }

  return false
}

const removeOption = (option) => {
  data.multipleChoiceValue = data.multipleChoiceValue.filter((value) => value.id !== option.id)
}

const optionCounter = (option) => {
  return `(${option.count}/${option.max})`
}

const multiSelect = ref(null)

// Remove the highlight from the first option when the dropdown is shown
const onShow = () => {
  // Wait for the next tick to ensure the overlay is rendered
  nextTick(() => {
    const overlay = multiSelect.value.$el.querySelector('.p-multiselect-panel');
    if (overlay) {
      const firstOption = overlay.querySelector('.p-multiselect-item');
      if (firstOption) {
        firstOption.classList.remove('p-highlight');
      }
    }
  });
};

watch(() => data.multipleChoiceValue, (newValue, oldValue) => {
  emit('setValue', {
    value: data.multipleChoiceValue,
    oldValue: oldValue,
    fieldHandle: data.fieldData.handle
  })
})

watch(() => data.singleChoiceValue, (newValue, oldValue) => {
  emit('setValue', {
    value: newValue.id ? [data.singleChoiceValue] : [],
    oldValue: oldValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div 
    v-if="data.fieldData && data.fieldData.options && v$.multipleChoiceValue"
    class="mm-custom-form"
  >
    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.multipleChoiceValue, field: data.fieldData, fieldValue: data.multipleChoiceValue }"
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />

      <div 
        v-if="data.fieldData.options?.length"
        ref="multipleChoiceParentDiv"
        class="multiple-choice span-input"
      >
        <div
          v-if="assistiveText && !isSingleChoice"
          class="assistive-text"
        >
          <span> {{ assistiveText }}</span>
        </div>
        <Dropdown
          v-if="isSingleChoice"
          :id="data.fieldData.handle"
          v-model="data.singleChoiceValue"
          :options="singleChoiceOptions"
          :placeholder="data.fieldData.placeholder"
          option-label="description"
          class="form-control"
          :aria-labelledby="data.fieldData.handle"
          :option-disabled="disableOption"
        >
          <template #value="slotProps">
            <span data-cy="multiple-input">
              {{ slotProps.value.description ?? '&nbsp;' }}
            </span>
          </template>
          <template #option="slotProps">
            <div
              data-cy="multiple-choice-options"
              class="option"
            >
              <span :data-cy="`multiple-choice-option-${slotProps.option.id}`">
                {{ slotProps.option.description }} <small
                  v-if="slotProps.option.max && data.fieldData.show_max"
                  class="fw-normal ps-1"
                >{{ optionCounter(slotProps.option) }}</small>
              </span>
            </div>
          </template>
        </Dropdown>
        <MultiSelect
          v-else
          :id="data.fieldData.handle"
          ref="multiSelect"
          v-model="data.multipleChoiceValue"
          :options="data.fieldData.options"
          :placeholder="data.fieldData.placeholder"
          option-label="description"
          :selection-limit="data.fieldData.max_choices"
          class="form-control"
          :aria-labelledby="data.fieldData.handle"
          :option-disabled="disableOption"
          display="chip"
          :panel-style="{ maxWidth: `${multipleChoicePanelWidth}px` }"
          @show="onShow"
        >
          <template #value="slotProps">
            <span
              v-if="slotProps.value.length === 0" 
              data-cy="multiple-input"
            > {{ '&nbsp;' }}</span>
            <div class="selected-values">
              <div
                v-for="(option, index) in slotProps.value"
                :key="index"
                class="selected-value"
              >
                <div class="remove-option">
                  {{ option.description }}
                  <i
                    v-if="!data.fieldData.disabled"
                    class="icon-false-close"
                    role="button"
                    tabindex="0"
                    :aria-label="translate('remove_option', { description: option.description })"
                    :data-cy="`remove-option-${option.id}`"
                    @click="removeOption(option)"
                    @keydown.enter="removeOption(option)"
                    @keydown.space="removeOption(option)"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #option="slotProps">
            <div
              :data-cy="`multiple-choice-option-${slotProps.option.id}`"
              class="option"
            >
              {{ slotProps.option.description }} <small
                v-if="slotProps.option.max && data.fieldData.show_max"
                class="fw-normal ps-1"
              >{{ optionCounter(slotProps.option) }}</small>
            </div>
          </template>
        </MultiSelect>
        <ValidationIcon
          :show-select-arrow="true" 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.multipleChoiceValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.multipleChoiceValue"
        />
      </div>
      
      <ValidationErrors 
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle" 
      />
    </div>
  </div>
</template>

<style lang="scss" type="text/scss" scoped>
.assistive-text {
  font-size: 12px
}

.selected-values {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}

.option {
  min-height: 27px;
  white-space: normal;
}

.selected-value {
  padding: 5px 30px 5px 10px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 2px;
  max-width: 95%;
  position: relative;

  div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div > i {
    position: absolute;
    right: 5px;
    top: 10px;
    border-radius: 6px;
    opacity: 0.8;
  }

  .remove-option i {
    font-size: 13px;
    font-weight: bold;
    padding-right: 0.5px;
    margin: 0 5px;
  }
}
</style>