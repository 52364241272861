import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import moment from 'moment-timezone'
import ApplicationService from '@/services/ApplicationService'
import PasswordService from '@/services/PasswordService'

import { useAppLanguages } from '@/stores/app-languages'
import { useAppTranslations } from '@/stores/app-translations'
import { useAppTemplate } from '@/stores/app-template'
import { useAppWidgets } from '@/stores/app-widgets'
import { useAppPages } from '@/stores/app-pages'
import { useAppLounge } from '@/stores/app-lounge'
import { useVisitor } from '@/stores/visitor'
import { useAppRegistration } from '@/stores/app-registration'
import * as statusses from '@/stores/statusses'

export const useApp = defineStore('app', () => {
  const eventState = ref({})
  const statusState = ref(statusses.APP_LOADING)
  const errorState = ref(null)
  const initialTranslationsState = ref({})

  // TODO: Vue 3 upgrade: ready is not used
  const event = computed(() => eventState.value)
  const published = computed(() => eventState.value.published)
  const status = computed(() => statusState.value)
  const error = computed(() => errorState.value)
  const initialTranslations = computed(() => initialTranslationsState.value)

  function getLangData() {
    const languageId = null
    const lang = navigator.language || navigator.userLanguage

    return {
      lang,
      id: languageId
    }
  }

  function getInitData() {
    const getParam = (name, cs = true) => {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
      let regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
      if (!cs) {
        regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i')
      }
      const results = regex.exec(window.location.search)
      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '))
    }
  
    return {
      language: getLangData(),
      timezone: moment.tz.guess(),
      cvid: getParam('cvid'),
      init: getParam('init')
    }
  }

  function init() {
    const appLanguagesStore = useAppLanguages()
    const appTranslationsStore = useAppTranslations()
    const appTemplateStore = useAppTemplate()
    const appWidgetsStore = useAppWidgets()
    const appPagesStore = useAppPages()
    const appLoungeStore = useAppLounge()
    const visitorStore = useVisitor()
    const registrationStore = useAppRegistration()

    return new Promise((resolve) => {
      ApplicationService.initApplication(getInitData()).then((data) => {
        const { registration, purchase } = data
        const lang = data.event.languages.find((l) => l.active)

        eventState.value = data.event
        statusState.value = data.status
        initialTranslationsState.value = data.translations

        appLanguagesStore.setLanguages(data.event.languages)
        appLanguagesStore.currentLanguage = lang ? lang.language_id : null
        appPagesStore.load(data.event.pages)
        appWidgetsStore.load(data.event.widgets)
        appLoungeStore.load(data)
        appTemplateStore.load(data.event.template)
        appTranslationsStore.translationsState.value = data.translations
        appTranslationsStore.load(data.translations).then(() => {})

        visitorStore.load(data)

        if (registration && registration.purchase) {
          registrationStore.load(registration)
        }

        if (purchase) {
          registrationStore.setRootPurchase(purchase)
        }

        statusState.value = data.password_required ? statusses.PASSWORD_REQUIRED : statusses.APP_READY

        resolve()
      }).catch((err) => {
        window.handle_error(err, 'app init')
      })
    })
  }

  function postPassword(password) {
    return new Promise((resolve, reject) => {
      PasswordService.postPassword(password).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function set404(data) {
    if (data) {
      errorState.value = Object.assign({}, data, { type: '404_page_not_found' })
    } else {
      errorState.value = null
    }
  }

  function setStatus(status) {
    statusState.value = status
  }

  return {
    eventState,
    statusState,
    errorState,
    initialTranslationsState,
    event,
    published,
    status,
    error,
    initialTranslations,
    init,
    postPassword,
    set404,
    setStatus
  }
})