<template>
</template>
<script>
    import { mapStores } from 'pinia'
    import { useAppLanguages } from '@/stores/app-languages'
    import { useAppPages } from '@/stores/app-pages'

    export default {
      name: 'livestream',
      data () {
        return {
          hash: null
        }
      },
      computed: {
        ...mapStores(useAppLanguages, useAppPages),
        livestreamPage () {
          const lang = this.appLanguagesStore.activeLanguage
          return this.appPagesStore.all.find(p => p.type === 'live' && p.language_id === lang)
        },
        programPage () {
          const lang = this.appLanguagesStore.activeLanguage
          return this.appPagesStore.all.find(p => p.type === 'program' && p.language_id === lang)
        }
      },
      created () {
        // Check livestream page
        let page = this.livestreamPage;
        if(!page) {
          // Check program page
          page = this.programPage;
        }

        let url = '/page/' + page.page_id
        if (this.$route.params.hash) {
          url += '/' + this.$route.params.hash
        }

        this.$router.replace(url)
      }
    }
</script>
