<template>
    <div class="venues-container">
        <div class="page-venues" v-if="!details">
            <VenueOverview :venues="page.venues"></VenueOverview>
        </div>
        <div class="page-venue" v-else>
            <div class="container" v-touch:swipe.left="swipeLeft"
                              v-touch:swipe.right="swipeRight"
                              @keyup.prevent.left="swipeLeft"
                              @keyup.prevent.right="swipeRight">

                <VenueDetail :venue="details" ></VenueDetail>

                <div class="row justify-content-center">
                    <div class="col-lg-8" v-if="page.venues.length > 1 || details.content || details.subpages"><hr/></div>
                    <div class="col-lg-8" v-html="details.content"></div>
                    <div v-for="(subpage, index) in details.subpages" :key="index" class="col-lg-8 subpage">
                        <h2 class="subpage_title">{{ subpage.title }}</h2>
                        <div class="mm-subpage-img-wrapper text-center">
                            <img :src="subpage.image" class="img-fluid" v-if="subpage.image" :alt="subpage.title" />
                        </div>
                        <div class="mm-html mm-html-venue" v-if="subpage.content" v-html="subpage.content"></div>
                    </div>
                    <div class="col-lg-8" v-if="page.venues.length > 1 && (details.content || details.subpages)"><hr/></div>
                </div>

                <detail-navigation v-if="page.venues.length > 1"
                                   :indexRoute="{ name: 'page', params: { pageId: page.page_id }}"
                                   :nextRoute="nextRoute"
                                   :previousRoute="previousRoute">
                </detail-navigation>
            </div>
        </div>
        <div id="map" v-show="showMap" :class="{isOverviewPage : !details}"></div>
    </div>
</template>

<script>
  var mapsapi = require('google-maps-api')(process.env.ANON_CONFIG_1)

  import detailNavigationMixin from '../../mixins/detailNavigationMixin'
  import VenueOverview from "../../components/Venues/VenueOverview"
  import VenueDetail from "../../components/Venues/VenueDetail"

  export default {
    name: 'Venues',
    components: {
      VenueOverview,
      VenueDetail
    },
    props: [
      'page'
    ],
    mixins: [
      detailNavigationMixin
    ],
    mounted () {
      this.initMap()
    },
    computed: {
      hasVenueWithMapsEnabled () {
        let hasVenueWithMapsEnabled = this.page.venues.filter(venue => venue.maps === 'yes').length
        return hasVenueWithMapsEnabled >= 1
      },
      showMap () {
        let { details = {}, isOverviewPage, hasVenueWithMapsEnabled } = this
        switch (true) {
          case (details.maps === 'yes'):
            return true
          case (isOverviewPage && hasVenueWithMapsEnabled):
            return true
          default:
            return false
        }
      },
      isOverviewPage () {
        let { params = {} } = this.$route
        return !params.param1
      },
      navigationObjects () {
        return this.page.venues
      },
      details () {
        if (this.page.venues.length === 1) {
          return this.page.venues[0]
        }

        return this.page.venues.find(p => p.id === parseInt(this.$route.params.param1))
      }
    },
    methods: {
      swipeLeft (e) {
        if (e && e.preventDefault) {
          e.preventDefault()
        }
        if (this.nextRoute) {
          this.$router.push(this.nextRoute)
        }
      },
      swipeRight (e) {
        if (e && e.preventDefault) {
          e.preventDefault()
        }
        if (this.previousRoute) {
          this.$router.push(this.previousRoute)
        }
      },
      initMap () {
        if (this.details) {
          let that = this
            mapsapi().then(function (maps) {
            // use the google.maps object as you please
            let geocoder = new maps.Geocoder()

            geocoder.geocode({ 'address': that.details.address + ', ' + that.details.city }, function (results, status) {
              if (status === 'OK') {
                let location = results[0].geometry.location

                let stylez = [
                  {
                    featureType: 'all',
                    elementType: 'all',
                    stylers: [
                      { saturation: -100 }
                    ]
                  }
                ]

                let map = new maps.Map(document.getElementById('map'), {
                  center: location,
                  zoom: 13,
                  zoomControl: false,
                  disableDoubleClickZoom: true,
                  mapTypeControl: false,
                  draggable: false,
                  scrollwheel: false,
                  scaleControl: false,
                  streetViewControl: false
                })

                let mapType = new maps.StyledMapType(stylez, { name: 'Grayscale' })

                map.mapTypes.set('tehgrayz', mapType)
                map.setMapTypeId('tehgrayz')

                let marker = new maps.Marker({
                  position: location,
                  map: map,
                  title: that.details.title
                })

                maps.event.addListener(marker, 'click', function () {
                  window.open(`https://www.google.com/maps/search/?api=1&query=${location.lat()},${location.lng()}`, '_blank')
                })

                return marker
              }
            })
          })
        } else {
          mapsapi().then(maps => {
            let stylez = [
              {
                featureType: 'all',
                elementType: 'all',
                stylers: [
                  {saturation: -100}
                ]
              }
            ]

            var map = new maps.Map(document.getElementById('map'), {
              zoom: 13,
              zoomControl: false,
              disableDoubleClickZoom: true,
              mapTypeControl: false,
              draggable: false,
              scrollwheel: false,
              scaleControl: false,
              streetViewControl: false
            })

            let mapType = new maps.StyledMapType(stylez, {name: 'Grayscale'})

            map.mapTypes.set('tehgrayz', mapType)
            map.setMapTypeId('tehgrayz')

            var bounds = new maps.LatLngBounds()
            for (let venue of this.page.venues) {
              let location = venue.geo
              if (venue.maps === 'yes' && location && location.lat && location.lng) {
                let latlng = new maps.LatLng(location.lat, location.lng)
                bounds.extend(latlng)
                map.fitBounds(bounds)

                let marker = new maps.Marker({
                  position: latlng,
                  map: map,
                  title: venue.title
                })

                maps.event.addListener(marker, 'click', function () {
                  window.open(`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`, '_blank')
                })
              }
            }
          })
        }
      }
    },
    watch: {
      '$route.params' () {
        this.$nextTick(() => {
          this.initMap()
        })
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.venues-container
  flex: 1
  display: flex
  flex-direction: column
  padding-bottom: 0 !important

  .mm-html-venue
    padding-left: 0px !important
    padding-right: 0px !important

  .page-venue
    padding-bottom: 65px

    .specs
      padding: 30px 0px 10px 0px

      h1
        font-weight: 600

    h2.subpage_title
      font-weight: 600
      text-align: left

    i
      cursor: pointer
      font-size: 2rem
      padding: 5px

    a
      color: inherit
      text-decoration: underline
#map
  width: 100%
  height: 400px
  &.isOverviewPage
    margin-top: 25px
</style>
