<template>
	<div id="lounge-ticker-tape-container" v-if="ready">
      <form class="ticker-messages-form" @submit.prevent="send" ref="form">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="newMessage"
            placeholder="Start typing..."
            autocomplete="off"
          />
          <span v-on:click="submit" class="icon-arrow-right lounge-chat-send-btn" :title="translate('chat_send')"></span>
        </div>
      </form>
      <transition-group name="ticker" tag="div" class="ticker-messages" appear>
      	<div class="ticker-message-item" v-for="message in messages" :key="message.message">
	        <div class="ticker-message-author">{{ message.user }}</div>
	        <div class="ticker-message-content">{{ message.message }}</div>
	        <div class="ticker-message-divider">
	          <div class="ticker-message-middot"></div>
	        </div>
    	  </div>
      </transition-group>
    </div>
</template>

<script>

import { env } from "@/env";
import { io } from "socket.io-client"
import { mapStores } from 'pinia'
import { useVisitor } from '@/stores/visitor'

export default {
  props: {
    widget: Object
  },
  data() {
    return {
      newMessage: null,
      messages: [],
      ready: false,
      socket: io(env.CHAT_SERVER + "/" + this.widget.values.ticker_hash, {
        forceNew: true,
        secureConnection: true,
        reconnection: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        transports: ["websocket", "polling"],
        query: {
          token: localStorage.getItem("x-momice-token"),
          eventUrl: window.location.hostname,
          hash: this.widget.values.ticker_hash
        }
      }),
    };
  },
  beforeUnmount() {
    this.socket.close();
  },
  created() {

    this.socket.on("chat-message", data => {
      this.messages.unshift({
        message: data.message,
        type: 1,
        user: data.user
      });
    });

    this.socket.on("history", data => {
      data.forEach(element => {
        this.messages.unshift({
          message: element.message,
          type: 1,
          user: element.name
        });
      });
    });

    this.socket.on("error", data => {
      this.socket = null;
    });

    this.socket.on("connect_error", data => {
      this.socket = null;
    });

    this.socket.on("reconnect_failed", data => {
      this.socket = null;
    });

  },
  computed: {
    ...mapStores(useVisitor),
    invite () {
      return this.visitorStore.invite
    },
    inviteInited () {
      return this.visitorStore.inited
    },
    inviteName () {
      let lastname = this.invite.infix != '' ? this.invite.infix+' '+this.invite.lastname : this.invite.lastname;
      return this.inviteInited ? this.invite.firstname + " " + lastname : this.translate('anonymous_chat_user');
    }
  },
  methods: {
    send() {
      if(this.newMessage !== null) {
        this.messages.unshift({
          message: this.newMessage,
          type: 0,
          user: this.inviteName
        });
        this.socket.emit("chat-message", {
          message: this.newMessage,
          user: this.inviteName
        });
        this.newMessage = null;
      }
    },
    submit : function(){
      this.send()
    }
  },
  mounted() {
    if(this.widget.values.ticker_hash !== undefined && this.widget.values.ticker_hash != ''){
      this.ready = true;
    }
  }
};

</script>

<style lang="css" type="text/css" scoped>

#lounge-ticker-tape-container {
  height: 40px;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
}
.ticker-messages-form {
  z-index: 1;
}
.ticker-messages {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
}
.ticker-message-item {
  display: flex;
  align-items: center;
}
.ticker-message-author {
  font-size: 14px;
  line-height: 16px;
  color: #006F72;
  padding-right: 12px;
  width: max-content;
}
.ticker-message-content {
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  white-space: nowrap;
}
.ticker-message-divider {
  padding: 24px;
}
.ticker-message-middot {
  background-color: #006F72;
  height: 4px;
  width: 4px;
}
/** ticker transition **/
.ticker-enter{
  opacity: 0;
}
.ticker-enter-active{
  transition: opacity 1s;
}
.ticker-move {
  transition: transform 1s;
}
.form-group{
  margin: 0;
  min-width: 380px
}
.form-control {
  width: 338px;
  float: left;
  height: 40px;
  border: 0;
  padding-left: 20px;
  background: #FFFFFF;
}
.lounge-chat-send-btn {
  float: left;
  height: 40px;
  text-align: left;
  font-size: 42px;
  cursor: pointer;
  line-height: 40px;
  background: #FFFFFF;
}

</style>
