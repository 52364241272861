<template>
  <div class="page-shortcut mm-page" :class="{ 'has-image': page.image }">
    <QRcodeWidget
      class="shortcut_qrcode base-widget"
      :style="`color:${colors('5')};background-color: ${colors('2')};`"
      :widget="widget"
    />
    <ImageHeader :imageUrl="page.image"></ImageHeader>
    <div class="container">
        <div class="row content mm-html shortcut-content mt-5" v-html="this.page.content"></div>

        <Subpage v-for="(subpage, index) in this.page.subpages" :key="index" :subpage="subpage" />
    </div>
  </div>
</template>

<script>
  import QRcodeWidget from 'widgets/qrcode/qrcode-v1'
  import { Subpage } from 'components'
  import ImageHeader from "../../components/ImageHeader.vue";
  export default {
    components: {
      ImageHeader,
      QRcodeWidget,
      Subpage
    },
    props: ['page'],
    computed: {
      widget () {
        return {
          values: {
            text: this.translate('desktop_scan_qrcode'),
            url: window.location.origin
          }
        }
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.shortcut-content p
  top: 0 !important

@include media-breakpoint-down(lg)
  .page-shortcut
    padding-top: 0 !important
.page-shortcut
  padding-top: 240px
  padding-bottom: 72px
  &.has-image
    padding-top: 0
  .image
    width: 100%
    max-height: 550px
    background-size: cover
    background-position: center center
  .subpage
    padding-bottom: 20px

    h2
      text-align: left
      font-weight: 600
    img
      padding: 20px 0 30px 0
</style>
