export default {
  methods: {
    getRootDomainFromUrl (url) {
      try {
        let url_object = new URL(url);
        let domain = url_object.hostname;
        return domain
      } catch (error) {
        // Fallback for (old) browsers that do not support the URL API
        return url;
      }
    }
  }
}
