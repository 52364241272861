<template>
  <div class="page-sitemap mm-page page-flex">
    <div class="container inner">
      <div class="row mm-html">
        <h2>Sitemap</h2>
        <ul class="mb-0">
          <li class="mb-1" v-for="(page, indexPage) in sitemap" :key="indexPage">
            <a
              v-if="page.url.includes('https')"
              :href="page.url"
              target="_blank"
            >
              {{ page.title }}
            </a>
            <router-link
              v-else
              :to="page.url"
            >
              {{ page.title }}
            </router-link>
            <ul class="mb-0 ms-3" v-if="page.subpages.length">
              <li class="mb-1" v-for="(sub, indexSubpage) in page.subpages" :key="indexSubpage">
                <a
                  v-if="sub.url.includes('https')"
                  :href="sub.url"
                  target="_blank"
                >
                  {{ sub.title }}
                </a>
                <router-link
                  v-else
                  :to="sub.url"
                >
                  {{ sub.title }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAppPages } from '@/stores/app-pages'
import { useAppLanguages } from '@/stores/app-languages'

export default {
  name: 'sitemap',
  computed: {
    ...mapStores(useAppPages, useAppLanguages),
    sitemap() {
      return this.appPagesStore.sitemap
    },
    activeLanguage() {
      return this.appLanguagesStore.active
    },
  },
  methods: {
    redirectTo(url){
      if(url.includes('https')){
        window.open(url)
      }else{
        this.navigateTo(url)
      }
    },
    pageTitle() {
      return this.appLanguagesStore.pageTitle('Sitemap')
    }
  },
  created () {
    document.title = this.pageTitle()
    this.appPagesStore.setSitemap()
  }
}
</script>

<style lang="sass" type="text/sass" scoped>
a
  margin-bottom: 5px
  line-height: 1.3rem
  text-decoration: none !important
  cursor: pointer
  color: inherit

a:focus-visible, a:hover
  text-decoration: underline !important
  color: inherit
</style>
