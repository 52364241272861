<template>
  <PageContainer>
    <div class="row justify-content-center">
      <div v-html="translate('rate_limit_body')" class="mm-html content row"></div>
    </div>
  </PageContainer>
</template>

<script>
// TODO: move to the Composition API when:
// - we have Vue 3 
// - we don't use the translate mixins
// - We don't use Vuex
import PageContainer from '@/components/PageContainer'

export default {
  components: {
    PageContainer
  }
}
</script>

<style>

</style>