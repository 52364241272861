<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import ValidationErrors from './Common/ValidationErrors'

const props = defineProps({
  field: {
    default: {},
    type: Object
  },
  validationErrors: {
    default: {},
    type: Object
  },
  participantChoice: {
    default: false,
    type: Boolean
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  photoValue: null,
  previousPhotoValue: null
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.photoValue = data.fieldData.value ?? ''
  emit('setValue', {
    value: data.photoValue,
    fieldHandle: data.fieldData.handle
  })
})

const getImage = (e) => {
  if (e.target.files && e.target.files.length) {
    let f = e.target.files[0]
    let fileReader = new FileReader()
    fileReader.onload = (e) => {
      data.photoValue = fileReader.result
    }

    fileReader.readAsDataURL(f)
  }
}

const fileSize = computed(() => {
  if (data.photoValue) {
    return ((data.photoValue.replace(/=/g, '').length) * 0.75)
  } 
  
  return 0
})

const hasFilesizeError = computed(() => {
  if (fileSize.value > 3000000) {
    clearImage()
    return true
  } 
  
  return false
})

const rules = computed(() => ({
  photoValue: {},
}))
const v$ = useVuelidate(rules, data)

const chooseImage = () => {
  document.getElementById("image").click()
}

const clearImage = () => {
  data.photoValue = null
  emit('setValue', {
    value: data.photoValue,
    fieldHandle: data.fieldData.handle
  })
}

watch(() => data.photoValue, () => {
  emit('setValue', {
    value: data.photoValue,
    fieldHandle: data.fieldData.handle
  })
})

watch(() => props.participantChoice, (newVal) => {
  if (!newVal) {
    data.previousValue = data.photoValue
    clearImage()
  } else {
    if (data.previousValue) {
      data.photoValue = data.previousValue
    }
  }
})
</script>

<template>
  <div class="mm-custom-form">
    <div class="photo-upload-widget" v-if="!data.fieldData.optional || props.participantChoice">
      <div 
        class="form-group" 
        v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.photoValue, field: data.fieldData, fieldValue: data.photoValue }"
      >
        <div class="image-preview" :style="{ color: colors('7'), backgroundImage: 'url(' + data.photoValue + ')'}"></div>
        <div class="controls" :style="{ backgroundColor: colors('1') }">
          <div class="btn-file-upload">
            <div class="pointer">
              <input
                type="file"
                id="image"
                class="form-control btn btn-1 icon-photos"
                @change="getImage($event)"
                accept="image/*"
                tabindex="0"
                hidden
              />
              <div
                class="choose-image"
                role="button"
                @click="chooseImage()"
                @keypress.enter="chooseImage()"
                :aria-label="translate('select_image_on_participant_page')"
                tabindex="0"
              >
                <i class="icon-photos icon-upload-participant-photo" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="remove-image float-end"
            @click="clearImage()"
            role="button"
            :aria-label="translate('remove_image')"
            @keypress.enter="clearImage()"
            tabindex="0"
          >
            <i
              class="icon-false-close icon-upload-participant-photo"
              :class="{ disabled: !data.photoValue }"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <ValidationErrors :validationErrors="validationErrors" :fieldHandle="data.fieldData.handle"></ValidationErrors>
      </div>
    </div>
    <p v-if="hasFilesizeError" class="error-message" role="alert">
      {{ translate('register_participant_photo_filesize_eventsite', {size: '3MB'}) }}
    </p>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.image-preview
  width: 250px
  height: 250px
  background-color: #FFFFFF
  border: 1px solid
  background-position: center center
  background-size: cover
  border-bottom: none

.controls
  width: 250px
  min-height: 48px
  padding: 0px 10px
  margin-bottom: 7px
  .btn-file-upload
    display: inline-block
    position: relative
    input[type='file']
      top: 0
      bottom: 0
      left: 0
      right: 0
      opacity: 0
      position: absolute
      z-index: 3
      cursor: pointer
  i
    font-size: 28px
    line-height: 48px
    cursor: pointer
    &.disabled
      color: rgba(255, 255, 255, 0.4)

.choose-image:focus-visible,
.remove-image:focus-visible
  outline: 2px dotted !important
  outline-offset: 3px

.error-message
  color: red
  font-weight: 600
</style>