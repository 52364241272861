<script setup>
import { reactive, onMounted } from 'vue'

const props = defineProps({
  field: {
    default: {},
    type: Object
  },
  validationErrors: {
    default: {},
    type: Object
  }
})

const data = reactive({
  fieldData: {}
})

onMounted(() => {
  data.fieldData = { ...props.field }
})
</script>

<template>
  <div v-if="data.fieldData" class="form-group has-success">
    <input 
      disabled
      type="text"
      class="form-control team"
      :value="data.fieldData.value"
      :style="{color: colors('7'), background: colors('6'),borderColor: colors('5')}"
    />
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.team
  font-weight: 900
  padding-left: 20px
</style>
