<template>
    <div class="box introducee inactive">
        <h3>{{ translate('introducee') }} {{ nr }}</h3>
    </div>
</template>

<style type="text/scss" lang="scss">

</style>

<script>
  export default {
    props: {
      nr: Number
    },
    data () {
      return {}
    }
  }
</script>
