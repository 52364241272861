<template>
    <div class="widget-countdown">
        <div class="container">
            <div class="row position-relative">
                <div class="col-6 col-sm-3 text-center">
                  <span class="number fw-bold" :class="countdownUnitClass">{{ days }}</span>
                  <div class="label label-countdown unit text-uppercase">
                    <span>{{ translate('days') }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3 text-center">
                  <span class="number fw-bold" :class="countdownUnitClass">{{ hours }}</span>
                  <div class="label label-countdown unit text-uppercase">
                    <span>{{ translate('hours') }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3 text-center">
                  <span class="number fw-bold" :class="countdownUnitClass">{{ minutes }}</span>
                  <div class="label label-countdown unit text-uppercase">
                    <span>{{ translate('minutes') }}</span>
                  </div>
                </div>
                <div class="col-6 col-sm-3 text-center">
                  <span class="number fw-bold" :class="countdownUnitClass">{{ seconds }}</span>
                  <div class="label label-countdown unit text-uppercase">
                    <span>{{ translate('seconds') }}</span>
                  </div>
                </div>
                <button
                  @click="toggleCountdown()"
                  class="countdown-toggle position-absolute"
                  v-bind:class="{ paused: pause }"
                  :aria-label="translate('countdown_toggle')"
                  :title="translate('countdown_toggle')"
                  data-testid="countdown-toggle"
                  :aria-pressed="pressed"
                >
                </button>
            </div>
        </div>
    </div>
</template>
<style lang="scss" type="text/scss" scoped>
    @import "../../../../src/assets/styles/app";

    .widget-countdown {
      .countdown--large {
        font-size: 6.66rem;
        line-height: 6.66rem;
      }
      .countdown--medium {
        font-size: 4.66rem;
        line-height: 4.66rem;
      }
      .countdown--small {
        font-size: 2.66rem;
        line-height: 2.66rem;
      }

      .label {
          font-weight: 600;
          letter-spacing: 3px;
          color: inherit;
      }

      .label-countdown.unit {
          padding-top: 20px !important;
          text-align: center;
      }

      .countdown-toggle:focus-visible {
        outline: 2px dotted !important;
        outline-offset: 3px;
      }

      .countdown-toggle {
        right: -55px;
        bottom: 5px;
        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        height: 16px;
        transition: 50ms all ease;
        cursor: pointer;

        // Pause button styling
        border-style: double;
        border-width: 0px 0 0px 16px;

        &.paused {
          // Play button styling
          border-style: solid;
          border-width: 8px 0 8px 16px;
        }
      }

      @media only screen and (max-width: 810px) {
        .countdown-toggle {
          bottom: -25px;
          left: 50%;
        }
      }

      @include media-breakpoint-only(xs) {
        .number {
            font-size: 3.5rem;
            line-height: 3.5rem;
        }
        .label {
            font-size: 1.00rem;
        }
        .countdown-toggle {
          right: -38px;
        }
      }

      @include media-breakpoint-only(sm) {
        .number {
            font-size: 3.5rem;
            line-height: 3.5rem;
        }
        .label {
            font-size: 1.0rem;
        }
        .countdown-toggle {
          right: -80px;
        }
      }

      @include media-breakpoint-only(md) {
        .number {
            font-size: 4.5rem;
            line-height: 4.5rem;
        }
        .label {
            font-size: 1.0rem;
        }
      }
    }
</style>
<script>
  import moment from 'moment'
  import widgetMixin from '../../../mixins/widgetMixin'

  export default {
    name: 'countdown-v1',
    mixins: [widgetMixin],
    data () {
      return {
        now: moment(Date.now()),
        intervalID: null,
        pause: false,
        timePaused: null
      }
    },
    created: function () {
      if (localStorage.getItem("countdown-paused-at")) {
        this.pause = true
        this.timePaused = moment.duration(localStorage.getItem("countdown-paused-at"))
      }
      this.intervalID = setInterval(() => {
        this.now = moment(Date.now())
      }, 1000)
    },
    beforeUnmount () {
      clearInterval(this.intervalID)
    },
    computed: {
      duration: function () {
        if (this.pause && this.timePaused) {
          return this.timePaused
        }
        const then = moment.unix(this.widget.values.timestamp)
        const duration = moment.duration(then.diff(this.now))
        return duration
      },
      days: function () {
        return (this.duration.asDays() > 0) ? Math.floor(this.duration.asDays()) : 0
      },
      hours: function () {
        return (this.duration.hours() > 0) ? this.duration.hours() : 0
      },
      minutes: function () {
        return (this.duration.minutes() > 0) ? this.duration.minutes() : 0
      },
      seconds: function () {
        return (this.duration.seconds() > 0) ? this.duration.seconds() : 0
      },
      widgetId: function () {
        return this.widget.widgetId
      },
      countdownUnitClass () {
        let size = this.widget.values.size || 'large'
        return `countdown--${size}`
      },
      pressed () {
        return this.pause ? 'true' : 'false';
      }
    },
    methods: {
      toggleCountdown() {
        if (!this.pause && !localStorage.getItem("countdown-paused-at")) {
          localStorage.setItem("countdown-paused-at", this.duration);
          this.timePaused = moment.duration(localStorage.getItem("countdown-paused-at"))
        } else {
          this.timePaused = null
          localStorage.removeItem("countdown-paused-at")
        }
        this.pause = !this.pause
      }
    }
  }
</script>
