<template>
    <div class="page-shares mm-page" :class="{ 'has-image': page.image }">
      <ImageHeader :imageUrl="page.image"></ImageHeader>
      <div class="container">
          <div class="row content mm-html" v-if="page.content" v-html="page.content"></div>

          <div v-for="(subpage, indexSubpage) in page.subpages" :key="indexSubpage" :class="`mm-subpage-${indexSubpage + 1}`">
              <div class="row content mm-subpage" v-if="subpage.type === 'content' && subpage.content" v-html="subpage.content"></div>

              <div class="row justify-content-md-center" v-for="(chunk, indexChunk) in chunk(subpage.shares)" :key="indexChunk">
                  <div class="col-12 col-md-4 share-wrapper" v-for="(share, indexShare) in chunk" :key="indexShare">
                    <div class="share text-center text-md-start">
                      <div class="image-container">
                        <img v-if="share.image" :src="share.image" class="img-fluid" role="presentation" alt="" />
                      </div>
                      <span v-bind:class="{ 'btn-2 label': share.label }" v-html="share.label"></span>
                      <a
                        v-html="share.title"
                        v-bind:href="share.url !== null ? share.url : share.file !== null ? share.file : '#'"
                        v-bind:target="share.url !== null ? '_blank' : '_self'"
                      >
                      </a>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
  import _chunk from 'lodash/chunk'
  import urlfuncs from '../../mixins/urlfuncs'
  import ImageHeader from "../../components/ImageHeader.vue";
  import { mapStores } from "pinia"
  import { useAppTemplate } from "@/stores/app-template"

  export default {
    components: {ImageHeader},
    props: ['page'],
    mixins: [urlfuncs],
    data () {
      return {
      }
    },
    computed: {
      ...mapStores(useAppTemplate),
      shareStyle () {
        const colors = this.appTemplateStore.get('colors')
        return {
          border: `1px solid ${colors['5']}`
        }
      }
    },
    methods: {
      chunk (what) {
        return _chunk(what, 3)
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
html .page-shares .row.content.mm-html
  max-width: none

html .page-shares .row.content
  h1, h2
    padding-top: 20px
    padding-bottom: 20px

html .page-shares .mm-subpage-1
  h1, h2
    padding-top: 0px
    position: relative
    top: -10px
    padding-bottom: 40px

.page-shares
  flex: 1
  display: flex
  flex-direction: column
  padding-top: 40px
  &.has-image
    padding-top: 0 !important

  .share-wrapper
    margin-bottom: 40px

  .share
    padding: 0px 0px
    height: 100%
    position: relative

    .label
      position: absolute
      font-size: 13px
      line-height: 16px
      box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 3px
      top: 8px
      font-style: normal
      font-weight: normal
      padding: 5px 13px
      text-decoration: none

    @media (max-width: 768px)
      .label
        left: 18%

    @media (max-width: 576px)
      .label
        left: 5%

    .image-container
      width: 100%
      height: 240px
      margin-bottom: 15px

      img
        max-height: 240px

    a
      font-weight: 300
      color: inherit

    a:focus-visible, a:hover
      text-decoration: underline !important

.has-image
  padding-top: 0 !important
  padding-left: 0
  padding-right: 0

.image
  width: 100%
  max-height: 550px
  background-size: cover
  background-position: 50%

  .page-flex
    padding: 0

</style>
