<script setup>
import { computed } from "vue";
import WidgetButton from "../../../partials/widgetButton.vue";


const props = defineProps({
  widget: {
    type: Object,
    default: () => ({}),
  },
  css_id: {
    type: String,
    default: "",
  },
});

const textContainerPercentage = computed(() => {
  return props.widget.values.text_percentage_column + "%";
})



</script>

<template>
  <div class="base-widget">
    <div class="container d-flex" :class="{ 'flex-row-reverse': !props.widget.values.text_position_left }">
      <div class="d-flex flex-shrink-0 flex-column text-container"
        :class="'justify-content-' + props.widget.values.text.vertical_alignment">
        <div v-if="props.widget.values.text.content" v-html="props.widget.values.text.content" />
        <WidgetButton v-if="props.widget.values.text.button" :button="props.widget.values.text.button" :margin="false"
          :class="'align-self-' + props.widget.values.text.button.horizontal_alignment" />

      </div>
      <div class="flex-grow-1 d-flex image-container"
        :class="['align-items-' + props.widget.values.image.vertical_alignment, 'justify-content-' + props.widget.values.image.horizontal_alignment]">

        <img :src="props.widget.values.image.src" :alt="props.widget.values.image.alt" />
      </div>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.container
  gap: 45px
  padding-top: 0
  padding-bottom: 0
  max-width: map-get($container-max-widths, "xl")

.image-container
  overflow: hidden
  img
    max-width: 100%

.text-container
  gap: 24px
  flex-basis: v-bind(textContainerPercentage)

@media (max-width: 1090px)
  .container
    padding-left: 40px
    padding-right: 40px

@media (max-width: 768px)
  .container
    flex-direction: column !important
    max-width: 100%

@media (max-width: 576px)
  .container
    padding-left: 20px
    padding-right: 20px

</style>
