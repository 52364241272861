<template>
    <div v-if="purchases.length > 0" class="introducee-registration">
        <!-- confirmed introducees-->
        <Summarybox
          v-for="(i, index) in registration.introducees"
          :key="index"
          :summary="i.summary"
          :name="i.fullname"
          :index="index"
        >
        </Summarybox>

        <IntroduceeActive :purchase="purchases[0]" :nr="activeIntroduceeNumber"></IntroduceeActive>

        <IntroduceeInactive
          v-for="(itemObjKey, index) in purchaseIntroduceeCount-1"
          :key="index"
          :nr="itemObjKey+1+confirmedIntroduceeCount"
        >
        </IntroduceeInactive>
    </div>
</template>

<script>
  import Summarybox from "./../Registration/Summarybox"
  import IntroduceeActive from "./Introducees/Active"
  import IntroduceeInactive from "./Introducees/Inactive"
  import { mapStores } from "pinia"
  import { useAppRegistration } from "@/stores/app-registration"
  import { useAppRegistrationIntroducee } from "@/stores/app-registration-introducee"

  export default {
    components: {
      Summarybox,
      IntroduceeActive,
      IntroduceeInactive
    },
    data () {
      return {}
    },
    computed: {
      ...mapStores(useAppRegistration, useAppRegistrationIntroducee),
      registration () {
        return this.appRegistrationStore.registration
      },
      purchases () {
        return this.appRegistrationIntroduceeStore.purchases
      },
      confirmedIntroduceeCount () {
        return this.registration.introducees.length
      },
      purchaseIntroduceeCount () {
        return this.purchases.length
      },
      activeIntroduceeNumber () {
        return this.confirmedIntroduceeCount + 1
      }
    }
  }
</script>
