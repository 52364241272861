import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import ChatService from '@/services/ChatService'

export const useAppChat = defineStore('appChat', () => {
  const roomsState = ref([])
  const roomMessagesState = ref([])
  const currentRoomState = ref(null)
  const userInfoState = ref(null)

  const getRooms = computed(() => roomsState.value)
  const getCurrentRoom = computed(() => currentRoomState.value)
  const getCurrentUser = computed(() => userInfoState.value)

  function setRoomMessages(hash) {
    return new Promise((resolve, reject) => {
      ChatService.getRoomMessages(hash)
        .then((data) => {
          roomMessagesState.value = data
          
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  function setCurrentUser(userInfo) {
    return new Promise((resolve) => {
      userInfoState.value = userInfo
      resolve()
    })
  }

  function setRooms() {
    return new Promise((resolve, reject) => {
      ChatService.getRooms()
        .then((data) => {
          roomsState.value = data
        })
        .catch((err) => {
          reject(err)
        })

      resolve()
    })
  }

  function joinRoom(roomHash) {
    return new Promise((resolve) => {
      ChatService.join(roomHash).then((data) => {
        resolve(data)
      })
    })
  }

  function setCurrentRoom(roomHash) {
    return new Promise((resolve) => {
      currentRoomState.value = roomHash
      resolve()
    })
  }

  return { 
    roomsState,
    roomMessagesState,
    currentRoomState,
    userInfoState,
    getRooms,
    getCurrentRoom,
    getCurrentUser,
    setRoomMessages,
    setCurrentUser,
    setRooms,
    joinRoom,
    setCurrentRoom
   }
})