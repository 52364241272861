<template>
    <div class="mm-widgets">
        <component :is="widgetComponent" :widget="widget" :css_id="css_id" :id="css_id" class="base-widget" :class="baseWidgetClasses()" />
    </div>
</template>

<script>
  export default {
    props: {
      widget: Object
    },
    computed: {
      widgetComponent: function () {
        return this.widget.type + '-' + this.widget.version
      },
      css_id: function () {
        return 'widget-' + this.widget.widgetId
      }
    },
    methods: {
      baseWidgetClasses () {
        let classes = []
        classes.push(this.isHomepageClass())

        if (this.widget.values.button && this.widget.values.button.active) {
          classes.push('mm-has-button')
        }

        if (this.widget.values.content && this.widget.values.content.length) {
          classes.push('mm-has-html')
        }

        if (this.widget.values.text && this.widget.values.text.length) {
          classes.push('mm-has-html')
        }

        return classes.join(' ')
      }
    }
  }
</script>

<style lang="sass" type="text/sass">
.base-widget
  padding: 65px 0
@include media-breakpoint-down(md)
  .base-widget
    padding: 50px 0
</style>
