import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useAppMenu = defineStore('appMenu', () => {
  const openState = ref(false)

  const open = computed(() => openState.value)

  function setOpen(data) {
    openState.value = data
  }

  return { openState, open, setOpen }
})