import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useAppLanguages } from '@/stores/app-languages'
import PageService from '@/services/PageService'

export const useAppPages = defineStore('appPages', () => {
  const appLanguagesStore = useAppLanguages()

  const pagesState = ref([])
  const currentState = ref(null)
  const readyState = ref(false)
  const sitemapState = ref([])

  const all = computed(() => pagesState.value)
  const current = computed(() => pagesState.value.find(p => p.page_id === currentState.value))
  const getPage = computed(() => (type) => pagesState.value.find(p => p.type === type && p.language_id === appLanguagesStore.activeLanguage))
  const menu = computed(() => {
    return pagesState.value.filter((page) => {
      return parseInt(page.language_id) === appLanguagesStore.activeLanguage && page.active === 'yes'
    }, 'sequence')
  })
  const currentLanguage = computed(() => {
    return pagesState.value.filter((page) => {
      return parseInt(page.language_id) === appLanguagesStore.activeLanguage
    }, 'sequence')
  })
  const ready = computed(() => readyState.value)
  const sitemap = computed(() => sitemapState.value)

  function load(data) {
    pagesState.value = data
  }

  function setCurrentPage(route) {
    return new Promise((resolve, reject) => {
      let page

      if (route.params.pageId) {
        page = all.value.find(p => p.page_id === parseInt(route.params.pageId))
      } else if (route.name === 'register') {
        page = all.value.find(p => p.type === 'register')
      } else {
        currentState.value = null
        resolve()
      }

      if (page && page.language_id !== appLanguagesStore.active.language_id) {
        appLanguagesStore.setActiveLanguage(page.language_id)
      }

      if (page !== undefined) {
        PageService.getPageData(page.page_id).then((data) => {
          // TODO: Vue 3 upgrade: Look for a new solution. Check if it is necessary
          Object.assign(pagesState.value.find(p => p.page_id === page.page_id), data)
          currentState.value = page.page_id

          resolve()
        }).catch((err) => {
          console.error(err)
          reject(err)
        })
      } else {
        reject()
      }
    })
  }

  function setReady(data) {
    readyState.value = data
  }

  function setSitemap() {
    return new Promise((resolve, reject) => {
      PageService.getSitemap().then((data) => {
        sitemapState.value = data
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      });
    })
  }

  function uploadPhoto(pageId, file) {
    return new Promise((resolve, reject) => {
      PageService.uploadPhoto(pageId, file).then((data) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  return { 
    pagesState,
    currentState,
    readyState,
    sitemapState,
    all,
    current,
    getPage,
    menu,
    currentLanguage,
    ready,
    sitemap,
    load,
    setCurrentPage,
    setReady,
    setSitemap,
    uploadPhoto
   }
})