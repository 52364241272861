<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div
        v-if="content"
        role="status"
        class="mm-html content row"
        v-html="content"
      />
    </div>
  </div>
</template>

<script>
  import renderMustache from 'src/lib/mustache'
  import { mapStores } from "pinia"
  import { useApp } from "@/stores/app"
  import { useAppTranslations } from "@/stores/app-translations"
  import { useAppRegistration } from "@/stores/app-registration"

  export default {
    props: {
      page: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      ...mapStores(useApp, useAppTranslations, useAppRegistration),
      content() {
        const defaultTpl = this.translationExists('text_registration_success') 
          ? this.translate('text_registration_success') 
          : this.appStore.event.text_registration_success;

        const delayedTpl = this.appTranslationsStore.translations['text_registration_delayedpayment_success'];

        if (!this.fields) {
          return defaultTpl;
        }

        const emailField = this.fields.find(f => f.type === 'email');

        if (!emailField) {
          return defaultTpl;
        }

        const tpl = this.isDelayedPayment ? delayedTpl : defaultTpl;
        const emailValue = this.registrationValues[emailField.handle] || '';

        return renderMustache(tpl, { email: emailValue });
      },
      fields () {
        return this.appRegistrationStore.fields
      },
      registrationValues () {
        return this.appRegistrationStore.registrationValues
      },
      isDelayedPayment () {
        const delayedField = this.fields.find(f => f.type === 'delaypayment')

        if (delayedField) {
          return this.registrationValues[delayedField.handle] || false
        }

        return false;
      }
    }
  }
</script>
