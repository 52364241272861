<script setup>
import { onMounted, ref, reactive, computed } from 'vue'
import { useAppRegistration } from "@/stores/app-registration"
import { useAppRegistrationIntroducee } from "@/stores/app-registration-introducee"
import Registertext from './RegistrationField/Text'
import Registeremail from './RegistrationField/Email'
import Registeremailrestricted from './RegistrationField/EmailRestricted'
import Registermultiplechoice from './RegistrationField/Multiplechoice'
import Registercheckbox from './RegistrationField/Checkbox'
import Registertelephone from './RegistrationField/Telephone'
import Registerdate from './RegistrationField/Date'
import Registerlink from './RegistrationField/Link'
import Registercontent from './RegistrationField/Content'
import Registerworkshop from './RegistrationField/Workshop'
import Registerselect from './RegistrationField/Select'
import Registerteam from './RegistrationField/Team'
import Registerparticipants_choice from './RegistrationField/ParticipantsChoice'
import Registerparticipants_photo from './RegistrationField/ParticipantsPhoto'
import Registerpassword from './RegistrationField/Password'
import Registerdelaypayment from './RegistrationField/Delaypayment'
import Registerintroducees from './RegistrationField/Introducees'
import Registercountry from './RegistrationField/Country'
import Registerterms from './RegistrationField/Checkbox'
import Registerfile from './RegistrationField/File'
import { useField } from '@/composables/field'

const appRegistrationStore = useAppRegistration()
const appRegistrationIntroduceeStore = useAppRegistrationIntroducee()
const { isMultipleChoice, isWorkshop, getFieldByHandle } = useField()

const props = defineProps({
  fields: {
    default: () => [],
    type: Array
  },
  isGuestForm: {
    type: Boolean,
    default: false
  }
})

const fieldsComponents = {
  Registertext,
  Registeremail,
  Registeremailrestricted,
  Registermultiplechoice,
  Registercheckbox,
  Registertelephone,
  Registerdate,
  Registerlink,
  Registercontent,
  Registerworkshop,
  Registerselect,
  Registerteam,
  Registerparticipants_choice,
  Registerparticipants_photo,
  Registerpassword,
  Registerdelaypayment,
  Registerintroducees,
  Registercountry,
  Registerterms,
  Registerfile
}
const participantChoiceFieldHandle = ref('')
const participantChoice = ref(false)
const showFields = reactive({})

onMounted(() => {
  const participantChoiceFiltered = props.fields.filter(field => field.type === 'participants_choice')
  participantChoiceFieldHandle.value = participantChoiceFiltered.length ? participantChoiceFiltered[0].handle : null
  participantChoice.value = participantChoiceFiltered.length ? participantChoiceFiltered[0].value : false

  updateShowFields()
})

const validationErrors = computed(() => {
  return props.isGuestForm ? appRegistrationIntroduceeStore.validationErrors : appRegistrationStore.validationErrors
})

const getComponentName = (field) => {
  let name = `Register${field.type}`

  if (field.type === 'email' && emailHasDomainRestriction(field)) {
    name = `Registeremailrestricted`
  }

  return fieldsComponents[name]
}

const getFieldType = (field) => {
  if (field.type === 'delaypayment') {
    return 'select'
  }

  return field.type
}

const setValue = (payload) => {
  if (payload.fieldHandle === participantChoiceFieldHandle.value) {
    participantChoice.value = payload.value
  }

  if (props.isGuestForm) {
    appRegistrationIntroduceeStore.addGuestValue(payload)
  } else {
    appRegistrationStore.addRegistrationValue(payload)
  }

  removeValidationError(payload.fieldHandle)
  updateShowFields()
}

const removeValidationError = (fieldHandle) => {
  if (props.isGuestForm) {
    appRegistrationIntroduceeStore.removeValidationError(fieldHandle)
  } else {
    appRegistrationStore.removeValidationError(fieldHandle)
  }
}

const emailHasDomainRestriction = (field) => {
  return Array.isArray(field.domains) && field.domains.length >= 1
}

const updateShowFields = () => {
  const store = props.isGuestForm ? appRegistrationIntroduceeStore : appRegistrationStore
  const registrationValues = props.isGuestForm ? 'guestValues' : 'registrationValues'


  /**
   * Creating a variable to store the fields that should be shown
   * before updating the showFields reactive object
   * to be able to compare the new values with the old ones
   * and cleaning the fields that should be hidden
   */
  const showFields_ = {}

  props.fields.forEach((field) => {
    if (!field.handle) return;

    if (!field.parents_field_handle) {
      showFields_[field.handle] = true;
      return;
    }

    field.parents_field_handle.some((parentFieldHandleElement) => {
      const parentFieldHandle = parentFieldHandleElement.handle;
      const parentFieldType = parentFieldHandleElement.type;

      if(!store[registrationValues][parentFieldHandle]) {
        console.error("Parent field "+parentFieldHandle+ " not configured for this ticket and is referenced by: " + field.description);
      }

      if (isMultipleChoice(parentFieldType)) {
        const ids = store[registrationValues]?.[parentFieldHandle]?.map(
          (option) => option.id
        ) ?? [];
        if (ids.includes(parentFieldHandleElement.id)) {
          showFields_[field.handle] = true;
          return true;
        }
      } else if (isWorkshop(parentFieldType)) {
        if (
          parentFieldHandleElement.id ===
          store[registrationValues][parentFieldHandle]
        ) {
          showFields_[field.handle] = true;
          return true;
        }
      }

      showFields_[field.handle] = false;
      return false;
    });
  });

  for (const fieldHandle in showFields_) {
    /**
     * If the field is not in the showFields reactive object
     * it means that it is a new field and should be shown.
     * All fields will go here the first time we open the form.
     */
    if (showFields[fieldHandle] === undefined) {
      showFields[fieldHandle] = showFields_[fieldHandle];
      continue;
    }


    /**
     * If the field has a different value in the showFields reactive object
     * it means that the field should be hidden or shown.
     *
     * If the field should be hidden, we need to clean the value in the store
     */
    if (showFields_[fieldHandle] !== showFields[fieldHandle]) {
      showFields[fieldHandle] = showFields_[fieldHandle];
      if (showFields[fieldHandle] === false) {
        const field = getFieldByHandle(fieldHandle);

        store[registrationValues][field.handle] = isMultipleChoice(field.type)
          ? []
          : null;
      }

      // Updating show fields again if values changed to make it recursive
      updateShowFields();
    }
  }
};
</script>

<template>
  <div class="fields">
    <div
      v-for="(field, index) in props.fields"
      :key="index"
    >
      <Transition v-if="showFields[field.handle]">
        <component
          :is="getComponentName(field)"
          v-if="field.type === 'participants_photo'"
          :field="field"
          :class="`mm-field-${getFieldType(field)}`"
          :validation-errors="validationErrors"
          :participant-choice="participantChoice"
          @set-value="setValue"
        />
        <component
          :is="getComponentName(field)"
          v-else
          :field="field"
          :class="`mm-field-${getFieldType(field)}`"
          :validation-errors="validationErrors"
          @set-value="setValue"
        />
      </Transition>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.v-enter-active, .v-leave-active
  transition: opacity 1.5s ease

.v-enter-from, .v-leave-to
  opacity: 0
</style>
