function itemsTillNextFullWidth (items, normalItem) {
  let count = 0
  for (let item in items) {
    if (items[item][normalItem]) {
      count = count + 1
    } else {
      return count
    }
  }
  return count
}

export default function (items, fullWidthItem) {
  let count = 0
  let rows = []
  let row = []

  for (let item in items) {
    count = count + 1
    if (items[item][fullWidthItem] === undefined) {
      items[item][fullWidthItem] = true
    }
    if (items[item][fullWidthItem]) {
      let remainingItems = items.slice(count)
      let nextItems = itemsTillNextFullWidth(remainingItems, fullWidthItem)
      let maxLength = (nextItems % 3) === 1 ? 4 : 4

      if (row.length < maxLength) {
        row.push(items[item])
      } else {
        rows.push(row)
        row = [items[item]]
      }
    } else {
      if (row.length) {
        rows.push(row)
        row = []
      }
      rows.push([items[item]])
    }
    if (items.length === count) {
      rows.push(row)
    }
  }
  return rows
}
