<script setup>
import { computed } from 'vue'
import { useTranslations } from '@/composables/translations'

const { translate } = useTranslations()

const props = defineProps({
  showSelectArrow: {
    default: false,
    type: Boolean
  },
  fieldHandle: {
    default: '',
    type: String
  },
  responseErrors: {
    default: () => {},
    type: Object
  },
  vuelidateField: {
    default: () => {},
    type: Object
  },
  fieldDisabled: {
    default: false,
    type: Boolean
  },
  value: {
    default: '',
    type: [String, Number, Boolean, Array]
  }
})

const ariaLabel = computed(() => {
  if (!props.fieldHandle) {
    return ''
  }

  if (hasResponseErrors(props.responseErrors, props.fieldHandle) || (props.vuelidateField.$invalid && props.vuelidateField.$dirty)) {
    return translate('validation_not_correct')
  } 
  else if (
    props.fieldDisabled ||
    (!hasResponseErrors(props.responseErrors, props.fieldHandle) && !isEmptyValue(props.value) && !props.vuelidateField.$invalid && props.vuelidateField.$dirty) || // no errors, not empty, validated, edited
    (!hasResponseErrors(props.responseErrors, props.fieldHandle) && !isEmptyValue(props.value) && !props.vuelidateField.$invalid && !props.vuelidateField.$dirty) // no errors, not empty, unedited
  ) {
    return translate('validation_correct')
  }
  
  return ''
})

const hasResponseErrors = (responseErrors, fieldHandle) => {
  return Object.prototype.hasOwnProperty.call(responseErrors, fieldHandle)
}

const isEmptyValue = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0
  }
  
  return [undefined, null, '', false].includes(value)
}

</script>

<template>
  <div class="icon-wrapper">
    <span
      v-if="props.showSelectArrow"
      class="select-arrow"
    />
    <span
      class="validation-icon"
      :aria-label="ariaLabel"
    />
  </div>
</template>
