import { useApp } from '@/stores/app'
import { useAppLanguages } from '@/stores/app-languages'

export default class TagManager {
    send() {
      window.dataLayer = window.dataLayer || [];
      if(window.dataLayer !== undefined) {
          let output

          const appStore = useApp()
          const appLanguagesStore = useAppLanguages()

          this.pageOrigin = window.location.origin
          this.eventId = appStore.event.event_id
          this.eventTitle = appLanguagesStore.all.lenght ? 
            appLanguagesStore.all[0].title_visitor : 
            null

          // visited most standard pages
          if(this.data.page !== undefined) {
            output = this.sendPage()
          }

          // submitted registration page
          if(this.data.registered !== undefined) {
            output = this.sendRegistrationComplete()
          }

          if(output !== undefined) {
            return output;
          }

          // This is a fail-state, the page objects themselves call the send function, check the object that call this.
          return 'not pushed'
      }
    }

    sendPage() {
      return window.dataLayer.push({
        'event': 'Page Viewed',
        'pageTitle': this.data.page.title,
        'pageType' : this.data.page.type ? this.data.page.type : null,
        'pageUrl': this.pageOrigin,
        'eventTitle': this.eventTitle,
        'eventID': this.eventId,
      });
    }

    sendRegistrationComplete() {
      return window.dataLayer.push({
        'event': 'User Registered',
        'pageTitle': this.data.registered.title,
        'pageUrl': this.pageOrigin,
        'eventTitle': this.eventTitle,
        'eventID': this.eventId,
        'userCompany': this.data.registered.userCompany,
        'userJobTitle': this.data.registered.userJobTitle,
        'userHash': this.data.registered.userHash,
      });
    }

    setData(data) {
      this.data = data
    }
}

