import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import VisitorService from '../services/VisitorService'

export const useAppLanguages = defineStore('appLanguages', () => {
  const languages = ref([])
  const currentLanguage = ref(null)

  const all = computed(() => languages.value)
  const active = computed(() => languages.value.find(p => p.language_id === parseInt(currentLanguage.value)) || languages.value[0])
  const activeLanguage = computed(() => currentLanguage.value)
  const pageTitle = computed(() => (type) => `${type} | ${((active.value) ? active.value.title_visitor : '')}`)

  function setLanguages(langs) {
    languages.value = langs
  }

  function setActiveLanguage(data) {
    return new Promise((resolve, reject) => {
      VisitorService.setLanguage(data).then(() => {
        currentLanguage.value = data
        resolve()
      }).catch(() => {
        reject()
      })
    })
  }

  return { languages, currentLanguage, all, active, activeLanguage, pageTitle, setLanguages, setActiveLanguage }
})