<template>
    <div class="widget-shares">
        <widget-title :title="title"></widget-title>
        <div class="container">
            <div class="row share-row justify-content-center" v-for="(chunk, indexChunk) in chunkSponsoren" :key="indexChunk">
                <div class="share-row-col col-12 col-md-3" v-for="(share, indexShare) in chunk" :key="indexShare">
                  <div class="share-wrapper col-12 col-md-12 col-sm-6 col-xs-6">
                      <div class="share text-center text-md-start">
                        <div class="image-container">
                          <img v-if="share.image" :src="share.image" class="img-fluid" role="presentation" alt="" />
                        </div>
                      </div>
                  </div>
                  <div class="details col-12 col-md-12 text-center">
                    <a
                      v-html="share.title"
                      v-bind:href="share.url !== null ? share.url : share.file !== null ? share.file : '#'"
                      v-bind:target="share.url !== null ? '_blank' : '_self'"
                      class="mm-color-text h3"
                    >
                    </a>
                  </div>
                </div>
            </div>
        </div>

        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import chunk from 'lodash/chunk'
  import { mapStores } from "pinia"
  import { useAppTemplate } from "@/stores/app-template"

  export default {
    name: 'shares-v1',
    mixins: [widgetMixin],
    data () {
      return {
      }
    },
    methods: {
      visitSponsorPage () {
        if (this.button && this.button.link) {
          this.$router.push(`/page/${this.button.link}`)
        }
      }
    },
    computed: {
      ...mapStores(useAppTemplate),
      title () {
        return this.widget.values.title
      },
      button () {
        return this.widget.values.button
      },
      shareStyle () {
        const colors = this.appTemplateStore.get('colors')
        return {
          border: `1px solid ${colors['5']}`
        }
      },
      indexView () {
        let length = this.widget.values.shares.length

        let modulo = length % 4

        if (modulo !== 1) {
          return 4
        }

        if (modulo === 1) {
          return 3
        }
      },
      chunkSponsoren () {
        return chunk(this.widget.values.shares, this.indexView)
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.widget-shares
  .share-wrapper
    padding: 0px
  .share-row
    margin-bottom: 30px
    .share-row-col
      margin-bottom: 30px

    .share
      width: 100%
      margin-bottom: 20px
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      position: relative
      cursor: pointer

      .share-image
        position: absolute
        top: 10%
        left: 10%
        width: 80%
        height: 80%
        background-repeat: no-repeat
        background-size: contain
        background-position: center center

@include media-breakpoint-down(lg)
  .share-wrapper
    margin: 0 auto
@include media-breakpoint-down(md)
  .share-wrapper
    max-width: 50%
</style>
