import { createRouter as _createRouter, createWebHistory } from 'vue-router'
import { routes } from "./routes"

const routerHistory = createWebHistory()

export function createRouter() {
    return _createRouter({
        history: routerHistory,
        routes
    })
}