<script setup>
const props = defineProps({
  field: {
    default: {},
    type: Object
  }
})
</script>

<template>
  <div class="mm-html" v-html="field.type_config"></div>
</template>
