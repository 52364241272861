/* eslint-disable */
export const env = {
  "BASE_URL": "https://event-api-loadbalancer.momice.com/api/eventwebsite",
  "SENTRY_DSN": "https://9bb04483f78e4858bb79087e39671592@o4506298473709568.ingest.sentry.io/4506416034938880",
  "ANON_CONFIG_1": null,
  "CHAT_SERVER": "https://chat.momice.com:3001",
  "VOTING_SERVER": "https://voting.momice.com:3003",
  "ADYEN_CLIENT_KEY": "live_3PJUZL5NR5HVFAEZVC5OBJYGOI7FRX2Y",
  "ADYEN_ENVIRONMENT": "live",
  "NODE_ENV_SENTRY": "production",
  "API_URL": "https://event-api-loadbalancer.momice.com",
  "API_CONTENT_URL": "https://event-api.momice.com",
  "ADYEN_ENDPOINT": "https://live.adyen.com/hpp/pay.shtml",
  "X_MOMICE_VERSION": "1.0.0-fa22f98"
}
/* eslint-enable */
