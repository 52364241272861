<template>
    <div class="page-attachments">
        <div class="container">
            <div class="row content mm-html" v-if="page.content" v-html="page.content"></div>

            <AttachmentOverview :attachments="attachments"></AttachmentOverview>
        </div>
    </div>
</template>

<script>
  import AttachmentOverview from "../../components/Attachments/AttachmentOverview"

  export default {
    components: {
      AttachmentOverview
    },
    props: [
      'page'
    ],
    computed: {
      attachments () {
        return this.page.attachments
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.page-attachments
  padding-bottom: 150px
</style>
