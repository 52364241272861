<template>
    <div class="row justify-content-center" v-show="!teamcode || hasError">
        <div class="col-lg-12">

            <div class="text-center">

              <p>{{ translate('teammanager_code_before') }}</p>

              <Errors :errors="errors"></Errors>

              <form method="post" @submit.prevent="postTeamCode()">
                  <div class="row">
                      <div class="col text-center">
                          <label for="code">{{ translate('form_code') }}</label>
                      </div>
                  </div>
                  <div class="form-group">
                      <input type="text" id="code" v-model="code" class="form-control" @input="resetError()" />
                  </div>

                  <div class="form-group">
                      <button class="btn btn-3" type="submit">{{ translate('event_confirm_code') }}</button>
                  </div>
              </form>
            </div>
        </div>
    </div>
</template>

<script>
  import Errors from "../../../../partials/errors"
  import { mapStores } from "pinia"
  import { useVisitor } from "@/stores/visitor"
  import { useAppRegistration } from "@/stores/app-registration"

  export default {
    components: {
      Errors
    },
    props: {
      page: Object
    },
    data () {
      return {
        code: '',
        errors: {}
      }
    },
    mounted () {
      if (this.teamcode) {
        this.code = this.teamcode
        this.postTeamCode()
      }
    },
    computed: {
      ...mapStores(useVisitor, useAppRegistration),
      teamcode () {
        let { param1: teamcode = null } = this.$route.params
        return teamcode
      },
      hasError () {
        return Object.keys(this.errors).length > 0
      }
    },
    methods: {
      resetError () {
        this.errors = {}
      },
      postTeamCode () {
        this.visitorStore.postTeamCode({ code: this.code }).then(() => {
          this.appRegistrationStore.init()
        }).catch((err) => {
          this.errors = err.response.data.data
        })
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
label
  display: inline-block
  font-weight: 600

input[type='text'], input[type='email']
  display: inline-block
  max-width: 330px
  height: 56px
  margin-bottom: 65px
</style>
