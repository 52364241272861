import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import NewsService from '@/services/NewsService'

export const useAppNews = defineStore('appNews', () => {
  const currentNewsItemState = ref(null)
  const isRegistrationState = ref(false)

  const getCurrentNewsItem = computed(() => currentNewsItemState.value)
  const isRegistration = computed(() => isRegistrationState.value)

  function setCurrentNewsItem(route) {
    return new Promise((resolve, reject) => {
      if (route.params.newsId) {
        NewsService.getNewsItem(route.params.newsId).then((data) => {
          currentNewsItemState.value = data.newsItem
          isRegistrationState.value = data.isRegistration

          resolve(data)
        })
        .catch((err) => {
          reject(err)
        });
      } else {
        reject()
      }
    })
  }

  return { 
    currentNewsItemState,
    isRegistrationState,
    getCurrentNewsItem,
    isRegistration,
    setCurrentNewsItem
   }
})