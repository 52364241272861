import detailNavigation from '../components/DetailNavigation.vue'
import { mapStores } from 'pinia'
import { useAppTopbar } from '@/stores/app-topbar'

export default {
  name: 'detailNavigationMixin',
  data () {
    return {
    }
  },
  computed: {
    ...mapStores(useAppTopbar),
    details () {
      if (this.navigationObjects.length === 1 && this.singleRedirect !== false) {
        return this.navigationObjects[0]
      }

      return this.navigationObjects.find(p => p.id === parseInt(this.$route.params.param1))
    },
    previousObject () {
      let currentKey = this.navigationObjects.findIndex(p => p.id === parseInt(this.$route.params.param1))
      return this.navigationObjects[--currentKey]
    },
    nextObject () {
      let currentKey = this.navigationObjects.findIndex(p => p.id === parseInt(this.$route.params.param1))
      return this.navigationObjects[++currentKey]
    },
    nextRoute () {
      if (this.nextObject) {
        return this.detailRoute(this.nextObject.id)
      }

      return null
    },
    previousRoute () {
      if (this.previousObject) {
        return this.detailRoute(this.previousObject.id)
      }

      return null
    }
  },
  methods: {
    detailRoute: function (id) {
      return {
        name: 'page',
        params: {
          pageId: this.page.page_id,
          param1: id
        }
      }
    },
    keyDown (event) {
      if (this.details) {
        if (parseInt(event.which) === 39) {
          if (this.nextRoute) {
            this.$router.push(this.nextRoute)
          }
        } else if (parseInt(event.which) === 37) {
          if (this.previousRoute) {
            this.$router.push(this.previousRoute)
          }
        }
      }
    }
  },
  components: {
    detailNavigation
  },
  mounted () {
    if (this.details && this.navigationObjects.length > 1) {
      window.addEventListener('keydown', this.keyDown)
      this.appTopbarStore.setReturnLink({ name: 'page', params: { pageId: this.page.page_id } })
    }
  },
  unmounted () {
    window.removeEventListener('keydown', this.keyDown, false)
    this.appTopbarStore.setReturnLink({ name: 'home' })
  }
}
