<script setup>
import { computed, ref, provide } from "vue"
import ResourceOverview from '@/components/Resource/ResourceOverview.vue'
import ResourceDetail from '../../components/Resource/ResourceDetail.vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const props = defineProps({
  page: {
    type: Object,
    default: () => {}
  }
})

// TODO: this should be returned from the API
const service = ref('speaker')

provide('service', service)

const speakers = computed(() => props.page.speakers)

// Speakers without elements with type content
const speakersOnly = computed(() => {
  return speakers.value.filter(p => p[service.value])
})

const details = computed(() => {
  if (props.page.speakers.length === 1) {
    return props.page.speakers[0]
  }

  return props.page.speakers.find(p => p.id === parseInt(route.params.param1))
})
</script>

<template>
  <div>
    <div class="container">
      <div 
        class="row content mm-html"
        v-html="props.page.content"
      />
      <ResourceOverview 
        v-if="!details"
        :resource="props.page.type" 
        :resource-elements="speakers"
      />
      <ResourceDetail
        v-else 
        :page="props.page"
        :resource-elements="speakersOnly"
        :resource-details="details"
      />
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>

</style>
