import { useApp } from '@/stores/app'
import { useAppTranslations } from '@/stores/app-translations'
import Mustache from 'mustache'

export function useTranslations() {
  const appStore = useApp()
  const translationsStore = useAppTranslations()

  const translate = (name, values) => {
    const trans = translationsStore.translations[name]
    
    if (!trans) {
      console.warn(`Translation not found for: ${name}`)
      return ''
    }
  
    if (!values) {
      return trans
    }
  
    try {
      return Mustache.render(trans, values)
    } catch (err) {
      console.error(`Error rendering translation for ${name}:`, err)
      return ''
    }
  }

  const translationExists = (name) => {
    return Boolean(translationsStore.translations[name])
  }

  const translateRegistrationText = (name) => {
    return translationExists(name) ? translate(name) : appStore.event.name
  }

  const serviceOfOwner = (service, owner) => {
    return translate('service_of_owner', { service, owner })
  }

  const goToDescription = (resourceType, resourceDetails) => {
    const resource = {
      resource: resourceType ? translate(`resource_${resourceType}`, resource) : '',
      details: resourceDetails
    }
  
    return resourceType && resourceDetails ? translate('go_to_resource', resource) : ''
  }

  return { translate, translationExists, translateRegistrationText, serviceOfOwner, goToDescription }
}
