import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import VisitorService from '@/services/VisitorService'
import jwt from 'jsonwebtoken'

export const useVisitor = defineStore('visitor', () => {
  const initedState = ref(false)
  const inviteState = ref({})
  const dataState = ref({})
  const linkExpiredState = ref(false)
  const testModeState = ref(false)
  const twoFactorAuthKeyState = ref(null)

  const inited = computed(() => initedState.value)
  const invite = computed(() => inviteState.value)
  const data = computed(() => dataState.value)
  const linkExpired = computed(() => linkExpiredState.value)
  const testMode = computed(() => testModeState.value)
  const twoFactorAuthKey = computed(() => twoFactorAuthKeyState.value)

  function load(data) {
    initedState.value = !!data.invite
    inviteState.value = data.invite ? data.invite : {}
    linkExpiredState.value = data.link_expired
    testModeState.value = data.test_mode
  }

  function setTwoFactorAuthKey(key) {
    twoFactorAuthKeyState.value = key
  }

  function init() {
    return new Promise((resolve, reject) => {
      VisitorService.init().then((data) => {
        inviteState.value = data
        initedState.value = true
      }).catch((err) => {
        dataState.value = {}
        initedState.value = false

        reject(err)
      })

      resolve()
    })
  }
  
  function postInviteHash(hash) {
    return new Promise((resolve, reject) => {
      VisitorService.getInviteFromHash(hash).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function postCode(code) {
    return new Promise((resolve, reject) => {
      VisitorService.getInviteFromCode(code).then((response) => {
        if (response.hasOwnProperty('twofactor') && response.twofactor.needed) {
          setTwoFactorAuthKey(response.twofactor.uuid)
        }

        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function twoFactorAuth(code) {
    return new Promise((resolve, reject) => {
      const payloadTwoFa = {
        uuid: twoFactorAuthKeyState.value,
        two_factor: code
      }
      
      VisitorService.twofactor(payloadTwoFa).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function postTeamCode(code) {
    return new Promise((resolve, reject) => {
      VisitorService.postTeamCode(code).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function login({email, password}) {
    return new Promise((resolve, reject) => {
      VisitorService.login(email, password).then((response) => {
        inviteState.value = response
        initedState.value = true
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function loginByHash(hash) {
    return new Promise((resolve, reject) => {
      VisitorService.loginByHash(hash).then((response) => {
        inviteState.value = response
        initedState.value = true
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function logout() {
    return new Promise((resolve, reject) => {
      VisitorService.logout().then((response) => {
        inviteState.value = {}
        initedState.value = false
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function token(token) {
    let data = {}
  
    try {
      data = jwt.decode(token)

      if (!(data instanceof Object)) {
        data = {}
      }
    } catch (err) {
      data = {}
    }

    dataState.value = data
  }

  return {
    initedState,
    inviteState,
    dataState,
    linkExpiredState,
    testModeState,
    twoFactorAuthKeyState,
    inited,
    invite,
    data,
    linkExpired,
    testMode,
    twoFactorAuthKey,
    load,
    init,
    postInviteHash,
    postCode,
    twoFactorAuth,
    setTwoFactorAuthKey,
    postTeamCode,
    login,
    loginByHash,
    logout,
    token
  }
})