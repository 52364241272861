<template>
<div class="page-flex mm-page">
    <div class="container">
        <Success/>
    </div>
</div>
</template>
<script>
import Success from './types/Registration/Status/Success.vue'
import Tracker from 'src/tracker/tracker'
import { mapStores } from 'pinia'
import { useVisitor } from '@/stores/visitor'

export default {
  components: {
    Success
  },
  computed: {
    ...mapStores(useVisitor),
    visitorInvite() {
      return this.visitorStore.invite
    }
  },
  created() {
    let visitorInvite = this.visitorInvite
    let tracker =  new Tracker;

    tracker.run({
      'registered': {
        'title' : 'Registration Complete',
        'userCompany': visitorInvite.company ? visitorInvite.company : null,
        'userJobTitle': visitorInvite.function ? visitorInvite.function : null,
        'userHash': visitorInvite.hash ? visitorInvite.hash : null,
      }
    })
  }
}
</script>
