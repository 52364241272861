<template>
  <div class="page-password-reset">
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="success"
          class="col-12 col-lg-6"
        >
          <p>{{ translate('my_account_reset_password_success') }}</p>
          <router-link
            v-slot="{ navigate }"
            :to="{ name: 'account' }"
            custom
          >
            <button
              class="btn btn-1"
              @click="navigate"
              @keypress.enter="navigate"
            >
              {{ translate('my_account_submit_button') }}
            </button>
          </router-link>
        </div>
        <div
          v-else
          class="col-12 col-lg-6"
        >
          <p>{{ text }}</p>
          <form @submit.prevent="post">
            <div
              v-if="error"
              class="alert alert-danger"
            >
              {{ error }}
            </div>
            <div class="form-group">
              <label>
                {{ translate('my_account_label_password_new') }} <span>*</span></label>
              <input
                v-model="password"
                type="password"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>
                {{ translate('my_account_label_password_new_repeat') }} <span>*</span></label>
              <input
                v-model="password2"
                type="password"
                class="form-control"
              >
            </div>
            <div class="form-group text-end">
              <button
                type="submit"
                class="btn btn-1"
              >
                {{ translate('my_account_reset_password_submit') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import http from 'src/http'
  import { mapStores } from 'pinia'
  import { useApp } from '@/stores/app'

  export default {
    data () {
      return {
        password: '',
        password2: '',
        error: null,
        success: false
      }
    },
    computed: {
      ...mapStores(useApp),
      text () {
        if (this.event.salutation === 'informal') {
          return this.translate('my_account_reset_password_explanation_informal')
        } else {
          return this.translate('my_account_reset_password_explanation')
        }
      },
      event () {
        return this.appStore.event
      }
    },
    methods: {
      post () {
        this.error = ''

        http.post('/account/reset-password', {
          password: this.password,
          password2: this.password2,
          hash: this.$route.params.hash
        }).then(() => {
          this.password = ''
          this.password2 = ''
          this.success = true
        }).catch((err) => {
          this.error = err.response.data
        })
      }
    }
  }
</script>
<style lang="sass" type="text/sass" scoped>
.page-password-reset
  padding-top: 50px
</style>
