import http from 'src/http'

class PaymentService {

  getPaymentSession () {
    return new Promise((resolve, reject) => {
      http.get('/payment/session').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  cancelOrRefusedPayment (resultCode, redirectResult) {
    return new Promise((resolve, reject) => {
      http.post('/payment/cancel_refuse', { resultCode, redirectResult }).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new PaymentService()
