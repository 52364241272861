<script setup>
import { ref, computed, reactive, onMounted, watch } from 'vue'
import autosize from 'autosize'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: {},
    type: Object
  },
  validationErrors: {
    default: {},
    type: Object
  }
})

const multilineRef = ref(null)

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  textValue: '',
  autoCompleteAllowedValues: {
    firstname: 'given-name',
    lastname: 'family-name',
    company: 'organization',
    function: 'organization-title'
  }
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.textValue = data.fieldData.value ?? ''

  autosize(multilineRef)
})

const rules = computed(() => ({
  textValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, data)

const autoCompleteValue = computed(() => {
  return data.autoCompleteAllowedValues.hasOwnProperty(data.fieldData.field)
    ? data.autoCompleteAllowedValues[data.fieldData.field]
    : 'off'
})

const multiline = computed(() => {
  return (data.fieldData.type_config ? data.fieldData.type_config : { multiline: false }).multiline
})

watch(() => data.textValue, () => {
  if (multiline.value) {
    autosize(multilineRef)
  }

  emit('setValue', {
    value: data.textValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && v$.textValue"
    class="mm-custom-form"
  >
    <div
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.textValue, field: data.fieldData, fieldValue: data.textValue }"
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />
      <div class="span-input">
        <input
          v-if="!multiline"
          :id="data.fieldData.handle"
          v-model="data.textValue"
          :style="{color: colors('7')}"
          :name="data.fieldData.handle"
          type="text"
          class="form-control"
          :disabled="data.fieldData.disabled"
          :autocomplete="autoCompleteValue"
          @blur="v$.textValue.$touch"
        >
        <div
          v-if="multiline"
          class="textarea-box"
        >
          <textarea 
            :id="field.handle"
            ref="multilineRef"
            v-model="data.textValue"
            :name="field.handle"
            type="text"
            class="form-control"
            :disabled="data.fieldData.disabled"
            :style="{color: colors('7')}"
          />
        </div>
        <ValidationIcon 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.textValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.textValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>

<style scoped>
  .textarea-box > textarea {
    min-height: 108px;
  }
</style>