<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

// TODO: Vue3 upgrade: it is the same as a text field. Removable

const props = defineProps({
  field: {
    default: () => {},
    type: Object
  },
  validationErrors: {
    default: () => {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  linkValue: ''
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.linkValue = data.fieldData.value ?? ''
})

const rules = computed(() => ({
  linkValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, data)

watch(() => data.linkValue, () => {
  emit('setValue', {
    value: data.linkValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && v$.linkValue"
    class="mm-custom-form"
  >
    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.linkValue, field: data.fieldData, fieldValue: data.linkValue }" 
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />
      <div class="span-input">
        <input
          :id="data.fieldData.handle"
          v-model="data.linkValue"
          :style="{color: colors('7')}"
          :name="data.fieldData.handle"
          type="text"
          class="form-control"
          :disabled="data.fieldData.disabled"
          @blur="v$.linkValue.$touch"
        >
        <ValidationIcon 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.linkValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.linkValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>
