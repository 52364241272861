<template>
  <div v-if="ready">
    <div 
      id="lounge" 
      :style="background"
    >
      <BaseLoungeWidget
        v-if="show_welcome" 
        :widget="widget_welcome" 
        :class="'base-lounge-widget '+widgetOpacityClass+' mm-lounge-widget_'+widget_welcome.type"
      />

      <div
        v-if="!show_welcome"
        class="lounge-widget-wrapper" 
      >
        <div id="lounge-widget-container">
          <div 
            v-if="widget_participants || widget_shortcut"
            class="lounge-widget-column left"
          >
            <BaseLoungeWidget
              v-if="widget_participants"
              :widget="widget_participants"
              class="base-lounge-widget"
              :class="widgetOpacityClass+' mm-lounge-widget_'+widget_participants.type"
            />
            <BaseLoungeWidget
              v-if="widget_shortcut"
              :widget="widget_shortcut"
              class="base-lounge-widget"
              :class="widgetOpacityClass+' mm-lounge-widget_'+widget_shortcut.type"
            />
          </div>
          <div 
            v-if="widget_program"
            class="lounge-widget-column center"
          >
            <BaseLoungeWidget
              :widget="widget_program"
              class="base-lounge-widget"
              :class="widgetOpacityClass+' mm-lounge-widget_'+widget_program.type"
            />
          </div>
          <div
            v-if="widget_news"
            class="lounge-widget-column right" 
          >
            <BaseLoungeWidget
              :widget="widget_news"
              class="base-lounge-widget"
              :class="widgetOpacityClass+' mm-lounge-widget_'+widget_news.type"
            />
          </div>
        </div>
      </div>
      <LoungeFooter 
        v-if="widgets_footer.length > 0"
        :widgets_footer="widgets_footer"
        :program="widget_program.values.program" 
      />
    </div>
    <div id="lounge-no-access">
      <h2>{{ translate('lounge_visit_from_desktop') }}</h2>
    </div>
  </div>
</template>



<script>
    import orderBy from "lodash/orderBy";
    import BaseLoungeWidget from "./loungeWidgets/BaseLoungeWidget";
    import LoungeFooter from "../components/Lounge/LoungeFooter";
    import Tracker from 'src/tracker/tracker'
    import { mapStores } from 'pinia'
    import { useApp } from '@/stores/app'
    import { useAppLanguages } from '@/stores/app-languages'
    import { useAppUi } from '@/stores/app-ui'
    import { useAppLounge } from '@/stores/app-lounge'

    export default {
      name: "Lounge",
      components: {
        BaseLoungeWidget,
        LoungeFooter
      },
      data () {
        return {
          // Loaded?
          ready: false,
          // LoungeWidget types
          widget_program: false,
          widget_participants: false,
          widget_news: false,
          widget_shortcut: false,
          widget_welcome: false,
          widgets_footer: []
        }
      },
      computed: {
        ...mapStores(useApp, useAppLanguages, useAppUi, useAppLounge),
        event () {
          return this.appStore.event
        },
        language() {
          return this.appLanguagesStore.activeLanguage
        },
        device() {
          const breakPoint = this.appUiStore.breakingPoint
          return breakPoint.size <= 2 ? 'mobile' : 'desktop';
        },
        active() {
          return this.appLoungeStore.active
        },
        layout() {
          return this.appLoungeStore.layout
        },
        isRegistration() {
          return this.appLoungeStore.isRegistration
        },
        show_welcome() {
          if(this.widget_welcome === false) {
            return false
          } else {
            if (localStorage.getItem("lounge-welcome")) {
              return false
            } else {
              return true
            }
          }
        },
        background() {
          let background = 'background-color: '+this.layout.background_color+';';
          if(this.layout.background != '') {
            background = background + ' background-image: url("' + this.layout.background + '"); overflow: hidden';
          }
          return background;
        },
        widgetOpacityClass() {
          if(this.layout.background == '') {
            return 'base-lounge-widget-opacity-color'
          } else {
            return '';
          }
        }
      },
      created () {
        new Promise(() => {
          this.appLoungeStore.setLoungeWidgets().then((result) => {

            const widgetsOrdered = orderBy(result.widgets.filter(widget => widget.languageId === this.appLanguagesStore.active.language_id), "sequence")

            this.ready = true;
            // Init available widget types

            for (let i = 0; i < widgetsOrdered.length; i++) {
              let widget = widgetsOrdered[i];
              if(widget.type == 'program') {
                this.widget_program = widget;
              } else if(widget.type == 'participants') {
                this.widget_participants = widget;
              } else if(widget.type == 'news') {
                this.widget_news = widget;
              } else if(widget.type == 'shortcut') {
                this.widget_shortcut = widget;
              } else if(widget.type == 'welcome') {
                this.widget_welcome = widget;
              } else if(widget.type == 'tickertape') {
                this.widgets_footer.unshift(widget);
              } else if(widget.type == 'nownext') {
                this.widgets_footer.push(widget);
              }
            }

            let tracker = new Tracker;
            tracker.run({
              'page': {
                'route' : this.$route,
                'title' : 'Lounge',
                'type': null
              }
            })

            // Show welcome widget?
            if(this.widget_welcome) {
              //this.show_welcome =  true;
            }

        }, (err) => {
            window.handle_error(err, 'page init')
            this.ready = true;
          }).catch(() => {
            this.ready = true;
            this.appStore.set404({
              route: this.$route
            })
          })
        })
      },
      mounted () {
        if(!this.active) {
          this.$router.push({
            name: "home",
            params: {}
          });
        }else if(!localStorage.getItem("lounge-checkin") && this.isRegistration) {
          this.checkin();
        }
      },
      methods: {
        checkin() {
          this.appLoungeStore.checkin().then((result) => {
            if(result.success) {
              localStorage.setItem("lounge-checkin",new Date().toString());
            }
          });
        }
      }
    }
</script>

<style lang="css" type="text/css" scoped>
  img {
    width: 100%;
    max-width: 400px;
  }

  #lounge {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-position: center;
    background-size: cover;
  }

  .lounge-widget-wrapper {
    padding: 0px;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

  }
  #lounge-widget-container {
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
    text-align: center;
    height: 764px;
  }
  .lounge-widget-column {
    overflow: hidden;
    width: 330px;
    flex: 0 0 330px;
  }
  .lounge-widget-column.left {
    margin-right: 40px;
  }
  .lounge-widget-column.center {
    width: 520px;
    flex: 0 0 520px;
  }
  .lounge-widget-column.right {
    margin-left: 40px;
  }
  .base-lounge-widget {
    background: rgba(255, 255, 255, 0.9);
    height: 100%;
    border-radius: 4px;
    position: relative;
  }
  .base-lounge-widget-opacity-color {
    background: rgba(255, 255, 255, 0.7);
  }
  .mm-lounge-widget_participants {
    height: calc(100% - 153px - 40px);
    margin-bottom: 40px;
  }
  .mm-lounge-widget_shortcut {
    height: 153px;
    overflow: hidden;
  }
  /*
  WIDTH GUTTERS
  */
  @media only screen and (max-width: 1280px) {
    .lounge-widget-column.left {
      margin-right: 16px;
    }
    .lounge-widget-column.right {
      margin-left: 16px;
    }
  }
  /*
  WIDTH WIDGET COLUMNS
  */
  @media only screen and (max-width: 1279px) {
    .lounge-widget-column {
      overflow: auto;
      width: 268px;
      flex: 0 0 268px;
    }
    .lounge-widget-column.center {
      width: 423px;
      flex: 0 0 423px;
    }
  }

  /*
  HEIGHT WIDGET COLUMNS
  */
  @media only screen and (max-height: 1023px) {
    #lounge-widget-container {
      height: 564px;
    }
  }
  @media only screen and (max-height: 767px) {
    #lounge-widget-container {
      height: 462px;
    }
  }

  /**
  BLOCK ACCESS
   */

  @media screen and (min-width: 1024px) {
    #lounge-no-access {
      display: none;
    }
  }
  @media screen and (max-width: 1023px),
  screen and (max-height: 676px) {
    #lounge {
      display: none;
    }
    #lounge-no-access {
      position: absolute;
      top: 90px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      text-align: center;
    }
  }
</style>
