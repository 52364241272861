import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import RegistrationService from "@/services/RegistrationService";
import sha1 from '@/sha1'

export const useAppRegistration = defineStore("appRegistration", () => {
  const statusState = ref("open");
  const ticketsState = ref([]);
  const isFullState = ref(false);
  const isPreviouslyCanceledState = ref(false);
  const hasPreviouslyExpiredState = ref(false);
  const teamState = ref(null);
  const purchaseState = ref({});
  const rootPurchaseState = ref({});
  const fieldsState = ref([]);
  const registrationState = ref(null);
  const reserveTicketPendingState = ref(false);
  const reserveTicketErrorState = ref(false);
  const registrationValuesState = ref({});
  const validationErrorsState = ref({});

  const status = computed(() => statusState.value);
  const tickets = computed(() => ticketsState.value);
  const isFull = computed(() => isFullState.value);
  const isPreviouslyCanceled = computed(() => isPreviouslyCanceledState.value);
  const hasPreviouslyExpired = computed(() => hasPreviouslyExpiredState.value);
  const team = computed(() => teamState.value);
  const purchase = computed(() => purchaseState.value);
  const rootPurchase = computed(() => rootPurchaseState.value);
  const fields = computed(() => fieldsState.value);
  const registration = computed(() => registrationState.value);
  const reserveTicketPending = computed(() => reserveTicketPendingState.value);
  const reserveTicketError = computed(() => reserveTicketErrorState.value);
  const registrationValues = computed(() => registrationValuesState.value);
  const isRegistered = computed(() => registrationState.value !== null);
  const validationErrors = computed(() => validationErrorsState.value);

  function load(registration) {
    purchaseState.value = registration.purchase;
    registrationState.value = registration;
  }

  function setRootPurchase(purchase) {
    rootPurchaseState.value = purchase;
  }

  function initData(data) {
    statusState.value = "open";
    ticketsState.value = data.tickets;
    registrationState.value = data.registration;
    purchaseState.value = data.purchase !== null ? data.purchase : {};

    if (data.fields !== null) {
      fieldsState.value = data.fields;
      for (const field of data.fields) {
        registrationValuesState.value[field.handle] = field.value;
      }
    } else {
      fieldsState.value = [];
    }

    teamState.value = data.team;
    isFullState.value = data.isFull;
  }

  function handleError(err) {
    return new Promise((resolve) => {
      // TODO: Vue 3 upgrade: improve this code
      let expectedStatusses = [
        {
          type: "registration_closed",
          status: "closed",
        },
        {
          type: "registration_code",
          status: "code",
        },
        {
          type: "registration_teamcode",
          status: "teamcode",
        },
        {
          type: "registration_full",
          status: "max",
        },
      ];

      let f = expectedStatusses.find((e) => e.type === err.response.data.type);
      if (f) {
        statusState.value = f.status;

        resolve();
      } else {
        window.handle_error(err, "registration handleError");
      }
    });
  }

  function init() {
    return new Promise((resolve) => {
      RegistrationService.init()
        .then((data) => {
          initData(data);

          resolve();
        })
        .catch((err) => {
          handleError(err).then(() => {
            resolve();
          });
        });
    });
  }

  function reserveTicket(data) {
    return new Promise((resolve) => {
      reserveTicketPendingState.value = true;
      reserveTicketErrorState.value = false;

      const hash = sha1.hash(`${data.check}_${data.client_id}`);

      RegistrationService.reserveTicket(data.ticket_id, hash)
        .then((data) => {
          reserveTicketPendingState.value = false;

          initData(data);

          resolve();
        })
        .catch((err) => {
          reserveTicketPendingState.value = false;

          let expectedErrors = [
            "ticket_reserve_error",
            "purchase_error",
            "purchase_active_error",
            "purchase_check_error",
            "purchase_reserve_error",
          ];
          let f = expectedErrors.findIndex((e) => err.response.data.type === e);
          if (f > -1) {
            reserveTicketErrorState.value = true;
          } else {
            handleError(err).then(() => {
              resolve();
            });
          }
        });
    });
  }

  function getFields() {
    return new Promise((resolve, reject) => {
      RegistrationService.getFields()
        .then((response) => {
          fieldsState.value = response.fields;

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function setField(payload) {
    const index = fieldsState.value.findIndex((field) => {
      return field.handle === payload.fieldHandle;
    });

    fieldsState.value[index].value = payload.value;
  }

  function addRegistrationValue(payload) {
    const fieldHandle = payload.fieldHandle;
    registrationValuesState.value[fieldHandle] = payload.value;
  }

  function postRegistration() {
    return new Promise((resolve, reject) => {
      RegistrationService.postRegistration(registrationValuesState.value)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function setValidationErrors(payload) {
    validationErrorsState.value = payload;
  }

  function removeValidationError(fieldHandle) {
    delete validationErrorsState.value[fieldHandle];
  }

  function cancelPurchaseReservation() {
    return new Promise((resolve, reject) => {
      RegistrationService.cancelPurchaseReservation()
        .then((data) => {
          isPreviouslyCanceled.value = true;

          initData(data);

          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function setPurchase(purchase) {
    purchaseState.value = purchase;
  }

  function setRegistration(registration) {
    registrationState.value = registration;
  }

  function setStatus(status) {
    statusState.value = status;
  }

  function setExpired(expired) {
    hasPreviouslyExpiredState.value = expired;
  }

  return {
    statusState,
    ticketsState,
    isFullState,
    isPreviouslyCanceledState,
    hasPreviouslyExpiredState,
    teamState,
    purchaseState,
    rootPurchaseState,
    fieldsState,
    registrationState,
    reserveTicketPendingState,
    reserveTicketErrorState,
    registrationValuesState,
    validationErrorsState,
    status,
    tickets,
    isFull,
    isPreviouslyCanceled,
    hasPreviouslyExpired,
    team,
    purchase,
    rootPurchase,
    fields,
    registration,
    reserveTicketPending,
    reserveTicketError,
    registrationValues,
    isRegistered,
    validationErrors,
    load,
    setRootPurchase,
    initData,
    handleError,
    init,
    reserveTicket,
    getFields,
    setField,
    addRegistrationValue,
    postRegistration,
    setValidationErrors,
    removeValidationError,
    cancelPurchaseReservation,
    setPurchase,
    setRegistration,
    setStatus,
    setExpired,
  };
});
