<script setup>
import { computed, reactive } from 'vue'
import PhotoDropzone from '@/components/Photos/PhotoDropzone'
import { useAppPages } from '@/stores/app-pages'
import { useRouter } from 'vue-router'

const appPagesStore = useAppPages()
const router = useRouter()

const data = reactive({
  done: false
})

const photoPage = computed(() => {
  return appPagesStore.getPage('photo')
})

const uploadPhoto = (photo) => {
  appPagesStore.uploadPhoto(photoPage.value.page_id, photo)
}

const doneUpload = () => {
  data.done = true
}

const backToOverview = () => {
  router.push({ name: 'page', params: { pageId: photoPage.value.page_id }})
}
</script>

<template>
  <div class="page-photo-upload page-flex mm-page">
    <div v-if="photoPage" class="container">
      <div v-if="!data.done">
        <PhotoDropzone @uploadPhoto="uploadPhoto" @doneUpload="doneUpload" />
      </div>
      <div v-else>
        <h3>{{ translate('photo_upload_succes') }}</h3>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <div class="btn btn-1 float-start" @click="backToOverview()" data-cy="photos-back">{{ translate('back') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.page-flex
  padding: 100px 0
.page-photo-upload
  margin-top: -10px
</style>
