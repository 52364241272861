const hasResponseErrors = (responseErrors, fieldHandle) => {
  return Object.prototype.hasOwnProperty.call(responseErrors, fieldHandle)
}

const isEmptyValue = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0
  }
  
  return [undefined, null, '', false].includes(value)
}

export default (el, binding) => {
  const responseErrors = binding.value.responseErrors
  const vuelidateField = binding.value.vuelidateField
  const fieldHandle = binding.value.field.handle
  const fieldDisabled = binding.value.field.disabled
  const value = binding.value.fieldValue
  
  if (hasResponseErrors(responseErrors, fieldHandle) || (vuelidateField.$invalid && vuelidateField.$dirty)) {
    el.classList.remove('has-success')
    el.classList.add('has-error')
  } 
  else if (
    fieldDisabled ||
    (!hasResponseErrors(responseErrors, fieldHandle) && !isEmptyValue(value) && !vuelidateField.$invalid && vuelidateField.$dirty) || // no errors, not empty, validated, edited
    (!hasResponseErrors(responseErrors, fieldHandle) && !isEmptyValue(value) && !vuelidateField.$invalid && !vuelidateField.$dirty) // no errors, not empty, unedited
  ) {
    el.classList.remove('has-error')
    el.classList.add('has-success')
  } 
  else {
    el.classList.remove('has-error')
    el.classList.remove('has-success')
  }
}
