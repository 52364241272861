<template>
    <div class="container password-container mm-custom-form">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-8 col-md-6">
                <div v-html="text_code_before"></div>

                <form @submit.prevent="sendPassword">
                    <div class="form-group" :class="{ 'has-danger': error }">
                        <input type="password" v-model="password" class="form-control" :placeholder="translate('wachtwoord')" />
                    </div>

                    <div class="text-end">
                        <button type="button" v-on:click="sendPassword" class="btn btn-1">Go</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/scss" scoped>
.password-container
  padding-top: 100px

form
  margin-top: 20px

@keyframes password-error
  0%
    margin-left: 0px

  50%
    margin-left: -3px

  75%
    margin-left: 3px

  100%
    margin-left: 0px


.has-danger
  input
    animation: password-error 0.2s


@include media-breakpoint-only(xs)
  .password-container
    padding-top: 65px

</style>
<script>
  import { mapStores } from 'pinia'
  import { useApp } from '@/stores/app'

  export default {
    data () {
      return {
        password: '',
        error: null
      }
    },
    computed: {
      ...mapStores(useApp),
      placeholder() {
        return 'Password'
      },
      text_code_before() {
        return this.appStore.event.text_code_before
      }
    },
    methods: {
      sendPassword() {
        this.error = null
        this.appStore.postPassword(this.password).then((resp) => {
          // TODO: Vue 3 upgrade: init does not expect a password parameter
          this.appStore.init()
        }).catch((err) => {
          this.error = err
        })
      }
    }
  }
</script>
