import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import _get from 'lodash/get'

export const useAppTemplate = defineStore('appTemplate', () => {
  const templateState = ref({})

  const template = computed(() => templateState.value)
  const home = computed(() => templateState.value.homepage)
  const get = computed(() => (name) => _get(templateState.value, name))

  function load(data) {
    templateState.value = data
  }

  return { templateState, template, home, get, load }
})