export default {
  methods: {
    ariaCurrentHomePage(route) {
      return String('home' === route)
    },
    ariaCurrentLounge(route) {
      return String('lounge' === route)
    },
    ariaCurrentPage(pageId,currentPageId) {
      return String(pageId === parseInt(currentPageId))
    }
  }
}
