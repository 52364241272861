<template>
  <div class="session-wrapper">
    <div class="col-1 col-sm-2">
      <span class="session-title">{{ this.translate('lounge_now_title').toUpperCase() }}</span>
    </div>
    <div class="col-7 col-sm-5 session-info" v-if="item">
      <span class="session-info-title">{{ item.title }}</span>
      <br>
      <span class="session-info-descr">{{ item.location }}</span>
    </div>
    <div class="col-4 col-sm-5 d-flex justify-content-end align-items-center" v-if="item">
      <div class="float-start" v-if="this.hasStream()">
        <div :title="this.status" :class="indicator"></div>
        <div class="session-status">
          <span>{{ this.status_translation }}</span>
        </div>
        <div class="session-access" v-if="item">
          <div :class="this.item_access_icon" class="item-access-icon"></div>
          <span>{{ this.public_status }}</span>
        </div>
      </div>
      <div class="float-start">
        <AdvancedButton :loading="false" :disabled="disabled" v-if="this.canJoin()" class="mm-btn-stream btn btn-2" :style="`font-size: 14px`" @clickEvent="join()">
          <template v-slot:loading>
            <span>{{ 'loading' }}</span>
          </template>
          <template v-slot:default>
            <span>{{ button_cta }}</span>
          </template>
        </AdvancedButton>
      </div>
    </div>
    <div class="col-11 col-sm-10 text-center" v-else>
      {{ this.no_session }}
    </div>
  </div>
</template>
<style lang="css" type="text/css" scoped>

  .session-wrapper {
    align-items: center;
    display: flex;
    width: 100%;
  }
  .session-title {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 28px;
  }
  .session-info {
    line-height: 20px;
  }
  .session-info-title {
    font-weight: bold;
    font-size: 14px;
  }
  .session-info-descr {
    color: #fff;
    font-size: 14px;
  }
  .session-indicator {
    height: 10px;
    width: 10px;
    border: 1px solid;
    border-color: #fff;
    border-radius: 50%;
    float: left;
    margin-right: 5px;
  }
  .session-indicator-join {
    background-color: green;
  }
  .session-indicator-not-started {
    background-color: yellow;
  }
  .session-indicator-finished {
    background-color: orange;
  }
  .session-indicator-no-permission {
    background-color: red;
  }
  .session-status, .session-access {
    font-size: 10px;
    float: left;
    padding-right: 15px;
  }
</style>
<script>
  import Moment from 'moment';
  import { AdvancedButton } from '@/components/Misc';
  import { mapStores } from 'pinia';
  import { useAppLanguages } from '@/stores/app-languages'
  import { useAppLiveModule } from '@/stores/app-liveModule'

  export default {
    props: {
      item: Object
    },
    data () {
      return {
        status: null,
        time_start: null,
        time_end: null,
      }
    },
    computed: {
      ...mapStores(useAppLanguages, useAppLiveModule),
      iso: function () {
        const lang = this.appLanguagesStore.active
        if (lang && lang.language_data) {
          let iso = lang.language_data.iso
          if (['en', 'nl', 'fr', 'es', 'de', 'pt', 'it'].indexOf(iso) === -1) {
            iso = 'en'
          }

          return iso
        }
      },
      live_module () {
        return '/live-module/'+this.item.page_id;
      },
      button_cta () {
        return this.translate('choose_stream')
      },
      indicator () {
        // Define indicator class
        return 'session-indicator session-indicator-' + this.status
      },
      item_access_icon () {
        if(this.item.stream.public == 'yes') {
          return 'item-access-public'
        } else {
          return 'item-access-private'
        }
      },
      status_translation () {
        // Get translation for stream status
        return this.translate('live_stream_status_label_' + this.status)
      },
      public_status () {
        if(this.item.stream.public == 'yes') {
          return this.translate('stream_access_public')
        } else {
          return this.translate('stream_access_private')
        }
      },
      no_session () {
        return this.translate('lounge_no_session')
      }
    },
    methods: {
      moment (day) {
        moment.locale(this.iso)
        return moment(day)
      },
      // Does the visitor have permissions to join the livestream
      hasPermissionToJoin() {
        return this.item.access_allowed
      },
      hasStream() {
        return this.item.stream !== null
      },
      isTimeToJoin() {
        var now = Moment();
        var walk_in_date = Moment(this.item.start_date);
        if(this.hasStream()) {
          walk_in_date = Moment(this.item.stream.walk_in_date);
        }

        // Check if walkin time is reached
        if(walk_in_date < now) {
          // Check if end date is reached
          if(this.isFinished()) {
            return false
          } else {
            // End date is not reached
            return true
          }
        } else {
          // Not yet started
          return false
        }
      },
      isFinished() {
        var now = Moment();
        var end_date = Moment(this.item.end_date);
        if(this.hasStream()) {
          end_date = Moment(this.item.stream.end_date);
        }

        // Check if end date is reached
        if(end_date < now) {
          return true
        } else {
          // End date is reached
          return false
        }
      },
      // Can someone join based on permissions and time
      canJoin() {
        if(this.hasPermissionToJoin()) {
          // Validate times
          if(this.isTimeToJoin()) {
            return true;
          } else {
            return false;
          }
        } else {
          // No permissions
          return false;
        }
      },
      // Join stream
      join() {
        if(this.canJoin()) {
          // Check if stream has access and external URL
          if(this.hasStream() && this.item.stream.platform == 'url'){
            this.appLiveModuleStore.connectToStream(this.item.stream.hash).then((result) => {
              if(result.connected){
                window.open(this.item.stream.platform_data.stream_url,'_blank');
              } else {
                alert('Something went wrong connecting to livestream. Please try again later.')
              }
            });
          } else {
            // Open live module
            this.$router.push({name: 'live-module', params: {pageId: this.item.page_id}})
          }
        }
      },
    },
    created () {
      if(this.item) {
        // Determine call to action label
        if(this.canJoin()) {
          // Visitor can join based on time and permissions
          this.status = 'join'
        } else {
          if(this.hasPermissionToJoin()) {
            if(this.isFinished()) {
              // Stream is finished
              this.status = 'finished'
            } else {
              // Stream not started yet
              this.status = 'not-started'
            }
          } else {
            if(this.isFinished()) {
              // Stream is finished
              this.status = 'finished'
            } else {
              this.status = 'no-permission'
            }
          }
        }

        // Get times
        this.time_start = Moment(this.item.start_date).format('HH:mm');
        this.time_end = Moment(this.item.end_date).format('HH:mm');
      }
    },
    components: {
      AdvancedButton
    }
  }
</script>
