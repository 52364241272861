<template>
  <div class="registration-form">
    <RegistrationFields :fields="fields" :isGuestForm="isGuestForm"></RegistrationFields>
  </div>
</template>

<script>
import RegistrationFields from '@/pages/types/Registration/RegistrationFields'

export default {
  props: {
    fields: Array,
    isGuestForm: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RegistrationFields
  }
}


</script>

<style>

</style>