<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: () => {},
    type: Object
  },
  validationErrors: {
    default: () => {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  workshopValue: ''
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.workshopValue = data.fieldData.all_workshops_full ? -1 : (data.fieldData.value)
})

const showRemainingPlaces = computed(() => {
  // TODO: Vue3 upgrade: create isMomiceTrue helper
  return data.fieldData.type_config ? data.fieldData.type_config.show_remaining_places === 'yes' : false
})

const rules = computed(() => ({
  workshopValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, data)

watch(() => data.workshopValue, (newValue, oldValue) => {
  emit('setValue', {
    value: data.workshopValue,
    oldValue: oldValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && v$.workshopValue"
    class="mm-custom-form"
  >
    <div
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.workshopValue, field: data.fieldData, fieldValue: data.workshopValue }"
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />
      <p v-if="data.fieldData.all_workshops_full">
        {{ data.fieldData.max_registration_message }}
      </p>
      <div
        v-else
        class="span-input"
      >
        <select
          :id="data.fieldData.handle"
          v-model="data.workshopValue"
          :style="{color: colors('7')}"
          :name="data.fieldData.handle"
          class="form-control"
          @blur="v$.workshopValue.$touch"
        >
          <option />
          <option
            v-for="(workshop, index) in data.fieldData.workshops"
            :key="index"
            :value="workshop.workshop_id"
            :disabled="workshop.disabled"
          >
            {{ workshop.name }}
            <small
              v-if="workshop.max && showRemainingPlaces"
              class="fw-normal"
            >({{ workshop.count }}/{{ workshop.max }})</small>
          </option>
        </select>
        <ValidationIcon
          :show-select-arrow="true"
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.workshopValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.workshopValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>
