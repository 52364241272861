<template>
    <div class="container-fluid" v-if="title">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="widget-title">{{ title }}</h2>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.widget-title
  margin: -13px 0 54px
@include media-breakpoint-down(md)
  .widget-title
    margin: -10px 0 54px
</style>
<script>
  export default {
    props: {
      title: String
    },
    data () {
      return {}
    }
  }
</script>
