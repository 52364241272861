<template>
    <div class="widget-header">
        <div class="container">
            <div class="header col-md-12 d-flex align-content-between flex-wrap">
                <div class="header-logo-container align-self-start" v-if="logo">
                    <img :src="logo" class="img-fluid" role="presentation" alt="" />
                </div>
                <div class="header-title-container" v-if="!logo">
                    <h1 class="title">{{ title }}</h1>
                </div>
                <div class="header-subtext-container" v-if="subtext">
                    <h1 class="subtext">{{ subtext }}</h1>
                </div>

                <div class="button-container align-self-end">
                    <widget-button :margin="false" :button="button"></widget-button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="sass" scoped type="text/sass">
.widget-header
  padding: 0

.header
  width: 100%
  min-height: 590px
  padding: 70px 0

.header-logo-container
  position: relative
  z-index: 100
  width: 100%
  text-align: center

  img
    display: inline
    max-height: 80%
    max-width: 80%

.header-title-container, .header-subtext-container
  text-align: center
  width: 100%

.header-title-container, .header-logo-container
  margin-bottom: 70px
  padding-top: 70px

.header-subtext-container
  margin-bottom: 70px

h1.title
  align-self: center
  font-size: 3.5rem
  line-height: 1.2
  display: inline

h1.subtext
  display: inline
  line-height: 1.2

.button-container
  width: 100%

@include media-breakpoint-only(xs)
  .header
    min-height: 480px

    .header-title-container, .header-logo-container
      padding-top: 0

    h1.title
      font-size: 3rem
</style>
<script>
  import widgetMixin from '../../../mixins/widgetMixin'

  export default {
    name: 'header-v1',
    mixins: [widgetMixin],
    data () {
      return {
      }
    },
    computed: {
      widgetId: function () {
        return this.widget.widgetId
      },
      logo () {
        return this.widget.values.logo
      },
      title () {
        return this.widget.values.title
      },
      subtext () {
        return this.widget.values.subtext
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>
