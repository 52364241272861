import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useAppVoting = defineStore('appVoting', () => {
  const currentAnswerState = ref(null)

  const getCurrentAnswer = computed(() => currentAnswerState.value)

  function setCurrentAnswer({type, option_id, answer}) {
    currentAnswerState.value = {
      type,
      option_id,
      answer
    }
  }

  return { currentAnswerState, getCurrentAnswer, setCurrentAnswer }
})