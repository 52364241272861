import { useAppRegistration } from "../stores/app-registration"
import { useAppRegistrationIntroducee } from "../stores/app-registration-introducee"

export function useField() {
  const appRegistrationStore = useAppRegistration();
  const appRegistrationIntroduceeStore = useAppRegistrationIntroducee();

  const isMultipleChoice = (type) => type === "multiplechoice";

  const isWorkshop = (type) => type === "workshop";

  const getFieldByHandle = (handle, isGuest) => {
    const fields = isGuest
      ? appRegistrationIntroduceeStore.fields
      : appRegistrationStore.fields;
    const index = fields.findIndex((field) => {
      return field.handle === handle;
    });

    return fields[index];
  };

  return {
    isMultipleChoice,
    isWorkshop,
    getFieldByHandle,
  };
}