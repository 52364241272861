/**
 * This tracker dummy server as a boilerplate/template for the functions that all analytics workers must have.
 */
export default class DummyTracker {
    /**
     * This triggers the actual sending of the data, every worker is responsible for checking
     * it's own requirements and credentials
     */
    send() {
        // console.log('Pushing Dummy')
    }

    /**
     * The data must be provided via this function because tracker.js tracker.run() does not know what the data will
     * be when it's own constructor is called, and thus cannot pass it to the constructor of workers.
     *
     * @param data
     */
    /* eslint-disable */
    setData(data) {}
}

