<template>
    <div class="widget-video">
        <widget-title :title="title"></widget-title>

        <VideoOverview :videos="videos"></VideoOverview>

        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import VideoOverview from "../../../components/Video/VideoOverview"

  export default {
    name: 'video-v1',
    components: {
      VideoOverview
    },
    mixins: [
      widgetMixin
    ],
    computed: {
      title () {
        return this.widget.values.title
      },
      videos () {
        return this.widget.values.videos
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>
