<template>
  <div class="introducees" :style="{color: colors('7')}">
    <br>
    <h3><p v-html="introduceeText"></p></h3>
    <div v-if="introducees.length > 0">
      <h3>{{ translate('my_account_introducee_title') }}</h3>
      <Summarybox
        v-for="(introducee, index) in introducees"
        :key="index"
        :name="introducee.fullname"
        :summary="introducee.summary"
        :index="index"
      >
      </Summarybox>
    </div>
    <div class="mm-custom-form">
      <div class="form-group" v-if="field.max > 0" v-bind:class="{ 'has-error': hasErrors }">
        <LabelField
          :required="true"
          :forTag="'introducees_count'"
          :text="label"
        ></LabelField>
        <div class="span-input">
          <select 
            :style="{color: colors('7')}"
            id="introducees_count"
            class="form-control"
            v-model="value"
            data-cy="guest-select"
          >
            <option :value="0">0</option>
            <option v-for="(n, index) in fieldData.max" :key="index" :value="n">
              {{ n }}
            </option>
          </select>
          <ValidationIcon :showSelectArrow="true"></ValidationIcon>
        </div>
        <ValidationErrors :validationErrors="validationErrors" :fieldHandle="fieldData.handle"></ValidationErrors>
      </div>
    </div>
  </div>
</template>

<script>
//TODO: Vue3 upgrade: move to composition API when we're not using translations mixins and vuex
import Summarybox from "../Summarybox"
import LabelField from './Common/LabelField'
import ValidationErrors from './Common/ValidationErrors'
import ValidationIcon from './Common/ValidationIcon'
import { mapStores } from 'pinia'
import { useApp } from '@/stores/app'
import { useAppRegistration } from '@/stores/app-registration'

export default {
  components: {
    Summarybox,
    LabelField,
    ValidationErrors,
    ValidationIcon
  },
  props: {
    field: Object,
    validationErrors: Object
  },
  data() {
    return {
      fieldData: {},
      value: null
    }
  },
  mounted() {
    this.fieldData = { ...this.field }
    this.value = this.fieldData.value ?? null
  },
  computed: {
    ...mapStores(useApp, useAppRegistration),
    introduceeText() {
      return this.translationExists('text_introducee_before') ? 
        this.translate('text_introducee_before') :
        this.appStore.event.text_introducee_before
    },
    label() {
      if (this.introducees.length > 0) {
        return this.translate('introducee')
      }
      return this.translate('introducee')
    },
    registration () {
      return this.appRegistrationStore.registration
    },
    introducees() {
      return this.registration ? this.registration.introducees : []
    },
    hasErrors () {
      return this.fieldData.handle in this.validationErrors
    }
  },
  watch: {
    value: function () {
      this.$emit('setValue', {
        value: this.value,
        fieldHandle: this.fieldData.handle
      })
    }
  }
}
</script>
