export function useUrl() {
  const getRootDomainFromUrl = (url) => {
    try {
      const urlObject = new URL(url);
      
      return urlObject.hostname
    } catch (error) {
      return url;
    }
  }

  return { getRootDomainFromUrl }
}