<template>
  <div>
    <div class="page-news mm-page" v-if="!details || numNews === 1">
      <news-tab-filter v-if="!details" @filterData="filterCategory" :filt="selectedCategory"></news-tab-filter>
      <news-overview v-if="!details" :news="navigationObjects"></news-overview>
    </div>
    <component v-if="details" :is="details.type + 'Component'" :post="details" ></component>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
</style>
<script>
  import Overview from '../../components/News/NewsOverview.vue'
  import Regular from '../../components/News/Types/Regular.vue'
  import Podcast from '../../components/News/Types/Podcast.vue'
  import Video from '../../components/News/Types/Video.vue'
  import Poll from '../../components/News/Types/Poll.vue'
  import Filter from '../../components/News/NewsTabFilter.vue'

  export default {
    props: ['page'],
    data () {
     return {
      news: this.page.news,
      selectedCategory: "all"
     }
    },
    computed: {
      navigationObjects () {
        let category = this.selectedCategory;
        if(category !== "all"){
          return this.news.filter(n => n.type === category)
        }else{
          return this.news
        }
      },
      details () {
        return this.news.find(p => p.news_id === parseInt(this.$route.params.param1))
      },
      numNews () {
        return this.news.length
      }
    },
    methods: {
      filterCategory(variable) {
        this.selectedCategory= variable;
      }
    },
    components: {
      'news-overview': Overview,
      'news-tab-filter': Filter,
      'regularComponent': Regular,
      'podcastComponent': Podcast,
      'videoComponent': Video,
      'pollComponent': Poll
    }
  }
</script>
