<template>
	<div id="lounge-sessions-container">
		<div class="lounge-sessions-now btn-1">
      <NowItem :item="this.now_item" />
    </div>
		<div class="lounge-sessions-next" :style="`color: ${colors(1)}`">
      <NextItem :item="this.next_item" />
    </div>
  </div>
</template>

<style lang="css" type="text/css" scoped>
	#lounge-sessions-container {
		height: 72px;
	}
	.lounge-sessions-now {
	    align-items: center;
	    display: flex;
	    width: 50%;
	    height: 100%;
	    float: left;
	  }
	.lounge-sessions-next {
		align-items: center;
		display: flex;
		width: 50%;
		height: 100%;
		float: left;
		background-color: #FFFFFF;
		color: #006F72;
	}
</style>

<script>

	import Moment from 'moment';
  	import NowItem from './NowItem';
  	import NextItem from './NextItem';

	export default {
		props: {
			program: Object|Array
		},
		data() {
			return {
				real_time: Moment(),
				now_item: null,
				next_item: null
			};
		},
		computed: {
			button_cta () {
				return this.translate('choose_stream')
			}
		},
		created() {

			// init widget
			this.createItems();

			// interval for update items
			setInterval(() => this.createItems(), 60000);

		},
		methods: {
			createItems() {
				this.real_time = Moment();

				Object.values(this.program).forEach((day) => {
			  		Object.values(day.items).forEach((item) => {

			  			let start_date = this.reformat_date(item.date_start,item.time_start);
			  			let end_date = this.reformat_date(item.date_end,item.time_end);
			  			let	walk_in_date = item.stream !== null ? Moment(item.stream.walk_in_date) : start_date;

						if(start_date < this.real_time && end_date > this.real_time) {
							if(this.now_item === null){
								this.now_item = item;
							}else{
								let current_now_start_date = this.reformat_date(this.now_item.date_start,this.now_item.time_start);
								if(start_date < current_now_start_date) {
									this.now_item = item;
								}
							}
						}else if(walk_in_date > this.real_time) {
							if(this.next_item === null){
								this.next_item = item;
							}else{
								let current_next_walkin_date = this.next_item.stream !== null ? this.next_item.stream.walk_in_date : this.reformat_date(this.next_item.date_start,this.next_item.time_start);
								if(walk_in_date < current_next_walkin_date) {
									this.next_item = item;
								}
							}
						}
		  			});
				});
			},
			moment (day) {
				moment.locale(this.iso)
				return moment(day)
			},
			reformat_date(date,time) {
				let d = date.split("-");
				return Moment(d[2]+"-"+d[1]+"-"+d[0]+" "+time+":00");
			}
		},
		components: {
			NowItem,
			NextItem
		}
	};

</script>
