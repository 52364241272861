import http from "src/http";

class LiveModuleService {
  getProgramItem(params) {
    const pageId = params.pageId;
    const hash = params.hash || false;
    const autojoin = !hash;

    return new Promise((resolve, reject) => {
      http.get(`/program-item/${pageId}/?autojoin=${autojoin}&hash=${hash}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  connectStream(hash) {
    return new Promise((resolve, reject) => {
      http.post('livestream/connect', { hash })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  leaveStream(hash) {
    return new Promise((resolve, reject) => {
      http.post('livestream/leave', { hash })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
  keepAlive(hash) {
    return new Promise((resolve, reject) => {
      http.post('livestream/keep-alive', { hash })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
  sendQuestion(hash, question) {
    return new Promise((resolve, reject) => {
      http.post('qa/send', { hash, question })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
  sendRating(hash, rating) {
    return new Promise((resolve, reject) => {
      http.post('livestream/rate', { hash, rating })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  checkCode(code) {
    return new Promise((resolve, reject) => {
      http.post('livestream/check_code', { code })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
}

export default new LiveModuleService();
