<template>
  <div class="page-flex mm-page">
    <div class="container">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div role="status">
          <p v-html="translate('interaction_enter_code_description')"></p>
        </div>
        <div class="input-label mt-3" :style="{color: colors('7')}" >
          <label for="code" v-html="translate('form_code')"></label>
        </div>
        <div class="input-container">
          <div class="input-group">
            <input class="form-control" type="text" id="code" v-model="code" data-cy="input-code"/>
          </div>
        </div>
        <div v-if="showError">
          <small v-html="translate('interaction_code_is_not_correct')"></small>
        </div>
        <AdvancedButton class="btn btn-3" @click="confirmCode()" data-cy="confirm-code">
          <span slot="default" v-html="translate('interaction_confirm_code')"></span>
        </AdvancedButton>
      </div>
    </div>
  </div>
</template>

<script>
import AdvancedButton from '@/components/Misc/AdvancedButton'
import { mapStores } from 'pinia'
import { useAppLiveModule } from '@/stores/app-liveModule'

export default {
  name: 'LiveModuleAccess',
  components: {
    AdvancedButton
  },
  data() {
    return {
      code: null,
      showError: false
    }
  },
  computed: {
    ...mapStores(useAppLiveModule)
  },
  methods: {
    async checkCode() {
      return this.appLiveModuleStore.checkCode(this.code).then((response) => {
        return response.access_allowed
      })
    },
    confirmCode() {
      this.checkCode().then((access_allowed) => {
        if (!access_allowed) {
          this.showError = true
        } else {
          this.$router.push({
            name: 'live-module',
            params: {
              pageId: this.code
            },
            query: {
              interaction: this.$route.name
            }
          })
        }
      })

    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped>
.input-label
  font-weight: 600
  text-transform: initial

input[type='text']
  display: inline-block
  max-width: 330px
  height: 56px
  margin-bottom: 65px
</style>
