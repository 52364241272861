<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div v-html="content" v-if="content" class="mm-html content row"></div>

            <Errors :errors="errors"></Errors>

            <form method="post" v-on:submit.prevent="postCode()" v-if="codeType === 'code'">
                <div class="row">
                    <div class="col text-center">
                        <label for="code">{{ translate('form_code') }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <input  type="text" id="code" v-model="code" class="form-control" @input="errors = {}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <button class="btn btn-1" type="submit">{{ translate('event_confirm_code') }}</button>
                    </div>
                </div>
            </form>

            <form method="post" v-on:submit.prevent="postCode()" v-if="codeType === 'email'">
                <div class="row">
                    <div class="col text-center">
                        <label for="code">{{ translate('field_type_email') }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <input type="text" id="code" v-model="code" class="form-control" @input="errors = {}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <button class="btn btn-1" type="submit">{{ translate('event_confirm_code_email') }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
  import Errors from "../../../../partials/errors"
  import { mapStores } from "pinia"
  import { useApp } from "@/stores/app"
  import { useVisitor } from "@/stores/visitor"
  import { useAppRegistration } from "@/stores/app-registration"

  export default {
    components: {
      Errors
    },
    props: {
      page: Object
    },
    data () {
      return {
        code: '',
        errors: {}
      }
    },
    methods: {
      postCode () {
        this.visitorStore.postCode(this.code).then(() => {
          this.appRegistrationStore.init()
        }).catch((err) => {
          this.errors = err.response.data
        })
      }
    },
    computed: {
      ...mapStores(useApp, useVisitor, useAppRegistration),
      codeType () {
        return this.appStore.event.registration_code_type
      },
      content () {
        if (this.translationExists('text_registration_code')) {
          return this.translate('text_registration_code')
        } else {
          return this.appStore.event.text_registration_code
        }
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
label
  display: inline-block
  font-weight: 600

input[type='text'], input[type='email']
  display: inline-block
  max-width: 330px
  height: 56px
  margin-bottom: 65px
</style>
