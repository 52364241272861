<template>
  <div class="lounge-widget-participants">
    <lounge-widget-title
      :title="title"
      :color="titleColor"
      :icon="icon"
    />
    <div class="lounge-widget-content">
      <div class="container lounge-widget-participants-items">
        <template v-if="participants_ready">
          <div
            v-for="participant in participants"
            :key="participant.id"
          >
            <div class="row">
              <div class="col-2">
                <ParticipantImage
                  :to="{ name: 'page', params: { pageId: page.page_id, param1: participant.id }}"
                  :image="participant.image"
                  :initials="participant.initials"
                />
              </div>
              <div class="col-10 details">
                <router-link
                  v-slot="{ navigate }"
                  :title="participant.name"
                  :to="{ name: 'page', params: { pageId: page.page_id, param1: participant.id }}"
                  custom
                >
                  <p
                    class="name"
                    @click="navigate"
                    @keypress.enter="navigate"
                  >
                    {{ participant.name }}
                  </p>
                </router-link>
                <p
                  v-if="participant.fields[0] !== undefined"
                  class="job-title"
                  :title="participant.fields[0].value"
                >
                  {{ participant.fields[0].value }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="lounge-widget-footer">
      <router-link
        v-slot="{ navigate }"
        :to="{ name: 'page', params: { pageId: page.page_id }}"
        custom
      >
        <div
          class="goto"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <span class="goto-text">{{ translate('go_to_participants') }}</span>
          <span class="goto-icon"><i class="icon-arrow-right" /></span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import widgetMixin from '../../../mixins/loungeWidgetMixin'
  import ParticipantImage from '../../../components/ImageOrInitials'
  import { mapStores } from 'pinia'
  import { useAppPages } from '@/stores/app-pages'
  import { useAppLanguages } from '@/stores/app-languages'

  export default {
    name: 'LoungeParticipantsV1',
    components: {
      ParticipantImage
    },
    mixins: [widgetMixin],
    props: ['widget'],
    data () {
      return {
        participants_ready: false,
        participants: []
      }
    },
    computed: {
      ...mapStores(useAppLanguages, useAppPages),
      language() {
        return this.appLanguagesStore.activeLanguage
      },
      page() {
        return this.appPagesStore.all.find(p => p.type === 'participants' && p.language_id === this.language)
      },
      uniqueId () {
        return this.css_id
      },
      title () {
        return this.widget.values.title
      },
      titleColor () {
        return this.widget.values.title_color
      },
      button () {
        return this.widget.values.button
      },
      icon() {
        if(this.page) {
          return this.page.icon
        } else {
          return 'icon-profile'
        }
      }
    },
    created () {
      if(this.widget.values.participants.length > 0) {
        this.participants = this.widget.values.participants;
        this.participants_ready = true;
      }
    }
  }
</script>

<style lang="sass" type="text/sass">

.lounge-widget-content
  padding-bottom: 0px
  height: calc(100% - 112px)
  overflow: auto
  .lounge-widget-participants-items
    .row
      height: 41px
      overflow: hidden
      margin-top: 16px

      .image-initials-container
        width: 32px
        height: 32px
        overflow: hidden
        border-radius: 20px
        .initials
          font-size: 15px
          font-weight: 400

      .details
        text-align: left
        padding-left: 16px
        p
          font-size: 15px
          line-height: 20px
          margin-bottom: 0px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          &.name
            font-weight: 800
            cursor: pointer

.lounge-widget-footer
  height: 56px
  padding-top: 12px
  padding-bottom: 12px
  padding-right: 16px
  position: absolute
  bottom: 0px
  width: 100%
  text-align: right

  .goto
    float: right
    font-weight: 500
    font-size: 17px
    line-height: 35px
    cursor: pointer

    .goto-text
      padding-right: 32px

    .goto-icon
      position: absolute
      top: 19px
      right: 16px
      i
        color: inherit
</style>
