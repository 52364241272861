import LoungeWidgetTitle from "../partials/loungeWidgetTitle"
import WidgetButton from "../partials/widgetButton"

export default {
  props: {
    widget: Object,
    css_id: String
  },
  components: {
    'lounge-widget-title': LoungeWidgetTitle,
    'widget-button': WidgetButton
  }
}
