import http from 'src/http'

class ApplicationService {
  initApplication (data) {
    return new Promise((resolve, reject) => {
      http.post('/init', data).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }
}

export default new ApplicationService()
