<template>
  <div class="container mm-custom-form">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 col-md-8">
        <h2
          v-if="page && page.title"
          class="mm-page-title"
        >
          {{ page.title }}
        </h2>
        <form
          novalidate
          class="mm-login-form"
          @submit.prevent="login"
        >
          <div
            v-if="error"
            class="alert alert-danger"
          >
            {{ error }}
          </div>
          <div class="form-group">
            <label>
              {{ translate('my_account_label_email') }} <span>*</span></label>
            <input
              v-model="email"
              type="email"
              class="form-control"
            >
          </div>
          <div class="form-group">
            <label>
              {{ translate('my_account_label_password') }} <span>*</span></label>
            <input
              v-model="password"
              type="password"
              class="form-control"
            >
          </div>
          <div class="form-group">
            <AdvancedButton
              class="btn btn-1 float-end"
              type="submit"
              :loading="loginPending"
              :disabled="loginPending"
            >
              {{ translate('my_account_submit_button') }}
            </AdvancedButton>
            <router-link
              v-slot="{ navigate }"
              :to="{ name: 'forgot-password' }"
              type="button"
              custom
            >
              <button
                class="btn btn-3 float-start"
                @click="navigate"
                @keypress.enter="navigate"
              >
                {{ translate('my_account_forgot_password') }}
              </button>
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { AdvancedButton } from '@/components/Misc'
  import { mapStores } from 'pinia'
  import { useVisitor } from '@/stores/visitor'
  
  export default {
    components: {
      AdvancedButton
    },
    props: ['page'],
    data () {
      return {
        email: '',
        password: '',
        error: '',
        loginPending: false
      }
    },
    computed: {
      ...mapStores(useVisitor)
    },
    methods: {
      login () {
        this.error = ''
        this.loginPending = true
        this.visitorStore.login({email: this.email, password: this.password}).then(() => {
          this.email = ''
          this.password = ''
          this.loginPending = false
        }).catch((err) => {
          this.error = err
          this.password = ''
          this.loginPending = false
        })
      }
    }
  }
</script>

<style lang="sass" type="text/scss" scoped>
.mm-login-form
  .form-group:last-child
    margin-top: 62px
</style>

