import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import PersonalProgramService from "@/services/PersonalProgramService"

export const useAppPersonalProgram = defineStore('appPersonalProgram', () => {
  const workshopsState = ref([])
  const programState = ref(null)

  const workshops = computed(() => workshopsState.value)
  const program = computed(() => programState.value)

  const setPersonalProgram = (personalProgramKey) => {
    return new Promise((resolve) => {
      PersonalProgramService.getPersonalProgram(personalProgramKey).then(personalProgramResponse => {
        workshopsState.value = personalProgramResponse.workshops
        programState.value = personalProgramResponse.program
        resolve()
      }).catch((err) => {
        console.error(err)
      })
    })
  }

  return { 
    workshopsState,
    programState, 
    workshops, 
    program, 
    setPersonalProgram
  }
})

