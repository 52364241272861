import http from 'src/http'

class IntroduceeService {

  getFields () {
    return new Promise((resolve, reject) => {
      http.get('/introducee/get_fields').then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  postIntroducee (data) {
    return new Promise((resolve, reject) => {
      http.post('/introducee', data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  clearReservations (data) {
    return new Promise((resolve, reject) => {
      http.post('/introducee/clear_reservations', data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new IntroduceeService()
