<template>
  <div />
</template>
<script>
    // TODO: Try to move the redirection to the router using a navigation guard?
    import { mapStores } from 'pinia'
    import { useAppPages } from '@/stores/app-pages'
    import { useAppLanguages } from '@/stores/app-languages'

    export default {
      name: 'register',
      computed: {
        ...mapStores(useAppPages, useAppLanguages)
      },
      mounted () {
        const lang = this.appLanguagesStore.activeLanguage
        const registerPage = this.appPagesStore.all.find(p => p.type === 'register' && p.language_id === lang)

        let url = '/page/' + registerPage.page_id

        if (this.$route.params.hash) {
          url += '/' + this.$route.params.hash
        }

        this.$router.replace(url)
      }
    }
</script>