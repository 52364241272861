
export default {
  methods: {
    goToDescription(resourceType, resourceDetails) {
      const resource = {
        resource: resourceType ? this.translate(`resource_${resourceType}`, resource) : '',
        details: resourceDetails
      }

      return resourceType && resourceDetails ? this.translate('go_to_resource', resource) : ''
    }
  }
}
