<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: {},
    type: Object
  },
  validationErrors: {
    default: {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  passwordValue: null,
  passwordRepeatValue: null,
  fieldHandlePassword: null,
  fieldHandlePasswordRepeat: null
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.passwordValue = data.fieldData.value.password ?? ''
  data.passwordRepeatValue = data.fieldData.value.passwordRepeatValue ?? ''

  data.fieldHandlePassword = `${data.fieldData.handle}.password`
  data.fieldHandlePasswordRepeat = `${data.fieldData.handle}.password_repeat`
})

const rules = computed(() => ({
  passwordValue: {
    required,
    minLengthValue: minLength(9)
  },
  passwordRepeatValue: {
    required,
    sameAsPassword: sameAs(data.passwordValue)
  }
}))
const v$ = useVuelidate(rules, data)

const fieldDataPassword = computed(() => {
  return {
    ...data.fieldData, 
    handle: data.fieldHandlePassword
  }
})

const fieldDataPasswordRepeat = computed(() => {
  return {
    ...data.fieldData, 
    handle: data.fieldHandlePasswordRepeat
  }
})

watch(() => data.passwordValue, () => {
  emit('setValue', {
    value: {
      password: data.passwordValue,
      password_repeat: data.passwordRepeatValue
    },
    fieldHandle: data.fieldData.handle
  })
})

watch(() => data.passwordRepeatValue, () => {
  emit('setValue', {
    value: {
      password: data.passwordValue,
      password_repeat: data.passwordRepeatValue
    },
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && v$.passwordValue && v$.passwordRepeatValue"
    class="mm-custom-form"
  >
    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.passwordValue, field: fieldDataPassword, fieldValue: data.passwordValue }" 
      class="form-group"
    >
      <LabelField
        :required="true"
        :for-tag="'password'"
        :text="data.fieldData.description?.password"
      />
      <div class="span-input">
        <input
          id="password"
          v-model="data.passwordValue"
          :style="{color: colors('7')}"
          name="password"
          type="password"
          class="form-control"
          @blur="v$.passwordValue.$touch"
        >
        <ValidationIcon
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.passwordValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.passwordValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="fieldDataPassword.handle"
      />
    </div>

    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.passwordRepeatValue, field: fieldDataPasswordRepeat, fieldValue: data.passwordRepeatValue }" 
      class="form-group"
    >
      <LabelField
        :required="true"
        :for-tag="'password_repeat'"
        :text="data.fieldData.description?.password_repeat"
      />
      <div class="span-input">
        <input
          id="password_repeat"
          v-model="data.passwordRepeatValue"
          :style="{color: colors('7')}"
          name="password_repeat"
          type="password"
          class="form-control"
          @blur="v$.passwordRepeatValue.$touch"
        >
        <ValidationIcon 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.passwordRepeatValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.passwordRepeatValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="fieldDataPasswordRepeat.handle"
      />
    </div>
  </div>
</template>
