import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import LiveModuleService from '@/services/LiveModuleService'

export const useAppLiveModule = defineStore('appLiveModule', () => {
  const currentItemState = ref(null)
  const currentRatingState = ref([])
  const interactionParticipantHashState = ref(null)

  const getCurrentItem = computed(() => currentItemState.value)
  const getCurrentRating = computed(() => currentRatingState.value)
  const interactionParticipantHash = computed(() => interactionParticipantHashState.value)

  function setCurrentItem(route) {
    return new Promise((resolve, reject) => {
      if (route.params.pageId) {
        LiveModuleService.getProgramItem(route.params).then((data) => {
          currentItemState.value = data
          
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        });
      } else {
        reject()
      }
    })
  }

  function connectToStream(hash) {
    return new Promise((resolve, reject) => {
      if (hash) {
        LiveModuleService.connectStream(hash).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        });
      } else {
        reject()
      }
    })
  }

  function leaveStream(hash) {
    return new Promise((resolve,reject) => {
      if (hash) {
        LiveModuleService.leaveStream(hash).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        });
      } else {
        reject()
      }
    })
  }

  function keepAlive(hash) {
    return new Promise((resolve,reject) => {
      if (hash) {
        LiveModuleService.keepAlive(hash).then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        reject();
      }
    })
  }

  function sendQuestion({hash, question}) {
    return new Promise((resolve,reject) => {
      if (hash && question) {
        LiveModuleService.sendQuestion(hash, question).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
      } else {
        reject()
      }
    })
  }

  function sendRating({hash, rating}) {
    return new Promise((resolve,reject) => {
      if (hash && rating > 0) {
        LiveModuleService.sendRating(hash, rating).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
      } else {
        reject()
      }
    })
  }

  function setCurrentRating(rating) {
    return new Promise((resolve) => {
      currentRatingState.value = rating
      resolve()
    })
  }

  function checkCode(code) {
    return new Promise((resolve, reject) => {
      LiveModuleService.checkCode(code).then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
    })
  }

  function setInteractionParticipantHash(hash) {
    interactionParticipantHashState.value = hash
  }

  return { 
    currentItemState,
    currentRatingState,
    interactionParticipantHashState,
    getCurrentItem,
    getCurrentRating,
    interactionParticipantHash,
    setCurrentItem,
    connectToStream,
    leaveStream,
    keepAlive,
    sendQuestion,
    sendRating,
    setCurrentRating,
    checkCode,
    setInteractionParticipantHash
   }
})