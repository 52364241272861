<script setup>
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import moment from 'moment'
import { useAppSurvey } from '@/stores/app-survey'
import { useAppUi } from '@/stores/app-ui'
import { useAppPages } from '@/stores/app-pages'
import QuestionSurvey from '../../components/Survey/QuestionSurvey.vue'
import { useTranslations } from '@/composables/translations'

const props = defineProps({
  page: {
    default: () => {},
    type: Object
  }
})

const inited = ref(false)
const validation = ref(true)
const requestPending = ref(false)

const router = useRouter()
const route = useRoute()

const appPagesStore = useAppPages()
const appSurveyStore = useAppSurvey()
const appUiStore = useAppUi()

const { translate } = useTranslations()

onMounted(() => {
  if (route.path === '/survey') {
    const surveyPage = appPagesStore.currentLanguage.filter(p => p.type === 'survey')
    if (surveyPage && surveyPage.length) {
      router.push({
        name: 'page',
        params: {
          pageId: surveyPage[0].page_id
        }
      })
    }
  } else{
    init()

    appUiStore.setFooter(false)
    appUiStore.setScrollToTop(false)
    appUiStore.setSurveyFooter(true)
  }

})

const start = () => {
  requestPending.value = true
  appSurveyStore.start().then(() => {
    requestPending.value = false
  })
}

const next = () => {
  requestPending.value = true
  appSurveyStore.next().then(() => {
    window.scrollTo(0, 0)
    validation.value = true
    requestPending.value = false
  }).catch((err) => {
    if (err.response && err.response.status === 400) {
      validation.value = false
      window.scrollTo(0, 0)
    }
    requestPending.value = false
  })
}

const previous = () => {
  requestPending.value = true
  validation.value = true
  appSurveyStore.previous().then(() => {
    window.scrollTo(0, 0)
    requestPending.value = false
  }).catch(function (err) {
    requestPending.value = false
    window.handle_error(err, 'survey previous catch')
  })
}

const init = () => {
  requestPending.value = true
  appSurveyStore.init().then(() => {
    inited.value = true
    requestPending.value = false
  }).catch(function (err) {
    requestPending.value = false
    window.handle_error(err, 'survey init method')
  })
}

const startButton = computed(() => {
  if (props.page.start_button) {
    return props.page.start_button
  } else {
    return translate('event_survey_button')
  }
})

const question = computed(() => appSurveyStore.question)

const progress = computed(() => appSurveyStore.progress)

const finished = computed(() => appSurveyStore.finished)

const loading = computed(() => appSurveyStore.loading)

const finishTime = computed(() => {
  const duration = appSurveyStore.finish_time
  let seconds = moment.duration(duration, 'seconds').seconds()

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  return Math.floor(moment.duration(duration, 'seconds').asMinutes()) + ':' + seconds
})

const number = computed(() => appSurveyStore.number)

onUnmounted(() => {
  appUiStore.setFooter(true)
  appUiStore.setScrollToTop(true)
  appUiStore.setSurveyFooter(false)
})
</script>

<template>
  <div
    v-if="inited"
    class="page-survey"
  >
    <div class="container">
      <div
        v-if="loading"
        class="loading"
      >
        <ProgressSpinner />
      </div>
      <div v-if="!question && !finished">
        <div
          v-if="props.page.logo"
          class="logo"
        >
          <img
            :src="props.page.logo"
            role="presentation"
            alt=""
          >
        </div>
        <div
          v-if="props.page.text_start"
          class="row content text-start mm-html"
          v-html="props.page.text_start"
        />

        <div class="text-center">
          <button
            class="btn btn-6"
            :disabled="requestPending"
            data-cy="survey-start"
            @click="start()"
          >
            {{ startButton }}
          </button>
        </div>
      </div>
      <div v-if="!question && finished">
        <div
          v-if="props.page.text_end"
          class="row content text-finish mm-html"
          v-html="props.page.text_end"
        />

        <div class="finish_time text-center">
          {{ translate('text_survey_end', finishTime) }}
        </div>
      </div>
      <QuestionSurvey
        v-if="question"
        :question="question"
        :validation="validation"
        :number="number"
      />
    </div>
    <div id="survey-progress">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-3 col-sm-2">
            <a
              v-if="question && !finished"
              class="btn btn-1"
              :aria-label="translate('previous')"
              tabindex="0"
              :disabled="requestPending"
              @click="previous()"
              @keypress.enter="previous()"
            >
              <i class="icon-arrow-left survey-icon" />
            </a>
          </div>
          <div
            v-if="question || finished"
            class="col-6 col-sm-8 completed"
          >
            <ProgressBar
              :value="progress"
              class="bar"
              :aria-label="translate('survey_progress')"
            />
          </div>
          <div class="col-3 col-sm-2 text-end">
            <a
              v-if="question && !finished"
              class="btn btn-1"
              :aria-label="translate('next')"
              tabindex="0"
              :disabled="requestPending"
              @click="next()"
              @keypress.enter="next()"
            >
              <i class="icon-arrow-right survey-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
.loading
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.logo
  text-align: center
  margin-bottom: 50px
  img
    max-width: 400px
    max-height: 300px

@include media-breakpoint-down(md)
  .logo
    img
      max-width: 100%
      max-height: 450px

.text-start, .text-finish
  padding-bottom: 40px

#survey-progress
  position: fixed
  bottom: 0px
  left: 0px
  width: 100%
  height: $survey-footer-height
  background-color: #FFFFFF
  z-index: 8000
  border-top: 1px solid

  .row
    height: 90px

  a
    width: 35px
    height: 35px
    padding: 0 !important
    line-height: 35px

    [class^="icon-"], [class*=" icon-"]
      font-size: 1.5rem
      line-height: 31px

  .completed
    font-size: 0.75rem
    font-weight: 600

    h4
      font-weight: 600
      text-align: left
      margin-bottom: 0

    .bar
      outline: 2px solid
      height: 20px
      position: relative
      box-sizing: border-box
      outline-offset: -2px

      .fill
        position: absolute
        top: 0px
        left: 0px
        height: 12px
        transition: width 0.5s

.finish_time
  font-weight: 600
</style>
