<template>
    <div class="widget-welcome">
      <lounge-widget-title :title="title" :color="titleColor" :cStyle="'margin-left: 0px'"></lounge-widget-title>
      <div class="widget-welcome-content ">
        <div class="mm-html" v-if="widget.values.content" v-html="widget.values.content">
        </div>
        <div class="stream-embed" v-if="widget.values.embed">
          <div class="stream-embed-other" v-html="widget.values.embed"></div>
        </div>
      </div>
      <div class="widget-welcome-footer">
        <button class="btn btn-1" v-on:click="goToLounge" type="button" tag="button">{{ translate('go_to_lounge') }}</button>
      </div>
    </div>
</template>
<style lang="sass" type="text/sass">
.widget-welcome
  position: absolute !important
  height: 486px !important
  width: 560px
  left: calc(50% - 280px)
  z-index: 100
  top: calc(50% - 243px)

  .lounge-widget-title
    h2
      font-style: normal
      font-weight: 800
      font-size: 28px
      text-align: center

  .widget-welcome-content
    height: 290px
    background: #FFFFFF
    margin: 32px
    padding: 32px
    overflow: auto

  .widget-welcome-footer
    text-align: center

</style>
<script>
  import widgetMixin from '../../../mixins/loungeWidgetMixin'
  import { mapStores } from 'pinia'
  import { useVisitor } from '@/stores/visitor'

  export default {
    name: 'lounge-shortcut-v1',
    props: ['widget'],
    mixins: [widgetMixin],
    data () {
      return {
      }
    },
    computed: {
      ...mapStores(useVisitor),
      uniqueId () {
        return this.css_id
      },
      title () {
        let title = this.translate('lounge_welcome_title');
        const invite = this.visitorStore.invite
        if (invite.firstname) {
            title += ' ' + invite.firstname
        }
        return title
      }
    },
    components: {
    },
    methods: {
      goToLounge () {
        localStorage.setItem("lounge-welcome",new Date().toString());
        this.$router.push({
          name: "home",
          params: {}
        });
      }
    }
  }
</script>
