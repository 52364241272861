<template>
    <div class="box">
        <h3>{{ name }}</h3>
        <div class="row">
            <div class="col-10">
                <p v-if="introduceeCount" class="introducee-count">{{ intro_count_text }}</p>
                <p>{{ translate('registration_completed_summary_message', {
                  email: introducee_email || registration.email
                }) }}</p>
            </div>
            <div class="col-2 text-end">
                <i @click="toggleHidden()" :class="{ 'icon-arrow-up': !hidden, 'icon-arrow-down': hidden}"></i>
            </div>
        </div>

        <transition name="toggle">
            <div v-if="!hidden" class="summary">
                <div v-for="(value, label) in summary" :key="label">
                    <span class="label">{{ label }}</span><br/>
                    {{ value }}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useAppRegistration } from '@/stores/app-registration'
  
    export default {
      props: {
        name: String,
        index: Number,
        summary: Object,
        introduceeCount: {
          default: null,
          type: Number
        }
      },
      computed: {
        ...mapStores(useAppRegistration),
        registration () {
          return this.appRegistrationStore.registration
        },
        introducee_email () {
          let introducee = this.registration.introducees[this.index]
          if (introducee && introducee.email) {
            return introducee.email
          } else {
            return null
          }
        },
        intro_count_text () {
          return `${this.introduceeCount} ${this.introduceeCount > 1 ? this.translate('introducee_plural') : this.translate('introducee')}`
        }
      },
      data () {
        return {
          hidden: true
        }
      },
      methods: {
        toggleHidden () {
          this.hidden = !this.hidden
        }
      }
    }
</script>

<style type="text/scss" lang="scss" scoped>
[class^="icon-"], [class*=" icon-"] {
  cursor: pointer;
  user-select: none;
  font-size: 2.0rem;
}
p.introducee-count {
  font-weight: 600;
}
.summary {
  max-height: 800px;
  overflow: hidden;
  padding-top: 20px;
  span.label {
    font-weight: 600;
  }
}
.toggle-enter-active, .toggle-leave-active {
  transition: all .5s;
}
.toggle-enter, .toggle-leave-to /* .fade-leave-active below version 2.1.8 */ {
  max-height: 0;
  padding-top: 0;
}
</style>
