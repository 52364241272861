<template>
    <div class="widget-program">
        <widget-title :title="title"></widget-title>

       <ProgramWidget :widget="true" :scrollToActiveDateOnStart="false" :day-title="title_program" :uniqueId="uniqueId" :program="program"></ProgramWidget>

        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import ProgramWidget from "../../../components/Program/ProgramWidget"

  export default {
    name: 'program-v1',
    components: {
      ProgramWidget
    },
    mixins: [
      widgetMixin
    ],
    computed: {
      uniqueId () {
        return this.css_id
      },
      title () {
        return this.widget.values.title
      },
      program () {
        return this.widget.values.program
      },
      title_program () {
        return this.widget.values.title_program
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>
