<template>
    <div class="widget-contacts">
        <widget-title :title="title"></widget-title>
        <div class="container">
            <ContactOverview :contacts="contacts"></ContactOverview>

            <widget-button :button="button"></widget-button>
        </div>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import ContactOverview from "../../../components/Contacts/ContactOverview"

  export default {
    name: 'contacts-v1',
    components: {
      ContactOverview
    },
    mixins: [widgetMixin],
    data () {
      return {
      }
    },
    computed: {
      pageId () {
        return this.widget.values.page_id
      },
      contacts () {
        return this.widget.values.contacts
      },
      title () {
        return this.widget.values.title
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>
