<script setup>
import { ref, computed, reactive, onMounted, watch, nextTick } from 'vue'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: () => {},
    type: Object
  },
  validationErrors: {
    default: () => {},
    type: Object
  }
})

const domain = ref(null)

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  emailValue: '',
  domainValue: '',
  isValid: null
})

onMounted(() => {
  data.fieldData = { ...props.field }
  normalizeEmail()
  validateEmail()
})

const restrictedDomainHasError = computed(() => {
  if (!props.validationErrors[data.fieldData.handle]) {
    return false
  }

  return props.validationErrors[data.fieldData.handle].length >= 1
})

const checkKeycode = (e) => {
  if (e.key === '@') {
    e.preventDefault()
    domain.value.focus()
  }
}

const normalizeEmail = () => {
  if (data.fieldData.value) {
    let parts = data.fieldData.value.split('@')
    if (parts.length) {
      data.emailValue = parts[0]
      if (parts.length > 1) {
        data.domainValue = parts[1]
      }
    }
  }
}

const validateEmail = () => {
  if (data.emailValue && data.domainValue) {
    let patt = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    nextTick(() => {
      data.isValid = patt.test(joinDomain(data.emailValue, data.domainValue))
    })
  }
}

const joinDomain = (email, domain) => {
  return [email, domain].join('@')
}


watch(() => data.emailValue, () => {
  emit('setValue', {
    value: joinDomain(data.emailValue, data.domainValue),
    fieldHandle: data.fieldData.handle
  })
})

watch(() => data.domainValue, () => {
  emit('setValue', {
    value: joinDomain(data.emailValue, data.domainValue),
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData"
    class="mm-custom-form"
  >
    <div class="form-group">
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />
      <div class="row">
        <div 
          class="col-6 col-sm-6 col-md-7 pr-xs-15"
          :class="{'has-error': restrictedDomainHasError || (!data.isValid && data.emailValue && data.domainValue), 'has-success': !restrictedDomainHasError && data.isValid === true && data.emailValue && data.domainValue}"
        >
          <div class="span-input">
            <input 
              :id="data.fieldData.handle"
              v-model="data.emailValue"
              :style="{color: colors('7')}"
              :name="data.fieldData.handle"
              type="text"
              class="form-control input-email"
              :disabled="data.fieldData.disabled"
              @change="validateEmail"
              @keydown="checkKeycode"
            >
            <ValidationIcon
              :response-errors="validationErrors"
              :field-disabled="data.fieldData.disabled"
              :value="data.emailValue"
            />
          </div>
        </div>
        <div class="col-1 p-xs-0 mm-input-email-divider">
          @
        </div>
        <div class="col-5 col-sm-5 col-md-4  pl-xs-0">
          <div>
            <div class="span-input">
              <select
                ref="domain"
                v-model="data.domainValue"
                :name="field.handle"
                class="form-control mm-input-domain"
                :style="{color: colors('7')}"
                @change="validateEmail"
              >
                <option
                  value=""
                  disabled
                  hidden
                >
                  {{ translate('emaildropdown_domain_label') }}
                </option>
                <option
                  v-for="(domain, index) in data.fieldData.domains"
                  :key="index"
                  :value="domain"
                >
                  {{ domain }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>

<style scoped>
.input-email {
  height: calc(2.25rem + 4px);
}
.mm-input-domain {
  padding-right:20px;
}
.mm-custom-form .error-messages {
  display:block !important;
}
.mm-input-email-divider {
  line-height: 1.25;
  padding-top: 0.5rem !important;
}

@media (max-width: 480px) {
  .pr-xs-15 {
    padding-right: 15px !important;
  }
  .p-xs-0 {
    padding: 0;
  }
  .pl-xs-0 {
    padding-left: 0 !important;
  }
}

</style>