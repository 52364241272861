<template>
  <div class="mm-page page-flex">
    <div
      id="personal-program"
      class="container inner" 
    >
      <h2 v-html="translate('my_program')" />
      <div 
        v-if="workshops" 
        class="pt-4"
      >
        <Summary :workshops="workshops" />
      </div>
      <div 
        v-if="program"
        class="pt-4"
      >
        <ProgramWidget 
          :widget="false"
          :uniqueId="uniqueId"
          :scrollToActiveDateOnStart="false"
          :dayTitle="program.dayTitle"
          :program="program.program"
          :pageHasContent="false"
          :pageHasImage="false"
          :dayFormat="program.day_format"
          :showMonth="program.show_month"
          :daysHeaderAbsolute="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import uuid from "uuid/v4"
import Summary from "../components/Workshop/Summary"
import ProgramWidget from "../components/Program/ProgramWidget"
import { mapStores } from "pinia"
import { useAppPersonalProgram } from "@/stores/app-personalProgram"

export default {
  name: 'PersonalPage',
  components: {
    Summary,
    ProgramWidget
  },
  computed: {
    ...mapStores(useAppPersonalProgram),
    workshops () {
      return this.appPersonalProgramStore.workshops
    },
    program () {
      return this.appPersonalProgramStore.program
    },
    uniqueId() {
      return uuid();
    }
  },
  mounted() {
    if (this.$route.query.ppk) {
      this.appPersonalProgramStore.setPersonalProgram(this.$route.query.ppk).then()
    }
  }
}
</script>

<style scoped type="text/sass" lang="sass">

</style>