import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import IntroduceeService from "@/services/IntroduceeService";

export const useAppRegistrationIntroducee = defineStore(
  "appRegistrationIntroducee",
  () => {
    const fieldsState = ref([]);
    const purchasesState = ref([]);
    const guestValuesState = ref({});
    const validationErrorsState = ref({});

    const fields = computed(() => fieldsState.value);
    const purchases = computed(() => purchasesState.value);
    const guestValues = computed(() => guestValuesState.value);
    const validationErrors = computed(() => validationErrorsState.value);

    function getFields() {
      return new Promise((resolve, reject) => {
        fieldsState.value = [];
        IntroduceeService.getFields()
          .then((response) => {
            fieldsState.value = response.data.fields;

            for (const field of response.data.fields) {
              guestValuesState.value[field.handle] = field.value;
            }

            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    }

    function postIntroducee(payload) {
      return new Promise((resolve, reject) => {
        const data = {
          ...guestValuesState.value,
          purchase: payload,
        };

        IntroduceeService.postIntroducee(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }

    function removePurchase(pid) {
      return new Promise((resolve) => {
        const newPurchases = purchasesState.value.filter((e) => e.hash !== pid);

        purchasesState.value = newPurchases;

        resolve();
      });
    }

    function clearReservations() {
      return new Promise((resolve, reject) => {
        IntroduceeService.clearReservations({ purchases: purchasesState.value })
          .then((response) => {
            purchasesState.value = [];

            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }

    function setField(payload) {
      const index = fieldsState.value.findIndex((field) => {
        return field.handle === payload.fieldHandle;
      });

      fieldsState.value[index].value = payload.value;
    }

    function addGuestValue(payload) {
      const fieldHandle = payload.fieldHandle;
      guestValuesState.value[fieldHandle] = payload.value;
    }

    function setValidationErrors(payload) {
      validationErrorsState.value = payload;
    }

    function removeValidationError(fieldHandle) {
      // TODO: Vue 3 upgrade: is it called?
      delete validationErrorsState.value[fieldHandle];
    }

    function setPurchases(purchases) {
      purchasesState.value = purchases;
    }

    return {
      fieldsState,
      purchasesState,
      guestValuesState,
      validationErrorsState,
      fields,
      purchases,
      guestValues,
      validationErrors,
      getFields,
      postIntroducee,
      removePurchase,
      clearReservations,
      setField,
      addGuestValue,
      setValidationErrors,
      removeValidationError,
      setPurchases,
    };
  }
);