import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useAppWidgets = defineStore("appWidgets", () => {
  const widgetsState = ref({});

  const all = computed(() => widgetsState.value);

  async function load(data) {
    widgetsState.value = data;
  }

  return { widgetsState, all, load };
});
