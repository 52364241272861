<script setup>
import { computed, ref, reactive, onMounted, watch } from 'vue'

const props = defineProps({
  field: {
    default: {},
    type: Object
  },
  validationErrors: {
    default: {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  checked: false
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.checked = data.fieldData.value ?? ''
})

const input = ref(null)

const clickCheckbox = () => {
  input.value.click()
}

const participantsFields = computed(() => {
  return data.fieldData.participant_fields ? 
    data.fieldData.participant_fields.join(', ') :
    ''
})

watch(() => data.checked, () => {
  emit('setValue', {
    value: data.checked,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div class="mm-custom-form" :style="{color: colors('7')}">
    <div class="form-check mm-form-checkbox">
      <input
        :id="data.fieldData.handle"
        :name="data.fieldData.handle"
        class="form-check-input"
        type="checkbox"
        v-model="data.checked"
        tabindex="-1"
        ref="input"
      >
      <label
        class="form-check-label"
        :aria-label="translate('participants_choice_add')"
        :for="data.fieldData.handle"
        tabindex="0"
        @keyup.space="clickCheckbox()"
      >
        {{ translate('participants_choice_add') }}
        <small class="fw-normal d-block lh-base mt-1">
          {{ `${translate('participant_choice_visible_fields')} ${participantsFields}` }}
        </small>
      </label>
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>
.form-check.mm-form-checkbox
    padding: 10px 0px 20px 0px

.form-check-label:focus-visible
  outline: 2px dotted !important
  outline-offset: 3px
  
</style>
