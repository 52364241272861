<template>
    <div class="page-plan">
        <div class="container">
            <div class="row content mm-html" v-html="this.page.content"></div>

            <PlanOverview :plan="this.page.plan"></PlanOverview>
        </div>
    </div>
</template>

<script>
  import PlanOverview from "../../components/Plan/PlanOverview"

  export default {
    components: {
      PlanOverview
    },
    props: [
      'page'
    ]
  }
</script>
