<template>
  <div class="page-forgot-password mm-custom-form">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6 col-md-8">
          <div
            v-if="!done"
            novalidate
          >
            <p>{{ text }}</p>
            <form @submit.prevent="post">
              <div
                v-if="error && error === 'account_not_found'"
                class="alert alert-danger"
              >
                {{ translate('my_account_forgot_password_email_unknown') }}
              </div>
              <div
                v-else-if="error"
                class="alert alert-danger"
              >
                {{ error }}
              </div>
              <div class="form-group">
                <label>
                  {{ translate('my_account_label_email') }} <span>*</span></label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <AdvancedButton
                  :loading="isLoading"
                  :disabled="isLoading"
                  class="btn btn-1"
                  type="submit"
                >
                  {{ translate('my_account_submit_forgot_password') }}
                </AdvancedButton>
              </div>
            </form>
          </div>
          <div v-else>
            <p>{{ text_forgot_password_success }}</p>
            <router-link
              v-slot="{ navigate }"
              :to="{name: 'account'}"
              custom
            >
              <button
                class="btn btn-3"
                @click="navigate"
                @keypress.enter="navigate"
              >
                {{ translate('my_account_submit_button') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { AdvancedButton } from '@/components/Misc'
  import http from 'src/http'
  import { mapStores } from "pinia"
  import { useApp } from "@/stores/app"

  export default {
    components: {
      AdvancedButton
    },
    data () {
      return {
        isLoading: false,
        done: false,
        email: '',
        error: '',
        testField: {
          field: 'email',
          field_id: '123abc',
          handle: 'f04bf24e5344206f8479bd92d35b9843',
          description: 'Email address',
          required: true,
          disabled: false,
          value: '',
          type: 'text',
          type_config: null
        }
      }
    },
    computed: {
      ...mapStores(useApp),
      text () {
        if (this.event.salutation === 'informal') {
          return this.translate('my_account_forgot_password_explanation_informal')
        } else {
          return this.translate('my_account_forgot_password_explanation')
        }
      },
      text_forgot_password_success () {
        if (this.event.salutation === 'informal') {
          return this.translate('new_my_account_forgot_password_success_informal', {
            email: this.email
          })
        } else {
          return this.translate('new_my_account_forgot_password_success', {
            email: this.email
          })
        }
      },
      event () {
        return this.appStore.event
      }
    },
    methods: {
      post () {
        this.isLoading = true
        this.error = ''

        http.post('/account/forgot-password', {'email': this.email}).then(() => {
          this.done = true
          this.isLoading = false
        }).catch(err => {
          this.isLoading = false
          let { data: errorData } = err.response
          if (errorData && errorData.type === 'account_not_found') {
            this.error = err.response.data.type
          } else {
            this.error = err.response.data.data.email
          }
        })
      }
    }
  }
</script>
<style lang="sass" type="text/sass" scoped>
.page-forgot-password
  padding-top: 100px
  .form-group:last-child
    margin-top: 62px
@include media-breakpoint-down(md)
  .page-forgot-password
    padding-top: 65px
</style>
