import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import PaymentService from '@/services/PaymentService'

export const useAppPayment = defineStore('appPayment', () => {
  const sessionDataState = ref(null)
  const paymentResponseState = ref({})
  const redirectResultState = ref({})

  const sessionData = computed(() => sessionDataState.value)
  const paymentResponse = computed(() => paymentResponseState.value)
  const redirectResult = computed(() => redirectResultState.value)

  function setSessionData() {
    return new Promise((resolve) => {
      PaymentService.getPaymentSession().then(sessionResponse => {
        sessionDataState.value = sessionResponse
        resolve()
      }).catch((err) => {
        console.error(err)
      })
    })
  }

  function setPaymentResponse(paymentResponse) {
    paymentResponseState.value = paymentResponse
  }

  function setRedirectResult(redirectResult) {
    redirectResultState.value = redirectResult
  }

  function restartSessionData() {
    sessionDataState.value = null
  }

  function cancelOrRefused(payment) {
    return new Promise((resolve) => {
      PaymentService.cancelOrRefusedPayment(payment.resultCode, payment.redirectResult).then(() => {
        resolve()
      }).catch((err) => {
        console.error(err)
      })
    })
  }

  return { 
    sessionDataState,
    paymentResponseState,
    redirectResultState,
    sessionData,
    paymentResponse,
    redirectResult,
    setSessionData,
    setPaymentResponse,
    setRedirectResult,
    restartSessionData,
    cancelOrRefused
   }
})