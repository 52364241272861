<template>
  <div>
    <div v-for="(widget, i) in widgets" :key="i" data-testid="homepage-widget">
      <BaseWidget :widget="widget"></BaseWidget>
    </div>
  </div>
</template>
<script>
import BaseWidget from "./widgets/BaseWidget.vue";
import orderBy from "lodash/orderBy";
import Tracker from 'src/tracker/tracker'
import { mapStores } from 'pinia'
import { useAppPages } from '@/stores/app-pages'
import { useAppLanguages } from '@/stores/app-languages'
import { useAppTemplate } from '@/stores/app-template'
import { useAppWidgets } from '@/stores/app-widgets'
import { useAppLounge } from '@/stores/app-lounge'

export default {
  name: "homepage",
  data() {
    return {
      totalWidgets: 0
    };
  },
  mounted() {
    document.title = this.pageTitle()
    this.$nextTick(() => {
      this.init();
    });
  },
  created() {
    this.appPagesStore.setCurrentPage(this.$route)

    let tracker = new Tracker;
    tracker.run({
      'page': {
        'route' : this.$route,
        'title' : 'Home',
        'type'  : 'home'
      }
    })

  },
  methods: {
    init() {
      if (this.showLounge === true) {
        this.$router.push({
          name: "lounge",
          params: {}
        });
      } else if (this.showHomepage === false) {
        const pages = this.appPagesStore.currentLanguage
        for (let page of pages) {
          if (page.active === "yes") {
            this.$router.push({
              name: "page",
              params: {
                pageId: page.page_id
              }
            });
            break;
          }
        }
      }
    },
    pageTitle() {
      return this.appLanguagesStore.pageTitle('Home')
    }
  },
  computed: {
    ...mapStores(useAppPages, useAppLanguages, useAppTemplate, useAppWidgets, useAppLounge),
    language() {
      return this.appLanguagesStore.activeLanguage
    },
    template() {
      return this.appTemplateStore.template
    },
    widgets() {
      return orderBy(
        this.appWidgetsStore.all.filter(widget => {
          return (
            widget.languageId === this.appLanguagesStore.active.language_id
          );
        }),
        "sequence"
      );
    },
    showLounge () {
      return this.appLoungeStore.active
    },
    showHomepage () {
      let { homepage: showHomepage = true } = this.template
      return showHomepage
    },
  },
  components: {
    BaseWidget
  }
};
</script>
