<template>
    <div>
        <div class="page-podcast mm-page" v-if="!details || numPods === 1">
            <div class="container">
              <div class="row content mm-html" v-if="page.content" v-html="page.content"></div>
            </div>
            <PodcastOverview v-if="!details" :podcasts="navigationObjects"></PodcastOverview>
        </div>
        <div v-if="details" class="page-podcast-detail">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8 image-wrapper" v-if="details.image">
                      <img :src="details.image" :alt="details.title" />
                    </div>
                    <div class="col-12 col-lg-8">
                        <div class="podcast-wrapper">
                            <div v-html="details.embed"></div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-8 h3 podcast-title">
                        {{ details.title }}
                    </div>
                    <div class="col-12 col-lg-8">
                        <hr/>
                    </div>
                </div>

                <detail-navigation v-if="page.podcasts.length > 1"
                                   :indexRoute="{ name: 'page', params: { pageId: page.page_id }}"
                                   :nextRoute="nextRoute"
                                   :previousRoute="previousRoute">
                </detail-navigation>
            </div>
        </div>
    </div>
</template>

<script>
  import detailNavigationMixin from '../../mixins/detailNavigationMixin'
  import PodcastOverview from "../../components/Podcast/PodcastOverview"

  export default {
    components: {
      PodcastOverview
    },
    props: [
      'page'
    ],
    mixins: [
      detailNavigationMixin
    ],
    computed: {
      navigationObjects () {
        return this.page.podcasts
      },
      details () {
        if (this.page.podcasts.length === 1) {
          return this.page.podcasts[0]
        }

        return this.page.podcasts.find(p => p.id === parseInt(this.$route.params.param1))
      },
      numPods () {
        return this.page.podcasts.length
      }
    }
  }
</script>

<style lang="sass" type="text/sass">
.image-wrapper
  text-align: center

  img
    max-width: 100%
    margin: 0 auto
    margin-bottom: 30px

.podcast-wrapper
  margin-bottom: 30px
  padding: 5px

.page-podcast-detail
  .podcast-title
    font-weight: 600
</style>
