<template>
    <div class="widget-sponsors">
        <widget-title :title="title"></widget-title>
        <div class="container">
            <div
              v-for="(chunk, indexChunk) in sponsorsChunked"
              :key="indexChunk"
              class="row sponsor-row justify-content-center"
            >
                <div class="col-12 col-md-6 col-lg-3" v-for="(sponsor, indexSponsor) in chunk" :key="indexSponsor">
                    <div class="sponsor d-flex justify-content-center" :style="sponsorStyle">
                      <a v-if="sponsor.url" :href="sponsor.url" target="_blank" :aria-label="sponsor.title">
                        <img v-if="sponsor.image" class="sponsor-image" :src="sponsor.image" :alt="sponsor.title"/>
                      </a>
                      <div v-else :aria-label="sponsor.title">
                        <img v-if="sponsor.image" class="sponsor-image" :src="sponsor.image" :alt="sponsor.title"/>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import chunk from 'lodash/chunk'
  import { mapStores } from "pinia"
  import { useAppTemplate } from "@/stores/app-template"

  export default {
    name: 'sponsors-v1',
    mixins: [
      widgetMixin
    ],
    methods: {
      visitSponsorPage () {
        if (this.button && this.button.link) {
          this.$router.push(`/page/${this.button.link}`)
        }
      }
    },
    computed: {
      ...mapStores(useAppTemplate),
      title () {
        return this.widget.values.title
      },
      button () {
        return this.widget.values.button
      },
      sponsorStyle () {
        const colors = this.appTemplateStore.get('colors')
        return {
          border: `1px solid ${colors['5']}`
        }
      },
      indexView () {
        let length = this.widget.values.sponsors.length

        let modulo = length % 4

        if (modulo !== 1) {
          return 4
        }

        if (modulo === 1) {
          return 3
        }
      },
      sponsorsChunked () {
        return chunk(this.widget.values.sponsors, this.indexView)
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.sponsor-row
  margin-bottom: 40px

.sponsor
  height: 130px
  padding: 13px
  margin-bottom: 20px
  cursor: pointer
  align-items: center
  a
    color: inherit

.sponsor:focus-visible
  outline: 2px dotted !important
  outline-offset: 3px

.sponsor-image
  max-width: 100%

@include media-breakpoint-down(lg)
  .sponsor
    height: 180px

@include media-breakpoint-down(md)
  .sponsor
    height: 280px
</style>
