<template>
    <div>
        <div class="box introducee active">
            <div class="row">
                <div class="col-10">
                    <h3>{{ translate('introducee') }} {{ nr }}</h3>
                </div>
                <div class="col-2 text-end">
                    <i class="icon-arrow-up"></i>
                </div>
            </div>
        </div>

        <div v-if="fields.length" class="registration-form">
            <RegistrationForm :fields="fields" :isGuestForm="true"></RegistrationForm>

            <div class="row">
                <div class="col-12 text-end">
                  <AdvancedButton :loading="isLoading" class="btn btn-1" @clickEvent="postIntroducee()" :disabled="isLoading" data-cy="confirmation-guests">
                    <template v-slot:loading>
                      <span>{{ translate('register_confirm') }}</span>
                    </template>
                    <template v-slot:default>
                      <span>{{ translate('register_confirm') }}</span>
                    </template>
                  </AdvancedButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { AdvancedButton } from '@/components/Misc'
  import RegistrationForm from './../RegistrationForm'
  import { useVuelidate } from '@vuelidate/core'
  import { mapStores } from 'pinia'
  import { useAppRegistration } from '@/stores/app-registration'
  import { useAppRegistrationIntroducee } from '@/stores/app-registration-introducee'
  import { useApp } from '@/stores/app'
  import { useVisitor } from '@/stores/visitor'

  export default {
    components: {
      RegistrationForm,
      AdvancedButton
    },
    setup () {
      return {
        v$: useVuelidate()
      }
    },
    props: {
      purchase: Object,
      nr: Number
    },
    data () {
      return {
        isLoading: false
      }
    },
    computed: {
      ...mapStores(useApp, useAppRegistration, useAppRegistrationIntroducee, useVisitor),
      fields () {
        return this.appRegistrationIntroduceeStore.fields
      }
    },
    created () {
      this.getFields()
    },
    methods: {
      postIntroducee () {
        this.isLoading = true

        this.appRegistrationIntroduceeStore.postIntroducee(this.purchase).then((response) => {
          this.isLoading = false
          // delete purchase from store
          this.appRegistrationIntroduceeStore.removePurchase(this.purchase.hash).then(() => {
            if (this.appRegistrationIntroduceeStore.purchases.length === 0) {
              if (this.appStore.event.my_account === false) {
                // log out
                this.visitorStore.logout()
              }
              this.appRegistrationStore.setStatus('Success')
              this.$router.push('/registration/complete')
            } else {
              // update registration.introducees
              this.appRegistrationStore.setRegistration(response.data.registration)
              this.getFields()
            }
          })
        }).catch((e) => {
          this.isLoading = false
          if (e.response.data.type === 'validation') {
            this.appRegistrationIntroduceeStore.setValidationErrors(e.response.data.data)
            //TODO: Vue3 upgrade: is this necessary?
            this.v$.$reset()
          } else {
            window.handle_error(e, 'post introducee catch')
          }
        })
      },
      getFields () {
        this.appRegistrationIntroduceeStore.getFields()
      }
    }
  }
</script>


<style type="text/scss" lang="scss" scoped>
[class^="icon-"], [class*=" icon-"] {
  cursor: default;
  user-select: none;
  font-size: 2.0rem;
}
</style>
