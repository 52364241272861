import { createApp } from 'vue'
import { createRouter } from './router'
import { createPinia } from 'pinia'
import { ObserveVisibility } from 'vue-observe-visibility'
import * as Sentry from "@sentry/vue"
import renderMustache from 'lib/mustache'
import throttle from 'lodash/throttle'
import Vue3TouchEvents from 'vue3-touch-events'
import { Vue3Mq } from "vue3-mq"
import App from './App.vue'
import * as statusses from './stores/statusses'
import { env } from './env'
import PrimeVue from 'primevue/config'
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Textarea from 'primevue/textarea'
import Rating from 'primevue/rating'
import ProgressBar from 'primevue/progressbar'
import MultiSelect from 'primevue/multiselect'
import ProgressSpinner from 'primevue/progressspinner'
import RadioButton from 'primevue/radiobutton'
import globalComponents from './globalComponents'
import { useApp } from '@/stores/app'
import { useAppTranslations } from '@/stores/app-translations'
import { useAppTemplate } from '@/stores/app-template'
import { useAppUi } from '@/stores/app-ui'
import { AxiosError } from 'axios'

const pinia = createPinia()
const router = createRouter()

const app = createApp(App)

app.use(router)
app.use(pinia)

const appStore = useApp()
const translationsStore = useAppTranslations()
const templateStore = useAppTemplate()
const uiStore = useAppUi()

app.use(Vue3Mq, {
  breakpoints: { // default breakpoints - customize this
    xs: 0,
    mobile: 1023,
    desktop: 1024
  }
})
// intersection-observer
// https://github.com/w3c/IntersectionObserver/tree/master/polyfill
require('intersection-observer')

app.use(PrimeVue)
app.component('Dropdown', Dropdown)
app.component('FileUpload', FileUpload)
app.component('TextArea', Textarea)
app.component('Rating', Rating)
app.component('ProgressBar', ProgressBar)
app.component('MultiSelect', MultiSelect)
app.component('ProgressSpinner', ProgressSpinner)
app.component('RadioButton', RadioButton)

var USE_SENTRY = false
if (env.SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: env.SENTRY_DSN,
    integrations: [],
    environment: env.NODE_ENV_SENTRY
  })

  USE_SENTRY = true
}

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  updated: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});
app.use(Vue3TouchEvents)

require('./../node_modules/vue2-animate/dist/vue2-animate.min.css')

app.component('attachments-v1', globalComponents.AttachmentsV1)
.component('button-v1', globalComponents.ButtonV1)
.component('contacts-v1', globalComponents.ContactsV1)
.component('countdown-v1', globalComponents.CountdownV1)
.component('divider-v1', globalComponents.DividerV1)
.component('header-v1', globalComponents.HeaderV1)
.component('image-v1', globalComponents.ImageV1)
.component('venues-v1', globalComponents.VenuesV1)
.component('map-v1', globalComponents.MapV1)
.component('photo-v1', globalComponents.PhotoV1)
.component('plan-v1', globalComponents.PlanV1)
.component('program-v1', globalComponents.ProgramV1)
.component('qrcode-v1', globalComponents.QrcodeV1)
.component('speakers-v1', globalComponents.SpeakersV1)
.component('sponsors-v1', globalComponents.SponsorsV1)
.component('text-v1', globalComponents.TextV1)
.component('video-v1', globalComponents.VideoV1)
.component('html-v1', globalComponents.HtmlV1)
.component('shares-v1', globalComponents.SharesV1)
.component('textimage-v1', globalComponents.TextimageV1)
.component('lounge-program-v1', globalComponents.ProgramV1Lounge)
.component('lounge-participants-v1', globalComponents.ParticipantsV1)
.component('lounge-news-v1', globalComponents.NewsV1)
.component('lounge-shortcut-v1', globalComponents.ShortcutV1)
.component('lounge-welcome-v1', globalComponents.WelcomeV1)

// Reset focus (tabindex) to the body before each page load
router.beforeEach((to, from, next) => {
  document.body.setAttribute("tabindex", "-1");
  document.body.focus();
  document.body.removeAttribute("tabindex");
  next();
});

app.mixin({
  methods: {
    // TODO: remove this method and use the composable translations instead
    translate: (name, values) => {
      let trans = translationsStore.translations[name]
      // no translation found
      if (trans === undefined) {
        console.warn('Translation not found for: ' + name)
        Sentry.captureMessage('translation missing: ' + name, {
          level: 'warning',
          extra: values
        })

        return ''
      }

      // if template values
      if (values) {
        try {
          return renderMustache(trans, values)
        } catch (err) {
          console.error(err)
          Sentry.captureMessage('translation error: ' + name, {
            level: 'error',
            extra: values
          })

          return ''
        }
      } else {
        return trans
      }
    },
    // TODO: remove this method and use the composable translations instead
    translationExists: (name) => {
      let trans = translationsStore.translations[name]
      // no translation found
      if (trans === undefined) {
        return false
      } else {
        return true
      }
    },
    // TODO: remove this method and use the composable translations instead
    translateRegistrationText: (name) => {
      if (this.translationExists(name)) {
        return this.translate(name)
      } else {
        return appStore.event.name
      }
    },
    // TODO: remove this method and use the composable colors instead
    colors: (color) => {
      return templateStore.get('colors')[color]
    },
    // TODO: remove this method and use the composable home instead
    isHomepageClass: () => {
      if (window.location.pathname === '/') {
        return 'mm-is-homepage'
      } else {
        return 'mm-is-widgetpage'
      }
    },
    // TODO: remove this method and use the composable home instead
    isHomepage: () => {
      return window.location.pathname === '/'
    },
    // TODO: remove this method and use the router push instead
    navigateTo: (route) => {
      router.push(route)
    }
  }
})

app.directive('validation-class', require('./directives/validation-class').default)

/* eslint-disable */
app.config.errorHandler = function (err, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+

  window.handle_error(err)
}

// global error handler
window.handle_error = (err, where = 'undefined') => {
  const {response = {}} = err
  const {data = {}} = response

  switch (true) {
    // event_not_found
    case data.type === 'event_not_found':
      appStore.setStatus(statusses.EVENT_NOT_FOUND)
      break

    // event_offline
    case data.type === 'event_offline':
      appStore.setStatus(statusses.EVENT_OFFLINE)
      break

    // password_required
    case data.type === 'password_required':
      appStore.setStatus(statusses.PASSWORD_REQUIRED)
      break

    // default action if above is not applicable
    default:

      if (!USE_SENTRY) {
        console.log('Sentry.io logging not enabled, error: ', err)
        return
      }

      if(err instanceof AxiosError && err.status && err.status >= 500 && err.status < 600) {
        return
      }
      else if (err instanceof Error) {
        Sentry.captureException(err)
      } else if (err instanceof Object) {
        Sentry.captureMessage('Unhandled error object', {
          level: 'error',
          extra: err
        })
      } else {
        let error = {
          error: err
        }
        if (where !== 'undefined') {
          error.where = where
        }
        Sentry.captureMessage('Unhandled error', error)
      }
  }
}

let applyBreakpointClasses = throttle(function () {
  let width = window.innerWidth
  let breakpoints = {
    xs: {
      size: 1,
      min: 0,
      max: 575,
      className: 'xs'
    },
    sm: {
      size: 2,
      min: 576,
      max: 767,
      className: 'sm'
    },
    md: {
      size: 3,
      min: 768,
      max: 1279,
      className: 'md'
    },
    lg: {
      size: 4,
      min: 1280,
      max: 1299,
      className: 'lg'
    },
    xl: {
      size: 5,
      min: 1300,
      max: 900000,
      className: 'xl'
    }
  }
  switch (true) {
    case width <= breakpoints.xs.max:
      uiStore.setCurrentBreakingPoint(breakpoints.xs)
      break
    case width >= breakpoints.sm.min && width <= breakpoints.sm.max:
      uiStore.setCurrentBreakingPoint(breakpoints.sm)
      break
    case width >= breakpoints.md.min && width <= breakpoints.md.max:
      uiStore.setCurrentBreakingPoint(breakpoints.md)
      break
    case width >= breakpoints.lg.min && width <= breakpoints.lg.max:
      uiStore.setCurrentBreakingPoint(breakpoints.lg)
      break
    case width >= breakpoints.xl.min && width <= breakpoints.xl.max:
      uiStore.setCurrentBreakingPoint(breakpoints.xl)
      break
  }
}, 100)

window.addEventListener('resize', applyBreakpointClasses)
applyBreakpointClasses()

app.mount('#app')
