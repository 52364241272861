<template>
    <div class="widget-speakers">
        <widget-title :title="title"></widget-title>
        <div class="container">
            <SpeakerOverview :speakers="speakers"></SpeakerOverview>

            <widget-button :button="button"></widget-button>
        </div>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import SpeakerOverview from "../../../components/Speakers/SpeakerOverview"

  export default {
    name: 'speakers-v1',
    components: {
      SpeakerOverview
    },
    mixins: [
      widgetMixin
    ],
    computed: {
      pageId () {
        return this.widget.values.page_id
      },
      speakers () {
        return this.widget.values.speakers
      },
      title () {
        return this.widget.values.title
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
img
  margin-bottom: 21px
  cursor: pointer

h3
  font-weight: 600

.speaker
  margin-bottom: 20px
</style>
