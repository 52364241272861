<template>
  <div>
    <div class="page-photos mm-page" v-if="!details">
      <div class="container">

        <div class="row content mm-html mb-0 pb-0" v-if="page.content" v-html="page.content"></div>

        <div class="row search-row mb-3 mm-custom-form">
          <div class="col-lg-8 col-sm-6">
          </div>
          <div class="col-lg-4 col-sm-12 float-end">
            <label class="hide-from-viewport" for="filter">{{ translate('sort_by') }}</label>
            <select
              v-model="sort"
              class="form-select search-input form-control"
              :aria-label="translate('sort_by')"
              id="filter"
            >
              <option :value="null">{{ translate('sort_by') }}</option>
              <option value="oldnew">{{ translate('upload_date_oldnew') }}</option>
              <option value="newold">{{ translate('upload_date_newold') }}</option>
              <option value="name">{{ translate('name') }}</option>
            </select>
          </div>
        </div>

      </div>
      <PhotoOverview :sort="sort" :photos="navigationObjects" :page="page" v-if="!details" class="mm-photos-container"></PhotoOverview>
    </div>

    <div v-else-if="details" class="page-photo-detail" v-touch:swipe.left="swipeLeft"
         v-touch:swipe.right="swipeRight"
         @keyup.prevent.left="swipeLeft"
         @keyup.prevent.right="swipeRight">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-8 photo">
            <img
              :src="details.src"
              style="max-width:100%;display:block;"
              :alt="details.title"
            >
            <a :href="details.download" target="_blank" role="link" tabindex="0" class="btn btn-2">{{ translate('download') }}</a>
          </div>
          <div class="col-12 col-lg-8">
            <hr/>
          </div>
        </div>
        <detail-navigation v-if="page.photos.length > 1"
                           :indexRoute="{ name: 'page', params: { pageId: page.page_id }}"
                           :nextRoute="nextRoute"
                           :previousRoute="previousRoute">
        </detail-navigation>
      </div>
    </div>
  </div>
</template>

<script>
  import detailNavigationMixin from '../../mixins/detailNavigationMixin'
  import PhotoOverview from "../../components/Photos/PhotoOverview"

  export default {
    components: {
      PhotoOverview
    },
    props: [
      'page'
    ],
    mixins: [
      detailNavigationMixin
    ],
    data () {
      return {
        sort: null,
        singleRedirect: false
      }
    },
    computed: {
      navigationObjects () {
        return this.page.photos
      }
    },
    methods: {
      swipeLeft (e) {
        if (e && e.preventDefault) {
          e.preventDefault()
        }
        if (this.nextRoute) {
          this.$router.push(this.nextRoute)
        }
      },
      swipeRight (e) {
        if (e && e.preventDefault) {
          e.preventDefault()
        }
        if (this.previousRoute) {
          this.$router.push(this.previousRoute)
        }
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.search-input
  border-width: 0
  border-bottom-width: 1px
  height: calc(2.25rem + 4px)
  padding-left: 0px
  font-family: inherit
  font-weight: 300

.page-photo-detail
  .photo
    text-align: center
    width: 100%
    overflow-x: hidden
    white-space: nowrap
    position: relative

    div.actual-image
      width: 100%
      padding-bottom: 56.25%
      background-size: cover
      background-position: center center

    a
      margin-top: 30px
      margin-bottom: 10px
</style>
