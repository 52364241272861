<template>
    <div class="page-live mm-page"  :class="{ 'has-image': page.image, 'no-content-no-spacing': !page.content, 'has-timezone-recalculation': timezone_recalculation }">
        <div class="timezone-recalculation text-center" v-if="timezone_recalculation" v-html="this.text_timezone_recalculation"></div>
        <div class="image" v-if="page.image" :style="{ backgroundImage: 'url(\''+page.image+'\')', overflow:'hidden' }">
          <img :src="page.image" style="opacity:0;max-width:100%;" role="presentation" alt="">
        </div>
        <div class="container inner">
          <div class="row content mm-html" v-html="this.page.content"></div>
        </div>
        <div class="container inner stream-container">
          <StreamOverview :streams="streams"></StreamOverview>
        </div>
    </div>
</template>

<script>
  import StreamOverview from "../../components/Streams/StreamOverview"
  import { mapStores } from "pinia"
  import { useVisitor } from "@/stores/visitor"

  export default {
    components: {
      StreamOverview
    },
    props: [
      'page'
    ],
    computed: {
      ...mapStores(useVisitor),
      inited () {
        return this.visitorStore.inited
      },
      invite () {
        return this.visitorStore.invite
      },
      streams () {
        return this.page.streams
      },
      timezone_recalculation () {
        if(this.page.timezone_recalculation) {
          return {
            timezone_from: this.page.timezone_recalculation.timezone_from,
            timezone_to: this.page.timezone_recalculation.timezone_to
          }
        }
        return false;
      },
      text_timezone_recalculation () {
        let text_translated = this.translate('text_timezone_recalculation');
        return text_translated.replace('[timezone_from]',this.timezone_recalculation.timezone_from).replace('[timezone_to]',this.timezone_recalculation.timezone_to);
      }
    },
    created () {
      let hash = this.$route.params.param1

      if (this.inited && !hash) {
        return
      }
      this.visitorStore.loginByHash(hash).then((response) => {
        
      }).catch((e) => {
        
      })
    }
  }
</script>

<style lang="scss" type="text/scss">

    @media (max-width: 1279px) {

      html body .page-live .timezone-recalculation {
        top: 65px;
        height: auto;
        line-height: 21px;
        padding: 10px;
        font-size: 14px;
      }

      html body .page-live.has-timezone-recalculation {
        padding-top: 65px !important;
      }
    }

    .page-live {
      padding-bottom: 72px;

      &.has-image {
        padding-top: 0 !important;
      }

      .image {
        width: 100%;
        max-height: 550px;
        background-size: cover;
        background-position: center center;
      }

      .subpage {
        padding-bottom: 20px;

        h2 {
          text-align: left;
          font-weight: 600;
        }

        img {
          padding: 20px 0 30px 0;
        }
      }

      .timezone-recalculation {
        position: fixed;
        top: 90px;
        margin: 0 auto;
        height: 50px;
        line-height: 50px;
        width: 100%;
        z-index: 900;
        background: white;
        font-style: italic;
        border-bottom: 1px solid #eeeeee;
      }

      @media (max-width: 1279px) {

        .stream-container.container.inner {
          width: 100%;
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      @media (max-width: 1279px) {
        &.no-content-no-spacing {
          padding-top: 0px  !important;
          .image {
            margin-bottom: 0px !important;
          }
        }
      }

    }
</style>
