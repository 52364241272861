<template>
    <div class="lounge-widget-title">
        <h2 :style="'color: ' + color +';' + cStyle">{{ title }}
          <i v-if="icon && template.menu.icons === true" :class="icon"></i>
        </h2>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.lounge-widget-title
  height: 56px
  background-color: #FFFFFF
  border-radius: 4px 4px 0px 0px
  h2
    line-height: 56px
    margin-left: 24px
    text-align: left
    font-weight: 600
    font-size: 20px

    i
      position: absolute
      top: 16px
      margin-left: 18px
      color: inherit
</style>
<script>
  import { mapStores } from 'pinia'
  import { useAppTemplate } from '@/stores/app-template'

  export default {
    props: {
      title: String,
      color: String,
      icon: String,
      cStyle: String,
    },
    data () {
      return {}
    },
    computed: {
      ...mapStores(useAppTemplate),
      template () {
        return this.appTemplateStore.template
      }
    }
  }
</script>
