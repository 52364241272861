<template>
  <PageContainer :image="registrationPage.image">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div v-if="content" v-html="content" class="mm-html content row"></div>

        <Errors :errors="errors"></Errors>

        <CodeForm :label="codeOrEmailLabel" :buttonDescription="confirmDescription" @handleCode="postCode"></CodeForm>
      </div>
    </div>
  </PageContainer>
</template>

<script>
// TODO: move to the Composition API when:
// - we have Vue 3
// - we don't use the translate mixins
// - We don't use Vuex
import Errors from "@/partials/errors"
import PageContainer from '@/components/PageContainer'
import CodeForm from '@/components/Code/CodeForm'
import { mapStores } from "pinia"
import { useApp } from '@/stores/app'
import { useVisitor } from '@/stores/visitor'
import { useAppLanguages } from '@/stores/app-languages'
import { useAppPages } from '@/stores/app-pages'

export default {
  components: {
    Errors,
    PageContainer,
    CodeForm
  },
  data () {
    return {
      code: '',
      errors: {}
    }
  },
  mounted() {
    if (!this.event.registration_code) {
      this.$router.push({ name: 'home' })
    } else if (this.visitorInvite.hasOwnProperty('invite') && this.visitorInvite.invite) {
      this.$router.push({ name: 'page', params: { pageId: this.registrationPage.page_id }})
    }
  },
  computed: {
    ...mapStores(useApp, useVisitor, useAppLanguages, useAppPages),
    event () {
      return this.appStore.event
    },
    codeType() {
      return this.appStore.event.registration_code_type
    },
    content() {
      return this.translationExists('text_registration_code') ?
        this.translate('text_registration_code') :
        this.event.text_registration_code
    },
    codeOrEmailLabel() {
      return this.codeType === 'code' ? this.translate('form_code') : this.translate('field_type_email')
    },
    confirmDescription() {
      return this.codeType === 'code' ? this.translate('event_confirm_code') : this.translate('event_confirm_code_email')
    },
    twoFactorAuthKey() {
      return this.visitorStore.twoFactorAuthKey
    },
    visitorInvite() {
      return this.visitorStore.data
    },
    registrationPage() {
      return this.appPagesStore.getPage('register')
    }
  },
  methods: {
    postCode(code) {
      this.visitorStore.postCode(code).then(() => {
        if (this.twoFactorAuthKey) {
          this.$router.push({ name: 'two-fa' })
        } else {
          this.$router.push({ name: 'page', params: { pageId: this.registrationPage.page_id }})
        }
      }).catch((err) => {
        if (err.response.status === 429) {
          this.$router.push({ name: 'too-many-requests' })
        } else {
          this.errors = err.response.data
        }
      })
    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped>

</style>
