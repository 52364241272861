import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import SurveyService from '@/services/SurveyService'

export const useAppSurvey = defineStore('appSurvey', () => {
  const questionState = ref(null)
  const progressState = ref(0)
  const numberState = ref(null)
  const finishedState = ref(false)
  const finishTimeState = ref(null)
  const answerState = ref(null)
  const loadingState = ref(false)

  const question = computed(() => questionState.value)
  const progress = computed(() => progressState.value)
  const number = computed(() => numberState.value)
  const finished = computed(() => finishedState.value)
  const finishTime = computed(() => finishTimeState.value)
  const answer = computed(() => answerState.value)
  const loading = computed(() => loadingState.value)

  function handleResponse(data) {
    answerState.value = null
    
    if (data.question) {
      questionState.value = data.question
    }

    if (data.number) {
      numberState.value = data.number
    }

    if (data.progress) {
      progressState.value = data.progress
    }

    if (data.finished) {
      finishedState.value = data.finished
      finishTimeState.value = data.time
      progressState.value = 100
      questionState.value = null
    }
  }

  function init() {
    return new Promise((resolve, reject) => {
      SurveyService.init().then((data) => {
        if (data) { 
          handleResponse(data)
        } else {
          questionState.value = null
          progressState.value = 0
          finishedState.value = false
          finishTimeState.value = null
          numberState.value = null
        }

        resolve()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  function start() {
    return new Promise((resolve, reject) => {
      SurveyService.start().then((data) => {
        handleResponse(data)

        resolve()
      }).catch((err) => {
        reject(err)
      })

      resolve()
    })
  }

  function next() {
    questionState.value = {}
    loadingState.value = true

    return new Promise((resolve, reject) => {
      SurveyService.answer({ answer: answer.value }).then((data) => {
        handleResponse(data)

        loadingState.value = false

        resolve()
      }).catch((err) => {
        handleResponse(err.response.data)

        loadingState.value = false
        
        reject(err)
      })
    })
  }

  function previous() {
    questionState.value = {}
    loadingState.value = true

    return new Promise((resolve, reject) => {
      SurveyService.previous().then((data) => {
        handleResponse(data)

        loadingState.value = false

        resolve()
      }).catch((err) => {
        handleResponse(err.response.data)

        loadingState.value = false

        reject(err)
      })
    })
  }

  return { 
    questionState,
    progressState,
    numberState,
    finishedState,
    finishTimeState,
    answerState,
    loadingState,
    question,
    progress,
    number,
    finished,
    finishTime,
    answer,
    loading,
    init,
    start,
    next,
    previous
   }
})