<script setup>
  import { computed, reactive, onMounted } from 'vue'
  import {QRCode} from "../../../components/Misc";

  const props = defineProps({
    widget: Object,
  })

  const data = reactive({
    ready: false,
  })

  onMounted(() => {
    data.ready = true
  })

  const text = computed(() => {
    return props.widget.values.text
  })

</script>
<template>
  <div class="widget-qrcode">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center col-lg-2 col-md-3 qr-code">
          <QRCode
            :value="props.widget.values.url"
            :size="141"
            :margin="2"
          />
        </div>
        <div class="col-12 col-lg-6 col-md-9 align-self-center" v-if="text">
          <p class="h2 text-center text-md-start qr-text">{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
.widget-qrcode

  .qr
    background-color: transparent
    padding: 0
    border: 0

  .qr-text
    margin-bottom: 0

@include media-breakpoint-down(lg)
  .qr-text
    font-size: 1.5rem

@include media-breakpoint-down(md)
  .qr-text
    margin-top: 20px

  .qr-code
    max-width: 50%
</style>
