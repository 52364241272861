<template>
    <div v-html="widget.values.html" :ref="widgetIdRef" class="widget-html"></div>
</template>
<style lang="sass" type="text/sass" scoped>
.base-widget
  padding: 0
</style>
<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import loadScriptsMixin from "../../../mixins/loadScriptsMixin";

  export default {
    name: 'html-v1',
    mixins: [
      widgetMixin,
      loadScriptsMixin
    ],
    data () {
      return {
      }
    },
    mounted () {
      let scripts = Array.from(this.$refs[this.widgetIdRef].querySelectorAll('script'))
      this.loadScripts(scripts)
    },
    computed: {
      widgetIdRef() {
        return `widget-${this.widget.widgetId}`
      }
    }
  }
</script>
