<script setup>

const props = defineProps({
  validationErrors: {
    default: {},
    type: Object
  },
  fieldHandle: {
    default: '',
    type: String
  }
})

</script>

<template>
  <div class="error-messages" v-if="fieldHandle && validationErrors[fieldHandle]" role="alert">
    <p v-for="(errorMessage, index) in validationErrors[fieldHandle]" :key="index">
      {{ errorMessage }}
    </p>
  </div>
</template>

<style>

</style>