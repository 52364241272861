<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf, email } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: () => {},
    type: Object
  },
  validationErrors: {
    default: () => {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  emailValue: ''
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.emailValue = data.fieldData.value ?? ''
})

const rules = computed(() => ({
  emailValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
    email: email
  },
}))
const v$ = useVuelidate(rules, data)

watch(() => data.emailValue, () => {
  emit('setValue', {
    value: data.emailValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && v$.emailValue"
    class="mm-custom-form"
  >
    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.emailValue, field: data.fieldData, fieldValue: data.emailValue }" 
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />
      <div class="span-input">
        <input
          :id="data.fieldData.handle"
          v-model="data.emailValue"
          :style="{color: colors('7')}"
          :name="data.fieldData.handle"
          type="email"
          class="form-control"
          :disabled="data.fieldData.disabled"
          autocomplete="email"
          @blur="v$.emailValue.$touch"
        >
        <ValidationIcon 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.emailValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.emailValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>

<style>

</style>