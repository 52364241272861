<template>
    <div class="widget-photo">
        <widget-title :title="title"></widget-title>
        <PhotoOverview :photos="photos"></PhotoOverview>

        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import PhotoOverview from "../../../components/Photos/PhotoOverview"

  export default {
    name: 'photo-v1',
    components: {
      PhotoOverview
    },
    mixins: [widgetMixin],
    computed: {
      button () {
        return this.widget.values.button
      },
      title () {
        return this.widget.values.title
      },
      photos () {
        return this.widget.values.photos
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.photo
  background-color: #FFF
  height: 100%
  border: 1px solid #FFFFFF
  margin-bottom: 40px
</style>
