import { mapStores } from "pinia"
import { useAppMenu } from "@/stores/app-menu"

export default {
  computed: {
    ...mapStores(useAppMenu)
  },
  methods: {
    closeMenuKeyEscape(event) {
      if (event.key === 'Escape'){
        this.mouseLeaveMore()
      }
    },
    mouseEnterMore () {
      this.appMenuStore.setOpen(true)
    },
    mouseLeaveMore () {
      this.appMenuStore.setOpen(false)
    }
  },
  mounted() {
    window.addEventListener('keyup', this.closeMenuKeyEscape);
  },
  beforeUnmount () {
    window.removeEventListener('keyup', this.closeMenuKeyEscape);
  }
}
