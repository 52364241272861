<template>
    <div class="page-participants">
        <div class="container" v-if="!details">
            <div class="row search-row mm-custom-form">
                <div class="col-12 col-sm-6">
                    <label class="hide-from-viewport" for="fields">{{ translate('sort_by') }}</label>
                    <select
                      v-model="sort"
                      class="form-select search-input form-control"
                      :aria-label="translate('sort_by')"
                      id="fields"
                    >
                        <option :value="null">{{ translate('sort_by') }}</option>
                        <option value="firstname">{{ fieldDescription('firstname') }}</option>
                        <option value="lastname">{{ fieldDescription('lastname') }}</option>
                        <option
                          v-for="(option, key) in sortOptions.slice(0,2)"
                          :key="key"
                          :value="key"
                        >
                          {{ option.description }}
                        </option>
                    </select>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="search-container">
                        <input type="text" class="form-control search-input bg-transparent" v-model="searchQuery" :placeholder="translate('event_search')" />
                        <i 
                          class="icon-search" 
                          :aria-label="translate('event_search')"
                          :style="`color: ${colors(7)};`" 
                        />
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center overview">
                <div class="col-12 col-md-4 col-lg-3 participant" v-for="participant in searchedParticipants" :key="participant.id">
                    <div class="row g-0" data-cy="participant">
                        <div class="details col-8 order-1 order-md-2 col-md-12 text-start text-md-center">
                          <h3>
                            <router-link
                              :to="{ name: 'page', params: { pageId: page.page_id, param1: participant.id }}"
                              data-cy="participant-link"
                            >
                              {{ participant.name }}
                            </router-link>
                          </h3>
                          <p v-if="participant.fields[0] !== undefined">{{ participant.fields[0].value }}</p>
                          <p v-if="participant.fields[1] !== undefined">{{ participant.fields[1].value }}</p>
                        </div>
                        <div class="col-4 order-2 order-md-1 col-md-12">
                            <ParticipantImage
                              :initials="participant.initials"
                              :to="{ name: 'page', params: { pageId: page.page_id, param1: participant.id }}"
                              :circle="participant.circle"
                              :image="participant.image"
                              :resource="{ type: 'participant', details: participant.name }"
                            />
                        </div>
                        <div class="col-12 d-md-none order-3"><hr/></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="container">
                <div class="row justify-content-lg-center participant-detail" v-touch:swipe.left="swipeLeft"
                          v-touch:swipe.right="swipeRight"
                          @keyup.prevent.left="swipeLeft"
                          @keyup.prevent.right="swipeRight">
                    <div class="col-md-6 col-lg-4">
                        <ParticipantImage
                          :initials="details.initials"
                          :image="details.image"
                          :circle="details.circle"
                          :to="{}"
                          class="participant-image"
                          :resource="{ type: 'participant', details: details.name }"
                        />
                    </div>
                    <div class="col-md-6 col-lg-4 text-center text-md-start">
                        <h2 class="text-center text-md-start text-lg-start mm-detail-title">{{ details.name }}</h2>
                        <p class="text-center text-md-start text-lg-start" v-if="details.fields[0] !== undefined">{{ details.fields[0].value }}</p>
                        <p class="text-center text-md-start text-lg-start" v-if="details.fields[1] !== undefined">{{ details.fields[1].value }}</p>

                        <SocialMediaIcons 
                          :socialMediaLinks="details.icons"
                          :ownerName="details.name"
                        >
                        </SocialMediaIcons>

                        <p class="rest-fields" v-for="(field, index) in details.fields.slice(2)" :key="index">
                            <a v-if="field.type === 'link'" :href="field.value" target="_blank"><strong>{{ field.value }}</strong></a>
                            <span v-else>
                                {{ field.value }}
                            </span>
                        </p>

                        <a class="btn btn-2 vcard" :href="details.vcard" v-if="vcard">{{ translate('event_download_vcard') }}</a>
                    </div>
                </div>
                <div class="row justify-content-lg-center">
                    <div class="col-lg-8 mm-col-hr"><hr /></div>
                </div>

                <detail-navigation v-if="page.participants.length > 1"
                                   :indexRoute="{ name: 'page', params: { pageId: page.page_id }}"
                                   :nextRoute="nextRoute"
                                   :previousRoute="previousRoute">
                </detail-navigation>
            </div>
        </div>
    </div>
</template>

<script>
  import escapeRegExp from 'lodash/escapeRegExp'
  import detailNavigationMixin from '../../mixins/detailNavigationMixin'
  import ParticipantImage from "../../components/ImageOrInitials"
  import serviceOfOwnerMixin from "../../mixins/serviceOfOwnerMixin"
  import SocialMediaIcons from '../../components/SocialMedia/SocialMediaIcons'
  import { mapStores } from 'pinia'
  import { useApp } from '@/stores/app'

  export default {
    components: {
      ParticipantImage,
      SocialMediaIcons
    },
    props: [
      'page'
    ],
    mixins: [
      serviceOfOwnerMixin,
      detailNavigationMixin
    ],
    data () {
      return {
        sort: null,
        searchQuery: ''
      }
    },
    created () {
      this.sort = null
    },
    methods: {
      swipeLeft (e) {
        if (e && e.preventDefault) {
          e.preventDefault()
        }
        if (this.nextRoute) {
          this.$router.push(this.nextRoute)
        }
      },
      swipeRight (e) {
        if (e && e.preventDefault) {
          e.preventDefault()
        }
        if (this.previousRoute) {
          this.$router.push(this.previousRoute)
        }
      },
      fieldDescription (f) {
        let field = this.page.sortOptions.find(s => s.field === f)
        if (!field) {
          return this.translate(f)
        }
        return field.description
      }
    },
    computed: {
      ...mapStores(useApp),
      participants () {
        return this.page.participants
      },
      sortOptions () {
        return this.page.sortOptions.filter(o => o.field !== 'firstname' && o.field !== 'lastname')
      },
      navigationObjects () {
        return this.searchedParticipants
      },
      /**
       * Overwrite details from detailNavigationWidget
       * @returns {*}
       */
      details () {
        if (this.participants.length === 1) {
          return this.navigationObjects[0]
        }

        return this.navigationObjects.find(p => p.id === parseInt(this.$route.params.param1))
      },
      vcard () {
        return this.appStore.event.participants_vcard
      },
      saveSearchQuery () {
        return escapeRegExp(this.searchQuery)
      },
      searchedParticipants () {
        return this.participants.filter((el) => {
          if (this.saveSearchQuery !== '') {
            let re = new RegExp(this.saveSearchQuery, 'gi')
            if (el.name.match(re) !== null) {
              return true
            }
            if (el.fields[0] !== undefined && el.fields[0].value && el.fields[0].value.match(re) !== null) {
              return true
            }
            if (el.fields[1] !== undefined && el.fields[1].value && el.fields[1].value.match(re) !== null) {
              return true
            }

            return false
          }

          return true
        }).sort((a, b) => {
          if (this.sort === null) {
            return a.ts < b.ts ? -1 : 1
          }

          if (this.sort === 'firstname' || this.sort === 'lastname') {
            return a[this.sort].trim().toLowerCase() < b[this.sort].trim().toLowerCase() ? -1 : 1
          } else {
            if (a.fields[this.sort] !== undefined && b.fields[this.sort] !== undefined) {
              let va = (a.fields[this.sort].value + '').trim().toLowerCase()
              let vb = (b.fields[this.sort].value + '').trim().toLowerCase()
              if (!va) {
                return -1
              } else if (!vb) {
                return 1
              } else {
                return va < vb ? -1 : 1
              }
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.participant-image
  max-width: 320px !important
  margin: 0 auto !important
.search-row
  margin-bottom: 30px
  margin-top: -13px

  .search-input
    border-width: 0
    border-bottom-width: 1px
    height: calc(2.25rem + 4px)
    padding-left: 0px
    font-family: inherit
    font-weight: 300

  .search-container
    position: relative
    margin-bottom: 10px

    i
      position: absolute
      right: 0
      top: 50%
      margin-top: -8px
      font-size: 1.27rem

.participant
  display: inline-block
  margin-bottom: 40px

  .details
    margin-top: 20px

    p
      margin: 0
      line-height: 1.3rem
      word-break: break-word

    h3
      font-weight: 600
      margin-bottom: 5px
      line-height: 1.3rem
      word-break: break-word
      text-decoration: none

@include media-breakpoint-down(md)
  .overview
    .participant
      margin-bottom: 0

    .details
      margin-top: 0
      padding-right: 20px

      h3
        font-weight: 600
        display: inline-block
        cursor: pointer

h2
  font-weight: 600

i
  font-size: 2rem


.participant-detail
  @include media-breakpoint-down(md)
    h2
      margin-top: 30px

  .icons
    margin-top: 20px

  p
    margin: 0

  p.rest-fields
    padding-top: 5px

  a
    text-decoration: none
    color: inherit

  a.vcard
    margin-top: 30px
    color: inherit
</style>
