<template>
    <div class="page-sponsors">
        <div class="container">
            <div class="row content mm-html" v-if="page.content" v-html="page.content"></div>

            <div v-for="(subpage, indexSupage) in page.subpages" :key="indexSupage" :class="`mm-subpage-${indexSupage + 1}`">
                <div class="row content mm-subpage" v-if="subpage.type === 'content' && subpage.content" v-html="subpage.content"></div>

                <div class="row justify-content-md-center" v-for="(chunk, indexChunk) in chunk(subpage.sponsors)" :key="indexChunk">
                    <div class="col-12 col-md-6 sponsor-wrapper" v-for="(sponsor, indexSponsor) in chunk" :key="indexSponsor">
                        <div class="sponsor text-center text-md-start" :style="sponsorStyle">
                            <div class="image-container" v-if="sponsor.image">
                                <img :src="sponsor.image" class="img-fluid" :class="sponsor.size" :alt="sponsor.title" />
                            </div>
                            <div v-html="sponsor.content"></div>
                            <a :href="sponsor.url" v-if="sponsor.url" target="_blank">
                                {{ getRootDomainFromUrl(sponsor.url) }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import _chunk from 'lodash/chunk'
  import urlfuncs from '../../mixins/urlfuncs'
  import { mapStores } from "pinia"
  import { useAppTemplate } from "@/stores/app-template"

  export default {
    props: ['page'],
    mixins: [urlfuncs],
    data () {
      return {
      }
    },
    computed: {
      ...mapStores(useAppTemplate),
      sponsorStyle () {
        const colors = this.appTemplateStore.get('colors')
        return {
          border: `1px solid ${colors['5']}`
        }
      }
    },
    methods: {
      chunk (what) {
        return _chunk(what, 2)
      }
    }
  }
</script>

<style lang="sass" type="text/sass">
html .page-sponsors .row.content
  h1, h2
    padding-top: 20px
    padding-bottom: 50px

html .page-sponsors .mm-subpage-1
  h1, h2
    padding-top: 0px
    position: relative
    top: -10px
    padding-bottom: 40px

.page-sponsors
  flex: 1
  display: flex
  flex-direction: column
  padding-top: 40px

  .sponsor-wrapper
    margin-bottom: 40px

  .sponsor
    padding: 40px 30px
    height: 100%

    .image-container
      width: 100%
      height: 100px
      margin-bottom: 35px

      img
        max-height: 100px
      img.small
        padding: 20px
      img.medium
        padding: 10px
      img.big
        padding: 0px

    a
      font-weight: 600
      color: inherit
      word-break: break-all
</style>
