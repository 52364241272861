<template>
  <div>
    <div v-if="active && ticket.price.display">
      <div
        class="active-ticket-with-price"
        :style="{color: colors('7')}"
      >
        <div class="head d-none d-lg-flex">
          <div class="col-6 line justify-content-center d-flex align-items-end">
            {{ translate('ticket') }}
          </div>
          <div
            :class="{ 'col-sm-2': !event.ticketing_fee_included, 'col-sm-3': event.ticketing_fee_included }"
            class="line justify-content-center d-flex align-items-end"
          >
            {{ translate('payment_price') }}
          </div>
          <div
            :class="{ 'col-sm-2': !event.ticketing_fee_included, 'd-none': event.ticketing_fee_included }"
            class="col-sm-2 line justify-content-center d-flex align-items-end"
          >
            {{ translate('fee') }}
          </div>
          <div
            :class="{ 'col-sm-2': !event.ticketing_fee_included, 'col-sm-3': event.ticketing_fee_included }"
            class="col-sm-2 line justify-content-center d-flex align-items-end"
          >
            {{ translate('payment_total') }}
          </div>
        </div>
        <div class="d-flex active-ticket-content row-fluid">
          <div class="col-lg-6 col-12">
            <h3>{{ ticket.title }}</h3>
            <p v-if="ticket.description">
              {{ ticket.description }}
            </p>
          </div>
          <div
            class="d-none d-lg-inline line justify-content-center align-items-end align-text-top"
            :class="{ 'col-lg-2': !event.ticketing_fee_included, 'col-lg-3': event.ticketing_fee_included }"
          >
            <span>{{ ticketPrice('ticket') }}</span>
          </div>
          <div
            class="d-none d-lg-inline line justify-content-center align-items-end align-text-top"
            :class="{ 'col-lg-2': !event.ticketing_fee_included, 'd-lg-none': event.ticketing_fee_included }"
          >
            <span>{{ ticketPrice('fees') }}</span>
          </div>
          <div
            class="d-none d-lg-inline line justify-content-center align-items-end align-text-top"
            :class="{ 'col-lg-2': !event.ticketing_fee_included, 'col-lg-3': event.ticketing_fee_included }"
          >
            <span class="price-total">{{ ticketPrice('total') }}</span>
          </div>
        </div>
        <div class="d-flex head prices-head-mobile d-lg-none">
          <div
            :class="{ 'col-4': !event.ticketing_fee_included, 'col-sm-6': event.ticketing_fee_included }"
            class="line justify-content-center d-flex align-items-end"
          >
            {{ translate('payment_price') }}
          </div>
          <div
            :class="{ 'col-4': !event.ticketing_fee_included, 'd-none': event.ticketing_fee_included }"
            class="line justify-content-center d-flex align-items-end"
          >
            {{ translate('fee') }}
          </div>
          <div
            :class="{ 'col-4': !event.ticketing_fee_included, 'col-sm-6': event.ticketing_fee_included }"
            class="line justify-content-center d-flex align-items-end"
          >
            {{ translate('payment_total') }}
          </div>
        </div>
        <div class="d-flex d-lg-none prices-mobile">
          <div
            :class="{ 'col-4': !event.ticketing_fee_included, 'col-6': event.ticketing_fee_included }"
            class="line justify-content-center d-flex align-items-center"
          >
            <span>{{ ticketPrice('ticket') }}</span>
          </div>
          <div
            :class="{ 'col-4': !event.ticketing_fee_included, 'd-none': event.ticketing_fee_included }"
            class="line justify-content-center d-flex align-items-center"
          >
            <span>{{ ticketPrice('fees') }}</span>
          </div>
          <div
            :class="{ 'col-4': !event.ticketing_fee_included, 'col-6': event.ticketing_fee_included }"
            class="line justify-content-center d-flex align-items-center"
          >
            <span class="price-total">{{ ticketPrice('total') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visible && (!active || !ticket.price.display)"
      class="ticket"
    >
      <div class="row">
        <div class="col-12 col-md-7 ticket-info">
          <h3 :id="titleId">{{ ticket.title }}</h3><br>
          <p v-if="ticket.description">
            {{ ticket.description }}
          </p>
          <span
            v-if="ticket.price.display"
            class="price"
          >{{ price }} <small class="fw-normal">({{ feeText }})</small></span>
        </div>
        <div
          v-if="!active"
          class="col-12 col-md-5 text-md-end position-relative"
        >
          <p class="registration-counter">
            {{ registrationCounter }}
          </p>
          <AdvancedButton
            v-if="!ticket.isFull && !eventFull"
            :loading="isLoading(ticket)"
            :disabled="disabled"
            class="mm-btn-ticket btn btn-1"
            @clickEvent="reserveTicket(ticket)"
            :aria-labelledby="titleId"
          >
            <template #loading>{{ translate('choose_ticket') }}</template>
            <template #default>{{ translate('choose_ticket') }}</template>
          </AdvancedButton>
          <AdvancedButton
            v-if="(ticket.isFull || eventFull) && reserve"
            :loading="isLoading(ticket)"
            :disabled="disabled"
            class="mm-btn-ticket btn btn-2"
            @clickEvent="reserveTicket(ticket)"
            :aria-labelledby="titleId"
          >
            <template #loading>{{ translate('reserve_ticket') }}</template>
            <template #default>{{ translate('reserve_ticket') }}</template>
          </AdvancedButton>
        </div>
      </div>
    </div>

    <div
      v-if="purchase_is_reserve"
      class="alert alert-warning"
      v-html="text_registration_reserve_before"
    />
  </div>
</template>
<script>
  import { AdvancedButton } from '@/components/Misc'
  import { mapStores } from 'pinia'
  import { useApp } from '@/stores/app'
  import { useAppRegistration } from '@/stores/app-registration'
  import { useVisitor } from '@/stores/visitor'

  export default {
    components: {
      AdvancedButton
    },
    props: {
      ticket: Object,
      eventFull: Boolean,
      active: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        lastPressedButton: null
      }
    },
    computed: {
      ...mapStores(useApp, useAppRegistration, useVisitor),
      event () {
        return this.appStore.event
      },
      purchase () {
        return this.appRegistrationStore.purchase
      },
      chosenTicket () {
        return this.appRegistrationStore.ticket
      },
      visible () {
        return Object.keys(this.purchase).length === 0 || this.purchase.ticket_id === this.ticket.ticket_id
      },
      isActive () {
        return this.purchase !== null && this.purchase.ticket_id === this.ticket.ticket_id
      },
      reserve () {
        return this.appStore.event.reserve
      },
      purchase_is_reserve () {
        const reserve = this.visitorStore.data.reserve

        if (reserve === true || reserve === false) {
          return reserve
        }

        // old way, use als fallback
        return this.reserve && this.purchase.status === 'reservation' && this.ticket.isFull && !this.eventFull
      },
      price () {
        if (this.isActive) {
          return this.purchase.amount
        }
        return this.ticket.price.display
      },
      registrationCounter () {
        if (!this.ticket.show_remaining_places) {
          return ''
        }

        if (this.ticket.max === 0) {
          return ''
        }

        if (this.ticket.count >= this.ticket.max) {
          return this.ticket.max + '/' + this.ticket.max
        }
        return this.ticket.count + '/' + this.ticket.max
      },
      disabled () {
        return this.appRegistrationStore.reserveTicketPending
      },
      feeText () {
        if (this.event.options.ticket_show_vat === true) {
          return this.translate('payment_tax_included')
        } else {
          return this.translate('payment_tax_excluded')
        }
      },
      text_registration_reserve_before () {
        if (this.translationExists('text_registration_reserve_before')) {
          return this.translate('text_registration_reserve_before')
        } else {
          return this.event.text_registration_reserve_before
        }
      },
      titleId () {
        return 'ticket-' + this.ticket.ticket_id;
      }
    },
    methods: {
      isLoading (ticket) {
        if (this.disabled && this.lastPressedButton === ticket.ticket_id) {
          return true
        }

        return false
      },
      reserveTicket (ticket) {
        this.lastPressedButton = ticket.ticket_id
        const clientId = this.visitorStore.data.id

        let data = {
          ticket_id: this.ticket.ticket_id,
          client_id: clientId,
          check: `${this.ticket.ticket_id}_${this.event.event_id}`
        }

        this.appRegistrationStore.reserveTicket(data).catch(err => {
          // reserveren van ticket is niet goed gegaan
          if (err.response.data.type === 'ticket_reserve_error') {
            this.appStore.init()
          } else {
            window.handle_error(err, 'reserve ticket catch')
          }
        })
      },
      ticketPrice (which) {
        return this.ticket.price[which]
      }
    }
  }
</script>
<style lang="scss" type="text/scss" scoped>
  div.text-md-end {
    margin-top: 0px;
  }

    .ticket {
        border-style: solid;
        border-width: 1px;
        margin-bottom: 20px;
        padding: 20px;

        .ticket-info {
          min-height: 80px;
        }

        .mm-btn-ticket {
          position: absolute;
          right: 20px;
          bottom: 0px;
        }

        .registration-counter {
            margin-bottom: 1.5rem;
        }

        h3 {
            font-weight: 600;
            display: inline;
        }

        span.price {
            font-weight: 600;
        }

        @media (max-width: 768px) {
          .ticket-info {
            min-height: auto;
          }

          .mm-btn-ticket {
            position: initial;
          }
        }
    }

    .active-ticket-with-price {
        border-width: 1px;
        border-style: solid;
        margin-bottom: 20px;

        .line {
          text-align: center;
        }

        h3 {
            font-weight: 600;
            display: inline;
        }
        .head {
            div {
                font-weight: 600;
                text-transform: uppercase;
                font-size: 0.89rem;
                padding: 5px 0;
            }
        }

        .prices-head-mobile {
            border-top: 1px solid;
            border-color: inherit;
        }

        .prices-mobile {
            min-height: 3rem;
        }


        .active-ticket-content {
            div:first-child {
              padding: 15px;
            }
            div {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            p {
                margin: 0;
            }
        }

        .price-total {
            font-weight: 600;
        }

        div.line:not(:first-child) {
            border-left-width: 1px;
            border-left-style: solid;
        }
    }
</style>
