import http from 'src/http'

class RegistrationService {

  init () {
    return new Promise((resolve, reject) => {
      http.get('/registration/init').then(function (response) {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  reserveTicket (ticketId, check) {
    return new Promise((resolve, reject) => {
      http.post('/tickets/reserve', { ticket_id: ticketId, check: check }).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getFields () {
    return new Promise((resolve, reject) => {
      http.get('/registration/get_fields').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPaymentConfig () {
    return new Promise((resolve, reject) => {
      http.get('/payment/config').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  postRegistration (data) {
    return new Promise((resolve, reject) => {
      http.post('/registration/post', data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  cancelPurchaseReservation () {
    return new Promise((resolve, reject) => {
      http.post('/purchase/cancel').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPurchaseStatus () {
    return new Promise((resolve, reject) => {
      http.get('/purchase/poll').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new RegistrationService()
