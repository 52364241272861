import AttachmentsV1 from "./pages/widgets/attachments/attachments-v1"
import ButtonV1 from "./pages/widgets/button/button-v1"
import ContactsV1 from "./pages/widgets/contacts/contacts-v1"
import CountdownV1 from "./pages/widgets/countdown/countdown-v1"
import DividerV1 from "./pages/widgets/divider/divider-v1"
import HeaderV1 from "./pages/widgets/header/header-v1"
import ImageV1 from "./pages/widgets/image/image-v1"
import VenuesV1 from "./pages/widgets/venues/venues-v1"
import MapV1 from "./pages/widgets/map/map-v1"
import PhotoV1 from "./pages/widgets/photo/photo-v1"
import PlanV1 from "./pages/widgets/plan/plan-v1"
import ProgramV1 from "./pages/widgets/program/program-v1"
import QrcodeV1 from "./pages/widgets/qrcode/qrcode-v1"
import SpeakersV1 from "./pages/widgets/speakers/speakers-v1"
import SponsorsV1 from "./pages/widgets/sponsors/sponsors-v1"
import TextV1 from "./pages/widgets/text/text-v1"
import VideoV1 from "./pages/widgets/video/video-v1"
import HtmlV1 from "./pages/widgets/html/html-v1"
import SharesV1 from "./pages/widgets/shares/shares-v1"
import ProgramV1Lounge from './pages/loungeWidgets/program/program-v1'
import ParticipantsV1 from './pages/loungeWidgets/participants/participants-v1'
import NewsV1 from './pages/loungeWidgets/news/news-v1'
import ShortcutV1 from './pages/loungeWidgets/shortcut/shortcut-v1'
import WelcomeV1 from './pages/loungeWidgets/welcome/welcome-v1'
import TextimageV1 from './pages/widgets/textimage/textimage-v1'

export default {
  AttachmentsV1,
  ButtonV1,
  ContactsV1,
  CountdownV1,
  DividerV1,
  HeaderV1,
  ImageV1,
  VenuesV1,
  MapV1,
  PhotoV1,
  PlanV1,
  ProgramV1,
  QrcodeV1,
  SpeakersV1,
  SponsorsV1,
  TextV1,
  VideoV1,
  HtmlV1,
  SharesV1,
  ProgramV1Lounge,
  ParticipantsV1,
  NewsV1,
  ShortcutV1,
  WelcomeV1,
  TextimageV1
}