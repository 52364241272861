<script setup>
// TODO: Vue3 upgrade: check at the end of the upgrade if we still have a console warning
import { ref, computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: () => {},
    type: Object
  },
  validationErrors: {
    default: () => {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const countryValue = ref()

const data = reactive({
  fieldData: {}
})

onMounted(() => {
  data.fieldData = { ...props.field }
  if (data.fieldData.value) {
    countryValue.value = {
      code: data.fieldData.value,
      name: data.fieldData.countries[data.fieldData.value],
    }
  } else {
    countryValue.value = ''
  }
})

const rules = computed(() => ({
  countryValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, { countryValue })

const options = computed(() => {
  if (!data.fieldData.countries) {
    return []
  }

  return Object.keys(data.fieldData.countries).map((countryKey) => {
    return {
      code: countryKey,
      name: data.fieldData.countries[countryKey],
    }
  })
})

watch(countryValue, () => {
  emit('setValue', {
    value: countryValue.value.code ?? null,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && options && v$.countryValue"
    class="mm-custom-form"
  >
    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.countryValue, field: data.fieldData, fieldValue: countryValue }" 
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />
      <div class="span-input">
        <Dropdown 
          v-model="countryValue"
          :options="options"
          filter
          option-label="name"
          class="form-control"
          :aria-labelledby="data.fieldData.handle"
        >
          <template #value="slotProps">
            <span :style="{color: colors('7')}">
              {{ slotProps.value ? slotProps.value.name : '&nbsp;' }}
            </span>
          </template>
        </Dropdown>

        <ValidationIcon 
          :show-select-arrow="true"
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.countryValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.countryValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>

<style lang="sass" type="text/sass" scoped>

</style>

	