<template>
  <PageContainer>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div v-html="translate('2fa_description')" class="mm-html content row"></div>

        <CodeForm :label="translate('form_code')" :buttonDescription="translate('event_confirm_code')" @handleCode="postCode"></CodeForm>
      </div>
    </div>
  </PageContainer>
</template>

<script>
// TODO: move to the Composition API when:
// - we have Vue 3 
// - we don't use the translate mixins
// - We don't use Vuex
import PageContainer from '@/components/PageContainer'
import CodeForm from '@/components/Code/CodeForm'
import { mapStores } from "pinia"
import { useVisitor } from '@/stores/visitor'
import { useAppLanguages } from '@/stores/app-languages'
import { useAppPages } from '@/stores/app-pages'

export default {
  components: {
    PageContainer,
    CodeForm
  },
  data () {
    return {
      code: ''
    }
  },
  mounted() {
    if(!this.twoFactorAuthKey) {
      this.$router.push({ name: 'login' })
    }
  },
  beforeUnmount(){
    this.visitorStore.setTwoFactorAuthKey(null)
  },
  computed: {
    ...mapStores(useVisitor, useAppLanguages, useAppPages),
    twoFactorAuthKey() {
      return this.visitorStore.twoFactorAuthKey
    }
  },
  methods: {
    postCode(code) {
      this.visitorStore.twoFactorAuth(code).then(() => {
        const registrationPage = this.appPagesStore.getPage('register')

        this.$router.push({ name: 'page', params: { pageId: registrationPage.page_id }})
      }).catch(() => {
        this.$router.push({ name: 'two-fa-incorrect' })
      })
    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped>

</style>
