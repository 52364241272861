import http from 'src/http'

class SurveyService {
  init () {
    return new Promise((resolve, reject) => {
      http.get('/survey/init').then(function (response) {
        resolve(response.data)
      }).catch(function (err) {
        reject(err)
      })
    })
  }

  start () {
    return new Promise((resolve, reject) => {
      http.post('/survey/start', {
        'device': 'laptop'
      }).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  answer (data) {
    return new Promise((resolve, reject) => {
      http.post('/survey/answer', data).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  previous () {
    return new Promise((resolve, reject) => {
      http.get('/survey/previous').then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }
}

export default new SurveyService()
