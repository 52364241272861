import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useAppTopbar = defineStore('appTopbar', () => {
  const returnLinkState = ref('/')

  const returnLink = computed(() => returnLinkState.value)

  function setReturnLink(route) {
    returnLinkState.value = route
  }

  return { returnLinkState, returnLink, setReturnLink }
})