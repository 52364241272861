import http from 'src/http'

class PageService {
  getPageData (pageId) {
    return new Promise((resolve, reject) => {
      http.get('/page/' + pageId).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  getSitemap () {
    return new Promise((resolve, reject) => {
      http.get('/sitemap').then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  uploadPhoto(pageId, file) {
    const formData = new FormData()
    formData.append('file', file)
    return new Promise((resolve, reject) => {
      http.post(`/upload/photo?pageId=${pageId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default new PageService()
