<template>
  <div v-if="error && error.type !== '404_page_not_found'">
    <div class="container">
      <div class="col text-center">
        Something went wrong when loading this website.
        You can try again by clicking <a href="/">here</a>
      </div>
    </div>
  </div>
  <EventNotFound v-else-if="event_not_found" />
  <EventOffline v-else-if="event_offline" />
  <div
    v-else-if="ready"
    id="app"
    :class="appClass"
  >
    <SkipToContent />
    <Topbar />
    <TopLoadingIndicator />
    <AddToHomescreen
      v-if="status !== 'STATUS_PASSWORD_REQUIRED'"
      :favicon="favicon"
      :wait="300"
      :debug="false"
      storage-id="mm-add-to-homescreen"
    />
    <div v-if="error && error.type === '404_page_not_found'">
      <PageNotFound />
    </div>
    <div
      v-else
      id="router-view"
      :class="{ 'survey-footer-active': surveyFooterActive}"
    >
      <div v-if="password_required">
        <Password />
      </div>
      <div v-else>
        <router-view :key="routerKey" />
      </div>
    </div>
    <ScrollToTop />
    <FooterElement
      v-show="$route.name !== 'lounge'"
      :key="'footer'"
    />
  </div>
  <Loading v-else-if="event_loading" />
</template>

<script>
  import * as statusses from './stores/statusses'
  import { AddToHomescreen, TopLoadingIndicator, SkipToContent } from 'components/Misc'
  import Topbar from "./components/Topbar/Topbar"
  import { ScrollToTop, Loading, EventNotFound, EventOffline } from "./components"
  import FooterElement from "./components/Footer"
  import Password from "./pages/Password"
  import PageNotFound from "./components/Errors/404"
  import { useApp } from '@/stores/app'
  import { useAppCss } from '@/stores/app-css'
  import { useAppLanguages } from '@/stores/app-languages'
  import { useAppUi } from '@/stores/app-ui'
  import { useAppMenu } from '@/stores/app-menu'
  import { useAppTemplate } from '@/stores/app-template'
  import { useVisitor } from '@/stores/visitor'
  import { useAppTranslations } from '@/stores/app-translations'
  import { mapStores } from 'pinia'

  export default {
    name: 'App',
    components: {
      AddToHomescreen,
      TopLoadingIndicator,
      Topbar,
      ScrollToTop,
      EventOffline,
      FooterElement,
      Password,
      Loading,
      EventNotFound,
      PageNotFound,
      SkipToContent
    },
    computed: {
      ...mapStores(useApp, useAppCss, useAppLanguages, useAppUi, useAppMenu, useAppTemplate, useVisitor, useAppTranslations),
      fromTool () {
        let { tool = false } = this.$route.query
        if (tool && (tool === 'true' || tool === true)) {
          return true
        } else {
          return false
        }
      },
      shouldShowMenu () {
        let { menu = true } = this.$route.query
        if (menu && (menu === 'true' || menu === true)) {
          return true
        } else {
          return false
        }
      },
      published() {
        return this.appStore.published
      },
      ready() {
        return (this.status === statusses.APP_READY || this.password_required) && this.appCssStore.loaded && this.appTranslationsStore.ready && this.appTranslationsStore.translationsLoaded
      },
      status() {
        return this.appStore.status
      },
      event_loading () {
        return this.status === statusses.APP_LOADING
      },
      event_not_found () {
        return this.status === statusses.EVENT_NOT_FOUND
      },
      event_offline () {
        return this.status === statusses.EVENT_OFFLINE
      },
      password_required () {
        return this.status === statusses.PASSWORD_REQUIRED
      },
      languages() {
        return this.appLanguagesStore.all
      },
      activeLanguage() {
        return this.appLanguagesStore.active
      },
      surveyFooterActive () {
        return this.appUiStore.surveyFooter
      },
      error() {
        return this.appStore.error
      },
      isMenuOpen() {
        return this.appMenuStore.open
      },
      hasAppMenu() {
        return this.appTemplateStore.get('app.active')
      },
      favicon() {
        return this.appTemplateStore.get('favicon')
      },
      routerKey () {
        return `${this.$route.name}${this.$route.params.pageId}`
      },
      appClass () {
        return `app-${this.$route.name}-${this.$route.params.pageId}`
      },
      linkExpired() {
        return this.visitorStore.linkExpired
      },
    },
    watch: {
      '$route': function () {
        window.scrollTo(0, 0)
        this.appStore.set404(null)
        this.appMenuStore.setOpen(false)
      }
    },
    created () {
      this.appStore.init().then(() => {
        this.set_css()
        document.documentElement.setAttribute('lang', this.activeLanguage.language_data.iso) // set lang property to html
        if (this.published === false && this.fromTool === false) {
          window.alert(this.appStore.initialTranslations.not_yet_published)
          setInterval(() => {
            window.alert(this.translate('not_yet_published'))
          }, 30000)
        }
        if(this.linkExpired) {
          return this.$router.push({name: 'link-expired'})
        }

        if (this.$route.name === 'home' && window.innerWidth < 576 && !this.password_required) {
          // check for app-menu and show it
          if (this.hasAppMenu && this.shouldShowMenu) {
            return this.$router.push({name: 'menu'})
          }
        }

        // The payment, live-module and personal-program pages need to manage the query params by themselves
        // For that reason, we do not remove them
        if (this.$route.name !== 'payment' && this.$route.name !== 'live-module' && this.$route.name !== 'personal-program') {
          this.$router.replace(this.$route.path).catch(()=>{});
        }
      }).catch((err) => {
        window.handle_error(err, 'app init catch')
      })
    },
    updated () {
      // scroll to certain element on page load
      let { scrollto } = this.$route.query
      if (scrollto) {
        this.$nextTick(() => {
          setTimeout(() => {
            let topbar = window.document.getElementById('topbar')
            let topbarHeight = topbar ? topbar.scrollHeight : 0
            let offset = (scrollto === 'mm-footer') ? 0 : topbarHeight
            this.scrollToElement(scrollto, -offset)
          }, 50)
        })
      }
    },
    methods: {
      set_css () {
        this.appCssStore.setLoaded()
      },
      scrollToElement (hash, offset = 0) {
        window.location.href = `#${hash}`
        window.scrollBy(0, offset)
      }
    },
  }
</script>

<style lang="sass" type="text/sass">
@include media-breakpoint-down(lg)
  body
    padding-top: $topbar-height-small

@include media-breakpoint-up(lg)
  body
    padding-top: $topbar-height-large

div#router-view.survey-footer-active
  padding-bottom: $survey-footer-height

div#router-view.page-loading
  height: 200%
</style>
