<script setup>
  import { useVisitor } from '@/stores/visitor'
  import { useAppRegistration } from '@/stores/app-registration'
  import { useRouter } from 'vue-router'

  const router = useRouter()

  const visitorStore = useVisitor()
  const appRegistrationStore = useAppRegistration()

  visitorStore.logout().then(() => {
    appRegistrationStore.init().then(() => {
      router.push('/register')
    })
  }).catch((err) => {
    window.handle_error(err, 'not your details logout')
  })
</script>

<template>
  <div />
</template>