import TagManager from 'src/tracker/TagManager'
import DummyTracker from 'src/tracker/DummyTracker'

let trackers = [];

/**
 * Before developing/debugging please ensure that ALL your Ad-blockers, Ghostery, and Privacy tools are disabled.
 */
export default class Tracker {

    /**
     * Add new Analytics/Marketing trackers here
     */
    constructor() {
        if(trackers.length === 0) {
            let tagManager = new TagManager;
            let dummy = new DummyTracker;

            trackers.push(
                tagManager,
                dummy
            )
        }
    }

    /**
     * provide the data to every 'tracker'
     * @param data Object
     */
    run (data) {
        /* eslint-disable */
        let output = ''; // this is only for debugging, NOT(!) for displaying to the browser

        trackers.forEach(async function (tr) {
            try {
                tr.setData(data)
                output = tr.send()
            } catch (err) {
                //do nothing for now
            }
        })
    }

};
