import http from 'src/http'

class PageService {
  getPersonalProgram(personalProgramKey) {
    return new Promise((resolve, reject) => {
      http.get('/personal-program', {
        params: {
          ppk: personalProgramKey
        }
      }).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }
}

export default new PageService()
