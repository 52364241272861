<template>
  <div class="widget-shortcut">
    <div
      v-if="shortcuts_ready"
      class="lounge-widget-link-group"
    >
      <div
        v-for="(s, index) in shortcuts"
        :key="index"
        class="lounge-widget-link"
      >
        <router-link
          v-if="s.type === 'intern'"
          v-slot="{ navigate }"
          :to="{ name: 'page', params: { pageId: s.link }}"
          target="_self"
          custom
        >
          <div
            class="goto"
            @click="navigate"
            @keypress.enter="navigate"
          >
            <span class="pull-right goto-text">{{ s.title }}</span>
            <span class="pull-right goto-icon"><i class="icon-arrow-right" /></span>
          </div>
        </router-link>
        <div
          v-else
          class="goto"
          @click="goto(s.link)"
        >
          <span class="pull-right goto-text">{{ s.title }}</span>
          <span class="pull-right goto-icon"><i class="icon-arrow-right" /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import widgetMixin from '../../../mixins/loungeWidgetMixin'

  export default {
    name: 'LoungeShortcutV1',
    components: {
    },
    mixins: [widgetMixin],
    props: ['widget'],
    data () {
      return {
        shortcuts: [],
        shortcuts_ready: false
      }
    },
    computed: {
    },
    created () {
      if(this.widget.values.shortcuts !== null) {
        this.shortcuts = this.widget.values.shortcuts;
        this.shortcuts_ready = true;
       }
    },
    methods: {
      goto(url) {
        window.open(url, '_blank');
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.lounge-widget-link-group

  .lounge-widget-link
    height: 51px
    position: relative

    .goto
      text-align: left
      font-weight: 500
      font-size: 17px
      line-height: 51px
      cursor: pointer
      width: 100%
      padding-left: 24px
      padding-right: 46px
      overflow: hidden
      white-space: nowrap

      .goto-text
        max-width: 100%
        padding-right: 4px
        display: inline-block
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

      .goto-icon
        position: absolute
        top: 7px
        right: 28px
        i
          color: inherit

</style>
