import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import LoungeService from '@/services/LoungeService'

export const useAppLounge = defineStore('appLounge', () => {
  const loungeWidgetsState = ref([])
  const activeState = ref(false)
  const layoutState = ref(null)
  const isRegistrationState = ref(false)

  const widgets = computed(() => loungeWidgetsState.value)
  const active = computed(() => activeState.value)
  const layout = computed(() => layoutState.value)
  const isRegistration = computed(() => isRegistrationState.value)

  function load(data) {
    activeState.value = data.event.lounge.active
    layoutState.value = data.event.lounge.layout
    isRegistrationState.value = data.registration !== null
  }

  function setLoungeWidgets() {
    return new Promise((resolve, reject) => {
      LoungeService.getLoungeWidgets().then((data) => {
        loungeWidgetsState.value = data
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
    })
  }

  function checkIn() {
    return new Promise((resolve,reject) => {
      LoungeService.checkin().then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
    })
  }

  return { 
    loungeWidgetsState,
    activeState,
    layoutState,
    isRegistrationState,
    widgets,
    active,
    layout,
    isRegistration,
    load,
    setLoungeWidgets,
    checkIn
   }
})