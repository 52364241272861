<script setup>
import { computed } from 'vue'
import uuid from "uuid/v4"
import ProgramWidget from "../../components/Program/ProgramWidget"
import { useTranslations } from '@/composables/translations'

const { translate } = useTranslations()

const props = defineProps({
  page: {
    type: Object,
    default: () => { }
  }
})

const uniqueId = computed(() => uuid())

const program = computed(() => props.page.program)

const dayTitle = computed(() => props.page.day_title)

const timezoneRecalculation = computed(() => {
  if (props.page.timezone_recalculation) {
    return {
      timezone_from: props.page.timezone_recalculation.timezone_from,
      timezone_to: props.page.timezone_recalculation.timezone_to
    }
  }

  return false;
})

const textTimezoneRecalculation = computed(() => {
  let textTranslated = translate('text_timezone_recalculation');
  return textTranslated.replace('[timezone_from]', timezoneRecalculation.value.timezone_from).replace('[timezone_to]', timezoneRecalculation.value.timezone_to);
})

</script>

<template>
  <div 
    class="page-program" 
    :class="{ 'has-image': props.page.image,'has-content': props.page.content,'has-timezone-recalculation': timezoneRecalculation }"
  >
    <div 
      v-if="timezoneRecalculation" 
      class="timezone-recalculation text-center" 
      v-html="textTimezoneRecalculation"
    />
    <div 
      v-if="props.page.image" 
      class="image" 
      :style="{ backgroundImage: 'url(\''+props.page.image+'\')', overflow:'hidden' }"
    >
      <img 
        :src="props.page.image"
        style="opacity:0;max-width:100%;"
        role="presentation"
        alt=""
      >
    </div>
    <div 
      v-if="props.page.content"
      class="container" 
    >
      <div 
        class="row content mm-html"
        v-html="props.page.content"
      />
    </div>
    <ProgramWidget
      :widget="false"
      :unique-id="uniqueId"
      :scroll-to-active-date-on-start="true"
      :day-title="dayTitle"
      :program="program"
      :page-has-content="props.page.content !== ''"
      :page-has-image="props.page.image !== ''"
      :day-format="props.page.day_format"
      :show-month="props.page.show_month"
    />
  </div>
</template>

<style lang="scss" scoped>

@media (max-width: 1279px){
  html body .page-program:not(.has-content) {
      padding: 0 !important;
  }
  html body .page-program.has-image {
    padding: 0 !important;
  }

  .mm-page .timezone-recalculation {
    top: 65px;
    height: auto;
    line-height: 21px;
    padding: 10px;
    font-size: 14px;
  }

  html body .page-program.has-timezone-recalculation {
    padding-top: 65px !important;
  }
}
.page-program-v2 {
  position: relative;
  padding-left: 65px;
  padding-right: 65px;

  @media (max-width: 767px) {
    //the server is applying a padding: 50px 0 !important to the class page-flex applied automatically
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.program-container {
  max-width: 1290px !important;
  background-color: #00bb00 !important;
}

.timezone-recalculation {
  position: fixed;
  top: 90px;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  width: 100%;
  z-index: 900;
  background: white;
  font-style: italic;
  border-bottom: 1px solid #eeeeee;
}

.program {
  &__container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0;
  }

  &__menu {
    font-size: 24px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto 22px;

    a {
      display: inline-block;
      margin-right: 32px;
      font-weight: 700;
      text-transform: capitalize;

      @media (max-width: 600px) {
        margin-right: 16px;
        font-size: 16px;
      }

      &.active {
        text-decoration: underline;
      }
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }

  &__filter {
    margin-right: 32px;
    font-size: 12px;
    padding: 2px 8px 2px 0;
    border-bottom: 1px solid;
    margin-bottom: 20px;
    min-width: 200px;

    &.favorites {
      border: 1px solid;
      padding: 0;
      min-width: auto;

      a {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        padding: 2px 10px 2px 8px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      svg {
        margin-left: 3px;
      }
    }

    select {
      min-width: 100px;
      border: none;
      padding-left: 0;
    }
  }

  &__day-title {
    font-size: 18px;
  }
}
</style>
