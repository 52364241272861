<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: {},
    type: Object
  },
  validationErrors: {
    default: {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  selectValue: ''
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.selectValue = data.fieldData.value ?? ''
})

const rules = computed(() => ({
  selectValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, data)

watch(() => data.selectValue, () => {
  emit('setValue', {
    value: data.selectValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && v$.selectValue"
    class="mm-custom-form"
  >
    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.selectValue, field: data.fieldData, fieldValue: data.selectValue }" 
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />
      <div class="span-input">
        <select
          :id="data.fieldData.handle"
          v-model="data.selectValue"
          :style="{color: colors('7')}"
          :name="data.fieldData.handle"
          class="form-control"
          :disabled="data.fieldData.disabled"
          @blur="v$.selectValue.$touch"
        >
          <option />
          <option
            v-for="(option, index) in data.fieldData.type_config"
            :key="index"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <ValidationIcon
          :show-select-arrow="true" 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.selectValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.selectValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>
