import WidgetTitle from "../partials/widgetTitle"
import WidgetButton from "../partials/widgetButton"

export default {
  props: {
    widget: Object,
    css_id: String
  },
  components: {
    'widget-title': WidgetTitle,
    'widget-button': WidgetButton
  }
}
