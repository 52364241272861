<template>
    <div :class="{ 'has-image': page.image }">
      <ImageHeader :imageUrl="page.image"></ImageHeader>
        <div class="container" v-if="ready && !isReservering">
            <div v-if="info" class="text-center">{{ info }}</div>
            <div v-else>
                <component v-if="status !== 'open'" :is="'Status-' + status" :page="page"></component>

                <div v-if="status === 'open'" class="row justify-content-center">

                    <!-- Ticketkeuze Menu -->
                    <div v-if="!hasPurchase && tickets.length > 0" class="col-md-10 col-lg-8 col-12">
                        <div class="mm-html mm-choose-ticket" v-html="translate('choose_a_ticket')"></div>

                        <div class="alert alert-danger" v-if="reserveTicketError">
                            {{ translate('ticket_reservation_error') }}
                        </div>

                        <Ticket v-for="(ticket, index) in availableTickets" :ticket="ticket" :eventFull="eventFull" :key="index"></Ticket>

                        <span v-if="fullTickets.length > 0" class="message-tickets-full">
                            <hr/>
                            <strong v-html="text_registration_reserve_before"></strong>
                        </span>

                        <Ticket v-for="(ticket, index) in fullTickets" :ticket="ticket" :eventFull="eventFull" :key="index"></Ticket>
                    </div>

                    <div class="col-md-10 col-lg-8 col-12 register-signup" v-if="hasPurchase && fields.length > 0">

                        <div class="mm-html" v-html="page.content"></div>

                        <Deadline tag="div" class="text-center" v-if="showFirstDeadline" :expire="expire" :visible="showDeadline" :isFreeTicket="isFreeTicket"></Deadline>
                        <Deadline tag="div" class="text-center" v-if="showSecondDeadline" :expire="introduceePurchases[0].expire" :visible="showDeadline" :isFreeTicket="isFreeTicket"></Deadline>

                        <div v-if="introduceePurchases.length === 0">
                            <Ticket :ticket="activeTicket" :active="true" v-if="!activeTicket.autopick"></Ticket>

                            <NotYourDetails></NotYourDetails>

                            <RegistrationForm :fields="fields"></RegistrationForm>

                            <div class="register-buttons">
                                <AdvancedButton
                                  :loading="isButtonLoading('cancel')"
                                  class="btn btn-2 btn-register-cancel"
                                  @clickEvent="cancelTicket()"
                                  v-if="purchase.status === 'reservation'"
                                  :disabled="postRegistrationPending">
                                  {{ translate('cancel') }}
                                </AdvancedButton>
                                <AdvancedButton :loading="isButtonLoading('submit')" class="btn btn-3 float-end btn-register-confirm" @clickEvent="postRegistration()" :class="{'btn-register-edit': isRegistered}" :disabled="postRegistrationPending" data-cy="register-button">
                                  <template v-slot:loading>
                                    <span>{{ registerButtonText() }}</span>
                                  </template>
                                  <template v-slot:default>
                                    <span>{{ registerButtonText() }}</span>
                                  </template>
                                </AdvancedButton>
                            </div>
                        </div>
                        <Summarybox v-else-if="registration" :summary="registration.summary" :name="registration.fullname" :introduceeCount="summaryIntroduceeCount"></Summarybox>

                        <IntroduceeRegistration v-if="introduceePurchases.length > 0"></IntroduceeRegistration>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { AdvancedButton } from '@/components/Misc'
  import StatusClosed from './Registration/Status/Closed'
  import StatusMax from './Registration/Status/Max'
  import StatusCode from './Registration/Status/Code'
  import StatusTeamcode from './Registration/Status/Teamcode'
  import StatusSuccess from './Registration/Status/Success'
  import NotYourDetails from './Registration/NotYourDetails'
  import Ticket from './Registration/Ticket'
  import Deadline from './Registration/Ticket/Deadline'
  import RegistrationForm from './Registration/RegistrationForm'
  import IntroduceeRegistration from './Registration/IntroduceeRegistration'
  import Summarybox from './Registration/Summarybox'
  import ImageHeader from "../../components/ImageHeader.vue"
  import { useVuelidate } from '@vuelidate/core'
  import { mapStores } from 'pinia'
  import { useAppRegistration } from '@/stores/app-registration'
  import { useAppRegistrationIntroducee } from '@/stores/app-registration-introducee'
  import { useApp } from '@/stores/app'
  import { useAppTemplate } from '@/stores/app-template'
  import { useVisitor } from '@/stores/visitor'
  import { useAppPayment } from '@/stores/app-payment'

  export default {
    components: {
      ImageHeader,
      AdvancedButton,
      StatusClosed,
      StatusMax,
      StatusCode,
      StatusTeamcode,
      StatusSuccess,
      NotYourDetails,
      Ticket,
      Deadline,
      RegistrationForm,
      IntroduceeRegistration,
      Summarybox
    },
    setup () {
      return {
        v$: useVuelidate()
      }
    },
    props: ['page'],
    data () {
      return {
        autopick: false,
        ready: false,
        isReservering: false,
        readyForPayment: false,
        paymentConfigFields: {},
        info: '',
        postRegistrationPending: false,
        lastActiveButton: null,
        preferredTicketId: null,
        showPaymentProcessingDialog: false
      }
    },
    computed: {
      ...mapStores(useAppRegistration, useAppRegistrationIntroducee, useApp, useAppTemplate, useVisitor, useAppPayment),
      expire () {
        return this.appRegistrationStore.purchase.expires
      },
      ticketPrice () {
        if (this.activeTicket && this.activeTicket.price) {
          return this.activeTicket.price
        }
      },
      isFreeTicket () {
        if (this.ticketPrice && this.ticketPrice.real <= 0) {
          return true
        } else {
          return false
        }
      },
      showFirstDeadline () {
        return this.purchase.status === 'reservation' && this.introduceePurchases.length === 0
      },
      showSecondDeadline () {
        return this.introduceePurchases.length > 0
      },
      showDeadline () {
        if (this.ticketPrice && this.ticketPrice.real && this.ticketPrice.real !== 0) {
          return true
        } else {
          return false
        }
      },
      status () {
        return this.appRegistrationStore.status
      },
      registration () {
        return this.appRegistrationStore.registration
      },
      registrationFull () {
        return this.appRegistrationStore.isFull
      },
      isPreviouslyCanceled () {
        return false
      },
      hasPreviouslyExpired () {
        return this.appRegistrationStore.hasPreviouslyExpired
      },
      allTicketsFull () {
        return this.tickets.filter(p => p.isFull === false).length === 0
      },
      tickets () {
        return this.appRegistrationStore.tickets
      },
      purchase () {
        return this.appRegistrationStore.purchase
      },
      hasPurchase () {
        return Object.keys(this.purchase).length !== 0
      },
      fields () {
        return this.appRegistrationStore.fields
      },
      introduceePurchases () {
        return this.appRegistrationIntroduceeStore.purchases
      },
      summaryIntroduceeCount () {
        return this.introduceePurchases.length + this.registration.introducees.length
      },
      introduceeCount () {
        let introduceesField = this.fields.filter(field => field.type === 'introducees')
        if (introduceesField && introduceesField.length) {
          return introduceesField[0].value || 0
        }
        return 0
      },
      team () {
        return this.appRegistrationStore.team
      },
      event () {
        return this.appStore.event
      },
      eventFull () {
        return this.event.reserve && (this.registrationFull || this.allTicketsFull) && this.purchase.status !== 'active'
      },
      activeTicket () {
        return this.tickets.find(t => t.ticket_id === this.purchase.ticket_id)
      },
      availableTickets () {
        return this.tickets.filter(t => {
          return !t.isFull
        })
      },
      fullTickets () {
        return this.tickets.filter(t => {
          return t.isFull
        })
      },
      reserveTicketError () {
        return this.appRegistrationStore.reserveTicketError
      },
      fromTool () {
        let { tool = false } = this.$route.query
        if (tool && (tool === 'true' || tool === true)) {
          return true
        } else {
          return false
        }
      },
      isDelayedPayment () {
        const delayedField = this.fields.find(f => f.type === 'delaypayment')

        if (delayedField) {
          return this.appRegistrationStore.registrationValues[delayedField.handle] || false
        }

        return false;
      },
      isRegistered () {
        return (this.registration && this.registration.fullname)
      },
      text_registration_reserve_before () {
        if (this.translationExists('text_registration_reserve_before')) {
          return this.translate('text_registration_reserve_before')
        } else {
          return this.event.text_registration_reserve_before
        }
      },
      isHomeActive () {
        return this.appTemplateStore.home
      }
    },
    created () {
      // Check preferred ticket
      if(this.$route.params.param1 && this.$route.params.param1.substring(0,7) == 'ticket_') {
        this.preferredTicketId = parseInt(this.$route.params.param1.substring(7));
        this.$router.replace({
          name: 'page',
          params: {
            pageId: this.$route.params.pageId
          }
        })
      }
    },
    mounted () {
      this.readyForPayment = false
      if (!this.fromTool) { // ME-2487 do not add listener if the page is loaded inside the tool
        window.addEventListener('beforeunload', this.handleBeforeUnload)
      }
      // register init?
      new Promise((resolve) => {
        if (this.$route.params.param1) {
          // post the hash
          this.visitorStore.postInviteHash(this.$route.params.param1).then(() => {
            this.$router.replace({
              name: 'page',
              params: {
                pageId: this.$route.params.pageId
              }
            })
            resolve()
          }).catch((err) => {
            if (err.response.data === 'teammanager_team_full') {
              this.info = this.translate('teammanager_team_full')
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      }).then(() => {
        this.appRegistrationStore.init().then(() => {
          if (this.status === 'code') {
            this.$router.push({ name: 'login' })
          } else {
            this.checkAutopick()
            this.ready = true
          }
        })
      }).catch((err) => {
        window.handle_error(err, 'register mounted')
      })
    },
    beforeUnmount() {
      if (!this.fromTool) { // ME-2487
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
      }
    },
    methods: {
      handleBeforeUnload(event) {
        event.preventDefault()
        event.returnValue = ''
      },
      isButtonLoading (type) {
        if (this.postRegistrationPending && this.lastActiveButton === type) {
          return true
        }
        return false
      },
      scrollToFirstError () {
        this.$nextTick(() => {
          const errorElement = document.querySelector('.has-error');

          if (errorElement) {
            errorElement.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            })
          }
        })
      },
      registerButtonText () {
        let { price = {} } = this.activeTicket
        if (this.introduceeCount >= 1 && this.event.introducee_type !== 1) {
          return this.translate('next')
        }
        if (this.registration && this.registration.fullname) {
          return this.translate('edit')
        } else if (price && price.real && !this.isDelayedPayment) {
          return this.translate('go_to_payment')
        } else {
          return this.translate('event_register_button')
        }
      },
      postRegistration () {
        this.lastActiveButton = 'submit'
        this.postRegistrationPending = true

        this.appRegistrationStore.postRegistration().then((response) => {
          let { price = {} } = this.activeTicket
          // paid ticket without introducees
          if (!this.isDelayedPayment && price && price.real && !response.data.introducees && this.purchase.status !== 'active') {
            this.appPaymentStore.setSessionData().then(() => {
              this.$router.push({ name: 'payment' })
            })

            return false
          } else if (response.data.introducees) {
            this.visitorStore.init()
            this.appRegistrationStore.setPurchase(response.data.purchase)
            this.appRegistrationStore.setRegistration(response.data.registration)
            this.appRegistrationIntroduceeStore.setPurchases(response.data.introducees)
          } else {
            this.visitorStore.init()
            this.appRegistrationStore.setStatus('Success')
            if (this.event.my_account === false) {
              this.visitorStore.logout()
            }
            this.$router.push('/registration/complete')
          }
          this.postRegistrationPending = false
        }).catch((e) => {
          console.error(e)
          this.postRegistrationPending = false

          if (e.response.data.type === 'validation') {
            this.appRegistrationStore.setValidationErrors(e.response.data.data)
            this.v$.$reset()
            this.scrollToFirstError()
          } else if (e.response.data.type === 'registration_failed') {
            window.alert(this.translate('registration_failed'))
          } else {
            window.handle_error(e, 'post registration catch')
          }
        })
      },
      cancelTicket () {
        let returnToHomepage = this.activeTicket.autopick

        this.lastActiveButton = 'cancel'
        this.postRegistrationPending = true
        this.appRegistrationStore.cancelPurchaseReservation().then(() => {
          this.postRegistrationPending = false
          if (returnToHomepage && this.isHomeActive) {
            this.$router.replace({name: 'home'})
          }
        }).catch((err) => {
          window.handle_error(err)
        })
      },
      checkAutopick () {
        if (!this.showPaymentProcessingDialog
          && this.purchase
          && this.purchase.hasOwnProperty('paymentProcessing')
          && this.purchase.paymentProcessing)
        {
          this.showPaymentProcessingDialog = true
          alert(this.translate('eventsite_payment_still_processing_description'))
          this.$router.push({ name: 'home' })
        }

        if (!this.fromTool && this.tickets && this.tickets.length && this.tickets[0].autopick && !this.hasPurchase && !this.isReservering) {
          if (!this.hasPreviouslyExpired) {
            this.reserveTicket(this.tickets[0])
          }
        }
        // Check if we need to autoPick preferred ticket_id
        if (!this.fromTool && this.tickets && this.tickets.length && this.preferredTicketId && !this.hasPurchase && !this.isReservering) {
          if (!this.hasPreviouslyExpired) {
            this.reserveTicket(this.tickets.find(t => t.ticket_id === this.preferredTicketId))
            this.preferredTicketId = null;
          }
        }
      },
      reserveTicket (ticket) {
        this.isReservering = true
        const clientId = this.visitorStore.data.id

        let data = {
          ticket_id: ticket.ticket_id,
          client_id: clientId,
          check: `${ticket.ticket_id}_${this.event.event_id}`
        }

        this.appRegistrationStore.reserveTicket(data).then(() => {
          this.isReservering = false
        }).catch(err => {
          this.isReservering = false
          // reserveren van ticket is niet goed gegaan
          if (err.response.data.type === 'ticket_reserve_error') {
            this.appStore.init()
          } else {
            window.handle_error(err, 'reserve ticket catch')
          }
        })
      }
    },
    watch: {
      purchase: function (val) {
        this.checkAutopick()
        this.appRegistrationStore.setValidationErrors({})
      },
      activeTicket: function () {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.registration-form
  margin-bottom: 65px

.mm-choose-ticket
  padding-bottom: 27px

.mm-page.has-image
  padding-top: 0 !important

@media (max-width: 360px)
  .register-buttons
    display: flex
    flex-direction: column

    button
      margin-top: 12px

    .btn-register-cancel
      order: 2

    .btn-register-confirm
      order: 1

</style>

