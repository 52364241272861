<script setup>

const props = defineProps({
  required: {
    default: false,
    type: Boolean
  },
  forTag: {
    default: '',
    type: String
  },
  text: {
    default: '',
    type: String
  }
})

</script>

<template>
  <label :for="forTag" :style="{color: colors('7')}">
    {{ text }} <span v-if="required">*</span>
  </label>
</template>

<style>

</style>