<template>
    <div class="widget-button">
        <div class="container">
            <div class="row content mm-html" v-if="text" v-html="text"></div>

            <widget-button :button="button" :margin="text !== null"></widget-button>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.widget-button
  a
    display: inline-block
</style>
<script>
  import widgetMixin from '../../../mixins/widgetMixin'

  export default {
    name: 'button-v1',
    mixins: [widgetMixin],
    data () {
      return {
      }
    },
    computed: {
      button () {
        return this.widget.values.button
      },
      text () {
        if (this.widget.values.text === '') {
          return null
        }
        return this.widget.values.text
      }
    }
  }
</script>
