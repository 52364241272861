<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: () => {},
    type: Object
  },
  validationErrors: {
    default: () => {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  paymentValue: false
})

onMounted(() => {
  data.fieldData = { ...props.field }
  data.paymentValue = data.fieldData.value ?? false
})

const rules = computed(() => ({
  paymentValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, data)

watch(() => data.paymentValue, () => {
  emit('setValue', {
    value: data.paymentValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div
    v-if="data.fieldData && v$.paymentValue"
    class="mm-custom-form"
  >
    <div 
      v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.paymentValue, field: data.fieldData, fieldValue: data.paymentValue }" 
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description || 'Betaaltype'"
      />
      <div
        v-if="field.disabled"
        class="span-input"
      >
        <input
          disabled
          :style="{color: colors('7')}"
          type="text" 
          class="form-control"
          :value="data.paymentValue ? translate('option_delayed_payment') : translate('option_direct_payment')" 
        >
        <ValidationIcon 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.paymentValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.paymentValue"
        />
      </div>
      <div
        v-else
        class="span-input"
      >
        <select 
          :id="data.fieldData.handle"
          v-model="data.paymentValue"
          :style="{color: colors('7')}"
          :name="data.fieldData.handle"
          class="form-control"
          @blur="v$.selectValue.$touch"
        >
          <option :value="true">
            {{ translate('option_delayed_payment') }}
          </option>
          <option :value="false">
            {{ translate('option_direct_payment') }}
          </option>
        </select>
        <ValidationIcon
          :show-select-arrow="true" 
          :field-handle="data.fieldData.handle"
          :response-errors="validationErrors"
          :vuelidate-field="v$.paymentValue"
          :field-disabled="data.fieldData.disabled"
          :value="data.paymentValue"
        />
      </div>
      <ValidationErrors
        :validation-errors="validationErrors"
        :field-handle="data.fieldData.handle"
      />
    </div>
  </div>
</template>
