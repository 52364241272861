<template>
  <component
    :is="pageComponent"
    v-if="page && ready"
    :id="css_id"
    :class="{ 'pt-0': noPaddingTop }"
    :page="page"
    class="page-flex mm-page"
  />
</template>
<script>
    import Account from './types/Account.vue'
    import Attachments from './types/Attachments.vue'
    import Contacts from './types/Contacts.vue'
    import Embed from './types/Embed.vue'
    import Extra from './types/Extra.vue'
    import General from './types/General.vue'
    import Participants from './types/Participants.vue'
    import Photo from './types/Photo.vue'
    import Plan from './types/Plan.vue'
    import Program from './types/Program.vue'
    import Register from './types/Register.vue'
    import Shortcut from './types/Shortcut.vue'
    import Speakers from './types/Speakers.vue'
    import Sponsors from './types/Sponsors.vue'
    import Survey from './types/Survey.vue'
    import Venues from './types/Venues.vue'
    import Video from './types/Video'
    import Html from './types/Html'
    import Shares from './types/Shares'
    import Live from './types/Live'
    import Podcast from './types/Podcast'
    import News from './types/News'
    import LogoutForNewRegistration from './LogoutForNewRegistration'
    import RegistrationComplete from './RegistrationComplete.vue'
    import Tracker from 'src/tracker/tracker'
    import { mapStores } from 'pinia'
    import { useApp } from '@/stores/app'
    import { useAppPages } from '@/stores/app-pages'
    import { useAppLanguages } from '@/stores/app-languages'
    import { useAppRegistrationIntroducee } from '@/stores/app-registration-introducee'
    import { useAppTranslations } from '@/stores/app-translations'

    export default {
      name: 'Page',
      data() {
        return {
          pagesWithNoPaddingTop: [
            'shortcut'
          ]
        }
      },
      computed: {
        ...mapStores(useApp, useAppPages, useAppLanguages, useAppRegistrationIntroducee, useAppTranslations),
        page () {
          return this.appPagesStore.current
        },
        ready () {
          return this.appPagesStore.ready
        },
        pageComponent () {
          return this.page.type + 'Component'
        },
        css_id () {
          return 'page-' + this.page.page_id
        },
        activeLanguage: function () {
          return this.appLanguagesStore.active
        },
        noPaddingTop() {
          return this.pagesWithNoPaddingTop.includes(this.page.type)
        },
        getTypePageTitle() {
          let title = (this.page) ? this.page.title : ''

          // TODO: refactor:
          // - In the current situation is not possible to access a resource dynamically because
          // the name of the resource change depending of the resource
          // - There is also inconsistencies in the type naming in the current solution, for example: the type is
          // 'video' but the property to get them is 'videos'
          // - The components pages are showing the overview or the detail in the same component depending of a computed
          // property, for that reason is difficult to set a value in the store with the current resource
          if (this.page && this.$route.params.param1) {
            const resourceTypesSingular = ['video', 'podcast', 'photo']

            // We add an 's' if the resource is in resourceTypesSingular
            const resourceType = resourceTypesSingular.includes(this.page.type) ?
              `${this.page.type}s` :
              this.page.type

            if (!Array.isArray(this.page[resourceType])) {
              return title
            }

            // We get the current resource
            const resource = this.page[resourceType]
              .find(resource => resource.id === parseInt(this.$route.params.param1))

            // The resource has the property name or title
            const resourceName = typeof resource !== 'undefined' ? (resource.name ? '| ' + resource.name : (resource.title ? '| ' + resource.title : '')) : ''

            title = `${this.page.title} ${resourceName}`
          }

          return title
        },
        pageTitle() {
          return this.appLanguagesStore.pageTitle(this.getTypePageTitle)
        }
      },
      components: {
        'accountComponent': Account,
        'attachmentsComponent': Attachments,
        'contactsComponent': Contacts,
        'embedComponent': Embed,
        'extraComponent': Extra,
        'generalComponent': General,
        'participantsComponent': Participants,
        'photoComponent': Photo,
        'planComponent': Plan,
        'programComponent': Program,
        'registerComponent': Register,
        'shortcutComponent': Shortcut,
        'speakersComponent': Speakers,
        'sponsorsComponent': Sponsors,
        'surveyComponent': Survey,
        'venuesComponent': Venues,
        'videoComponent': Video,
        'htmlComponent': Html,
        'sharesComponent': Shares,
        'liveComponent': Live,
        'podcastComponent': Podcast,
        'newsComponent': News,
        LogoutForNewRegistration,
        RegistrationComplete
      },
      methods: {
        canNavigateAway () {
          if (this.page.type === 'register') {
            if (this.appRegistrationIntroduceeStore.purchases.length > 0) {
              const trans = this.appTranslationsStore.translations['cancel_registration_with_guest_registration']
              const answer = window.confirm(trans)
              if (!answer) {
                return false
              } else {
                this.appRegistrationIntroduceeStore.clearReservations().then((response) => {
                  return true
                }).catch((e) => {
                  window.handle_error(e, 'introducee clear reservations')
                })
              }
            }
          }
          return true
        }
      },
      created () {
        this.appPagesStore.setReady(false)
        this.appPagesStore.setCurrentPage(this.$route).then((result) => {
          document.title = this.pageTitle
          this.appPagesStore.setReady(true)

          let tracker = new Tracker;
          tracker.run({
            'page': {
              'route' : this.$route,
              'title' : this.page.title,
              'type'  : this.page.type
            }
          })

        }, (err) => {
          window.handle_error(err, 'page init')
        }).catch(() => {
          this.appStore.set404({
            route: this.$route
          })
        })
      },
      beforeRouteLeave (to, from, next) {
        if (this !== undefined && this.canNavigateAway()) {
          next()
        } else {
          next(false)
        }
      },
      beforeRouteUpdate (to, from, next) {
        if (this !== undefined && this.canNavigateAway()) {
          next()
        } else {
          next(false)
        }
      },
      watch: {
        pageTitle() {
          document.title = this.pageTitle
        }
      }
    }
</script>

<style scoped type="text/sass" lang="sass">
.page-flex
  padding: 100px 0

@include media-breakpoint-only(xs)
  .page-flex
    padding: 20px 0
</style>