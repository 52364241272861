import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import TranslationService from '../services/TranslationService'

export const useAppTranslations = defineStore('appTranslations', () => {
  const translationsState = ref({})
  const translationsLoadedState = ref(false)

  const translations = computed(() => translationsState.value)
  const get = computed(() => (key) => translationsState[key] || null)
  const ready = computed(() => Object.keys(translationsState.value).length > 0)
  const translationsLoaded = computed(() => translationsLoadedState.value)

  async function load() {
    translationsState.value = await TranslationService.getTranslations()
    translationsLoadedState.value = true

    return;
  }

  return { translationsState, translationsLoadedState, translations, get, ready, translationsLoaded, load }
})