<template>
  <div>
    <div v-if="liveModuleActive" class="row item item-live-module">
      <div class="col-3 col-md-2 item-time position-relative" :class="{ highlight: (item.highlight)}">
        <span class="mrb-0">
          <strong>{{ item.time_start }}</strong>
        </span>
        <span class="fw-300">
          {{ item.time_end }}
        </span>
        <i class="icon-star-colored" :class="bookmarkStatus" :title="translate('lounge_item_bookmark_tooltip')" @click="bookmark(item.page_id)"></i>
      </div>

      <div class="col-9 col-md-7 item-content">
        <p class="title mrb-0">{{ item.title }}</p>
        <p class="location" v-if="item.location">{{ item.location }}</p>
        <p class="read-more" @click="toggleVisibility()" @keypress.enter="toggleVisibility()" tabindex="0" v-if="item.image || item.content">
          <span v-if="!this.visible">{{ translate('more_information') }}</span>
          <span v-if="this.visible">{{ translate('less_information') }}</span>
        </p>
        <div class="row read-more-info" v-if="this.visible && breakpoint.size >= 3">
          <div :class="{ 'col-md-6': item.image, 'col-12': !item.image }" class="content col-12 mm-html"
               v-if="item.content" v-html="item.content"></div>
          <div :class="{ 'col-md-6': item.content, 'col-12': !item.content }" class="col-12 read-more-img-desktop"
               v-if="item.image">
            <img :src="item.image" class="img-fluid" role="presentation" alt=""/>
          </div>
        </div>
        <div class="d-none d-md-block col-12 item-live item-status-col">
          <div class="item-status">
            <div class="item-status-indicator-col">
              <div :title="this.status" :class="indicator" >
                <span>{{ this.status_translation }}</span>
              </div>
            </div>
            <div class="item-access">
              <div :class="this.item_access_icon" class="item-access-icon"></div>
              <span>{{ this.public_status }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-md-block col-md-3 offset-md-0 offset-4 col-8 item-status-col">
        <AdvancedButton :loading="false" :disabled="disabled" v-if="this.canJoin()" class="mm-btn-stream btn btn-1" :style="`font-size: 14px`" @clickEvent="join()">
          <template v-slot:loading>
            <span>{{ 'loading' }}</span>
          </template>
          <template v-slot:default>
            <span>{{ button_cta }}</span>
          </template>
        </AdvancedButton>
      </div>
    </div>

    <div v-else class="row item">
      <div class="col-3 col-md-2 item-time position-relative" :class="{ highlight: (item.highlight)}">
        <span class="mrb-0">
          <strong>{{ item.time_start }}</strong>
        </span>
        <span class="fw-300">
          {{ item.time_end }}
        </span>
        <i class="icon-star-colored" :class="bookmarkStatus" :title="translate('lounge_item_bookmark_tooltip')" @click="bookmark(item.page_id)"></i>
      </div>

      <div class="col-9 col-md-10 item-content">
        <p class="title mrb-0">{{ item.title }}</p>
        <p class="location" v-if="item.location">{{ item.location }}</p>
        <p class="read-more" @click="toggleVisibility()" @keypress.enter="toggleVisibility()" tabindex="0" v-if="item.image || item.content">
          <span v-if="!this.visible">{{ translate('more_information') }}</span>
          <span v-if="this.visible">{{ translate('less_information') }}</span>
        </p>
        <div class="row read-more-info" v-if="this.visible && breakpoint.size >= 3">
          <div :class="{ 'col-md-6': item.image, 'col-12': !item.image }" class="content col-12 mm-html"
               v-if="item.content" v-html="item.content"></div>
          <div :class="{ 'col-md-6': item.content, 'col-12': !item.content }" class="col-12 read-more-img-desktop"
               v-if="item.image">
            <img :src="item.image" class="img-fluid" role="presentation" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 30px bottom -->
    <div class="row read-more-info item" v-if="this.visible && breakpoint.size < 3">
      <div class="col-12 read-more-img" v-if="item.image">
        <img :src="item.image" class="img-fluid" role="presentation" alt="" />
      </div>
      <div :class="{ 'col-md-6': item.image, 'col-12': !item.image }" class="content col-12 mm-html" v-if="item.content"
           v-html="item.content"></div>
    </div>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
.h3
  font-size: 16px !important

.read-more-info
  padding-top: 30px !important
.read-more-img
  margin-top: -40px
  padding-left: 0px
  padding-right: 0px
  margin-bottom: 30px
.read-more-img-desktop
  top: -5px
.item
  border-right: 0px solid
  border-left: 0px solid
  padding-top: 10px
  padding-bottom: 10px
  border-bottom: 1px solid

  .item-time
    border-right: 0px solid
    padding-top: 22px

    span
      font-weight: 600
      text-align: center
      font-size: 16px !important

  .item-content
    padding: 25px
    padding-left: 10px
    padding-top: 22px
    padding-bottom: 10px
    .h3
      text-align: left

    .h3.title
      font-weight: 600

    .h3.read-more
      margin-bottom: 0
      font-weight: 300
      cursor: pointer
      text-decoration: underline
      margin-top: 5px

    .item-live
      padding-top: 10px

  .item-status-col
    padding-left: 0px
    padding-right: 10px

    .item-status
      margin-bottom: 0px
      margin-top: 8px

      .item-indicator
        height: 10px
        width: 10px
        border: 0px
        border-radius: 50%
        float: left

        span
          font-size: 70%
          margin-left: 10px
          position: absolute
          margin-top: -4px

      .item-indicator-join
        background-color: green
      .item-indicator-not-started
        background-color: yellow
      .item-indicator-finished
        background-color: orange
      .item-indicator-no-permission
        background-color: red

      .item-access
        .item-access-icon
          width: 15px
          height: 15px
          background-size: contain
          background-position: left
          background-repeat: no-repeat
          float: left
          margin-top: -4px

        span
          font-size: 70%
          margin-left: 5px
          position: absolute
          line-height: 91%
          margin-top: 2px
.item
  .item-time
    padding: 10px
    padding-left: 36px
    .icon-star-colored
      position: absolute
      top: 11px
      left: 9px
      cursor: pointer
    .item-bookmarked-default
      position: absolute
      top: 11px
      left: 9px
      color: rgba(0, 0, 0, 0.12)
      &:hover
        color: inherit
  .item-content
    padding-top: 10px

</style>
<script>
  import Moment from 'moment';
  import { AdvancedButton } from '@/components/Misc';
  import { mapStores } from 'pinia'
  import { useAppUi } from '@/stores/app-ui'
  import { useAppLanguages } from '@/stores/app-languages'
  import { useAppLiveModule } from '@/stores/app-liveModule'
  import { useAppPages } from '@/stores/app-pages'

  export default {
    props: {
      item: Object
    },
    data () {
      return {
        visible: false,
        status: null,
        time_start: null,
        time_end: null,
        bookmarked: false
      }
    },
    computed: {
      ...mapStores(useAppUi, useAppLanguages, useAppLiveModule, useAppPages),
      breakpoint () {
        return this.appUiStore.breakingPoint
      },
      iso: function () {
        const lang = this.appLanguagesStore.active
        if (lang && lang.language_data) {
          let iso = lang.language_data.iso
          if (['en', 'nl', 'fr', 'es', 'de', 'pt', 'it'].indexOf(iso) === -1) {
            iso = 'en'
          }

          return iso
        }
      },
      live_module () {
        return '/live-module/'+this.item.page_id;
      },
      liveModuleActive() {
        if(this.item.stream !== null && !this.livestreamPage) {
          return true;
        } else {
          return false;
        }
      },
      button_cta () {
        return this.translate('choose_stream')
      },
      indicator () {
        // Define indicator class
        return 'item-indicator item-indicator-' + this.status
      },
      item_access_icon () {
        if(this.item.stream.public == 'yes') {
          return 'item-access-public'
        } else {
          return 'item-access-private'
        }
      },
      stream_status () {
        // Define indicator class
        return 'stream-' + this.status
      },
      status_translation () {
        // Get translation for stream status
        return this.translate('live_stream_status_label_' + this.status)
      },
      stream_private_translation () {
        if(this.event.registration_code === false) {
          return this.translate('live_stream_no_permission_open_content')
        } else {
          return this.translate('live_stream_no_permission_closed_content')
        }
      },
      public_status () {
        if(this.item.stream.public == 'yes') {
          return this.translate('stream_access_public')
        } else {
          return this.translate('stream_access_private')
        }
      },
      livestreamPage () {
        const lang = this.appLanguagesStore.activeLanguage
        return this.appPagesStore.all.find(p => p.type === 'live' && p.language_id === lang)
      },
      bookmarkStatus() {
        return this.bookmarked === false ? 'item-bookmarked-default' : '';
      },
    },
    methods: {
      toggleVisibility () {
        this.visible = !this.visible
      },
      moment (day) {
        moment.locale(this.iso)
        return moment(day)
      },
      // Does the visitor have permissions to join the livestream
      hasPermissionToJoin() {
        return this.item.access_allowed
      },
      hasStream() {
        return this.item.stream !== null
      },
      isTimeToJoin() {
        var now = Moment();
        var walk_in_date = Moment(this.item.date_start);
        if(this.hasStream()) {
          walk_in_date = Moment(this.item.stream.walk_in_date);
        }

        // Check if walkin time is reached
        if(walk_in_date < now) {
          // Check if end date is reached
          if(this.isFinished()) {
            return false
          } else {
            // End date is not reached
            return true
          }
        } else {
          // Not yet started
          return false
        }
      },
      isFinished() {
        var now = Moment();
        var end_date = Moment(this.item.date_end);
        if(this.hasStream()) {
          end_date = Moment(this.item.stream.end_date);
        }

        // Check if end date is reached
        if(end_date < now) {
          return true
        } else {
          // End date is reached
          return false
        }
      },
      // Can someone join based on permissions and time
      canJoin() {
        if(this.hasPermissionToJoin()) {
          // Validate times
          if(this.isTimeToJoin()) {
            return true;
          } else {
            return false;
          }
        } else {
          // No permissions
          return false;
        }
      },
      // Join stream
      join() {
        if(this.canJoin()) {
          // Check if stream has access and external URL
          if(this.hasStream() && this.item.stream.platform == 'url'){
            this.appLiveModuleStore.connectToStream(this.item.stream.hash).then((result) => {
              if(result.connected){
                window.open(this.item.stream.platform_data.stream_url,'_blank');
              } else {
                alert('Something went wrong connecting to livestream. Please try again later.')
              }
            });
          } else {
            // Open live module
            this.$router.push({name: 'live-module', params: {pageId: this.item.page_id}})
          }
        }
      },
      bookmark(item_id) {
        this.bookmarked = !this.bookmarked;
        this.$emit("bookmark", item_id);
      }
    },
    created () {
      // Determine call to action label
      if(this.canJoin()) {
        // Visitor can join based on time and permissions
        this.status = 'join'
      } else {
        if(this.hasPermissionToJoin()) {
          if(this.isFinished()) {
            // Stream is finished
            this.status = 'finished'
          } else {
            // Stream not started yet
            this.status = 'not-started'
          }
        } else {
          if(this.isFinished()) {
            // Stream is finished
            this.status = 'finished'
          } else {
            this.status = 'no-permission'
          }
        }
      }

      // Get times
      this.time_start = Moment(this.item.date_start).format('HH:mm');
      this.time_end = Moment(this.item.date_end).format('HH:mm');

      // init bookmark icon
      if(localStorage.getItem("program-bookmarked") && JSON.parse(localStorage.getItem("program-bookmarked")).length > 0) {
        let bookmarkedStorage = JSON.parse(localStorage.getItem("program-bookmarked"));
        let i = bookmarkedStorage.indexOf(this.item.page_id);
        if (i > -1) {
          this.bookmarked = true;
        }
      }
    },
    components: {
      AdvancedButton
    }
  }
</script>
