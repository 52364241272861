<template>
  <div class="page-html nopadding" v-html="page.html" :ref="pageIdRef">
  </div>
</template>

<script>
  import loadScriptsMixin from "../../mixins/loadScriptsMixin"

  export default {
    name: 'html',
    props: ['page'],
    mixins: [
      loadScriptsMixin
    ],
    data() {
      return {
        htmlContent: ''
      }
    },
    mounted () {
      let scripts = Array.from(this.$refs[this.pageIdRef].querySelectorAll('script'))
      this.loadScripts(scripts)
    },
    computed: {
      pageIdRef() {
        return `page-${this.page.page_id}`
      }
    }
  }
</script>

<style type="text/scss" lang="scss" scoped>
  .nopadding {
    padding-top: 0 !important;
  }
</style>
