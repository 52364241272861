<template>
    <div class="row justify-content-center">
        <div class="col-12" v-html="content"></div>
    </div>
</template>
<style lang="sass" type="text/sass">

</style>
<script>
  import { mapStores } from "pinia"
  import { useApp } from "@/stores/app"

  export default {
    props: {
      page: Object
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapStores(useApp),
      content () {
        if (this.translationExists('text_registration_maximum')) {
          return this.translate('text_registration_maximum')
        } else {
          return this.appStore.event.text_registration_maximum
        }
      }
    }
  }
</script>
