<template>
    <div class="widget-image" v-if="image" :style="backgroundColorWidget">
      <div :class="{'container':inner}">
        <div class="image-widget-overlay" :style="{ backgroundImage: 'url(\''+image+'\')' }">
            <div :class="overlay"></div>
            <img :src="image" class="img-fluid image-canvas" role="presentation" alt="" />
        </div>
      </div>
    </div>
</template>
<style lang="sass" scoped type="text/sass">
.widget-image
  padding: 0

.image-widget-overlay
  position: relative
  text-align: center
  background-repeat: no-repeat
  background-position: center center
  background-size: cover

  .overlay
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

  img
    opacity: 0
</style>
<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import { mapStores } from "pinia"
  import { useAppUi } from "@/stores/app-ui"

  export default {
    name: 'image-v1',
    mixins: [widgetMixin],
    data () {
      return {
      }
    },
    computed: {
      ...mapStores(useAppUi),
      image: function () {
        return this.imageMobile || this.widget.values.image
      },
      inner () {
        if(this.imageMobile){
          return this.widget.values.image_mobile_width === 'content'
        }
        return this.widget.values.width === 'content'
      },
      imageMobile() {
        return this.device == 'mobile' && this.widget.values.image_mobile ? this.widget.values.image_mobile : false
      },
      overlay() {
        return this.imageMobile ? 'overlay overlay-mobile' : 'overlay'
      },
      backgroundColorWidget() {
        return this.imageMobile ? 'background-color: ' + this.widget.values.image_mobile_bkg_color : ''
      },
      device() {
        const breakPoint = this.appUiStore.breakingPoint
        return breakPoint.size <= 2 ? 'mobile' : 'desktop';
      }
    }
  }
</script>

