import { AppMenu, Styleguide, Confirm, Unsubscribe, LinkExpired } from "../components"
import Homepage from "../pages/Homepage"
import Lounge from "../pages/Lounge"
import Register from "../pages/Register"
import LogoutForNewRegistration from "../pages/LogoutForNewRegistration"
import RegistrationComplete from "../pages/RegistrationComplete"
import MyAccount from "../pages/MyAccount"
import Page from "../pages/Page"
import Account from "../pages/types/Account"
import ForgotPassword from "../pages/types/Account/ForgotPassword"
import ResetPassword from "../pages/types/Account/ResetPassword"
import Complete from "../pages/purchase/Complete"
import PhotoUpload from "../pages/PhotoUpload"
import Survey from "../pages/types/Survey"
import ErrorRedirect from "../components/Errors/404_redirect"
import Livestream from "../pages/Livestream"
import LiveModuleAccess from '../pages/LiveModuleAccess'
import LiveModule from "../pages/LiveModule"
import NewsDetails from "../components/News/NewsDetails"
import Payment from "../pages/Payment"
import Sitemap from "../pages/Sitemap"
import PersonalProgram from "../pages/PersonalProgram"
import Login from "@/pages/login/Login"
import TwoFA from "@/pages/login/TwoFA"
import TwoFAIncorrect from '@/pages/login/TwoFAIncorrect'
import TooManyRequests from '@/pages/login/TooManyRequests'

export const routes = [
  {
    name: 'menu',
    path: '/menu',
    component: AppMenu
  },
  {
    name: 'home',
    path: '/',
    component: Homepage
  },
  {
    name: 'lounge',
    path: '/lounge',
    component: Lounge
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'two-fa',
    path: '/login/2fa',
    component: TwoFA
  },
  {
    name: 'two-fa-incorrect',
    path: '/login/error',
    component: TwoFAIncorrect
  },
  {
    name: 'too-many-requests',
    path: '/login/too-many-requests',
    component: TooManyRequests
  },
  {
    name: 'register',
    path: '/register/:hash?',
    component: Register
  },
  {
    name: 'new-registration',
    path: '/registration/new',
    component: LogoutForNewRegistration
  },
  {
    name: 'registration-complete',
    path: '/registration/complete',
    component: RegistrationComplete
  },
  {
    name: 'my-account',
    path: '/my-account/:hash?',
    component: MyAccount
  },
  {
    name: 'teamcode',
    path: '/register/team/:hash?',
    component: Register
  },
  {
    name: 'page',
    path: '/page/:pageId/:param1?',
    component: Page
  },
  {
    name: 'account',
    path: '/account',
    component: Account
  },
  {
    name: 'forgot-password',
    path: '/account/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'reset-password',
    path: '/account/reset-password/:hash',
    component: ResetPassword
  },
  {
    name: 'styleguide',
    path: '/styleguide',
    component: Styleguide
  },
  {
    name: 'confirm',
    path: '/confirm/:hash',
    component: Confirm
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe/:hash?/:guestHash?",
    component: Unsubscribe,
  },
  {
    name: 'purchase-complete',
    path: '/purchase/complete',
    component: Complete
  },
  {
    name: 'photo-upload',
    path: '/photos/upload',
    component: PhotoUpload
  },
  {
    name: 'survey',
    path: '/survey',
    component: Survey
  },
  {
    name: '404',
    path: '/:catchAll(.*)',
    component: ErrorRedirect
  },
  {
    name: 'livestream',
    path: '/livestream/:hash?',
    component: Livestream
  },
  {
    name: 'vote',
    path: '/vote',
    component: LiveModuleAccess
  },
  {
    name: 'qa',
    path: '/qa',
    component: LiveModuleAccess
  },
  {
    name: 'live-module',
    path: '/live-module/:pageId/:hash?',
    component: LiveModule
  },
  {
    name: 'news',
    path: '/news/:newsId',
    component: NewsDetails
  },
  {
    name: 'payment',
    path: '/payment',
    component: Payment
  },
  {
    name: 'link-expired',
    path: '/link-expired',
    component: LinkExpired
  },
  {
    name: 'sitemap',
    path: '/sitemap',
    component: Sitemap
  },
  {
    name: 'personal-program',
    path: '/personal-program',
    component: PersonalProgram
  }
]
