<template>
    <div class="widget-location">
        <widget-title :title="title"></widget-title>

        <VenueOverview v-if="venues.length > 1" :venues="venues"></VenueOverview>

        <div class="container" v-if="venues.length === 1">
            <VenueDetail :venue="venues[0]"></VenueDetail>
        </div>

        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import VenueOverview from "../../../components/Venues/VenueOverview"
  import VenueDetail from "../../../components/Venues/VenueDetail"

  export default {
    name: 'location-v1',
    components: {
      VenueOverview,
      VenueDetail
    },
    mixins: [
      widgetMixin
    ],
    computed: {
      title () {
        return this.widget.values.title
      },
      venues () {
        return this.widget.values.venues
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
img
  margin-bottom: 20px

h2
  font-weight: 600

button
  width: 100%
</style>
