<template>
    <div class="deadline" role="timer" v-html="translate('purchase_countdown', values)" v-show="visible" />
</template>
<style lang="scss" type="text/scss" scoped>
    .deadline {
        color: #FFA500;
        font-weight: 600;
        font-size: 1rem;
        margin: 40px 0;
    }
</style>
<script>
  import moment from 'moment'
  import { mapStores } from 'pinia'
  import { useAppRegistration } from '@/stores/app-registration'
  import { useAppRegistrationIntroducee } from '@/stores/app-registration-introducee'

  export default {
    props: {
      expire: Number,
      visible: {
        type: Boolean,
        default: true,
        required: false
      },
      isFreeTicket: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        seconds: null,
        values: {
          minutes: null,
          seconds: null
        }
      }
    },
    mounted () {
      this.start()
    },
    beforeUnmount () {
      this.reset()
    },
    computed: {
      ...mapStores(useAppRegistration, useAppRegistrationIntroducee)
    },
    methods: {
      start () {
        this.reset()
        this.startInterval()
        this.tick()
      },
      reset () {
        clearInterval(this.interval)
      },
      tick () {
        let timestamp = this.expire
        let then = moment.unix(timestamp).utc()
        let now = moment().utc()
        let duration = moment.duration(then.diff(now)).asSeconds()
        if (duration <= 0) {
          this.appRegistrationStore.setExpired(true)
          clearInterval(this.interval)
          if (this.isFreeTicket) {
            window.alert(this.translate('purchase_expired_freeticket'))
          } else {
            window.alert(this.translate('purchase_expired'))
          }
          this.cancel()
        } else {
          let seconds = Math.floor(duration)
          let minutes = Math.floor(seconds / 60)

          this.values = {
            minutes,
            seconds: seconds - (minutes * 60)
          }
        }
      },
      startInterval () {
        this.interval = setInterval(() => {
          this.tick()
        }, 1000)
      },
      cancel () {
        this.appRegistrationIntroduceeStore.clearReservations().then(() => {
          this.appRegistrationStore.init()
        })
        if (this.$route.name === 'payment') {
          this.$router.push({ name: 'home' })
        }
      }
    }
  }
</script>
