<template>
  <PageContainer>
    <div class="row justify-content-center">
      <div v-html="translate('try_again_description')" class="mm-html content row"></div>
      <div class="row">
        <div class="col text-center">
          <button class="btn btn-1" v-on:click="handleTryAgain()" data-cy="tryagain-button">{{ translate('try_again') }}</button>
        </div>
      </div>
    </div>
  </PageContainer>
</template>

<script>
// TODO: move to the Composition API when:
// - we have Vue 3 
// - we don't use the translate mixins
// - We don't use Vuex
import PageContainer from '@/components/PageContainer'

export default {
  components: {
    PageContainer
  },
  methods: {
    handleTryAgain() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style>

</style>