<template>
    <div class="page-embed" :class="{ 'nopadding': !page.padding }">
        <div class="container">
            <div class="row content" v-html="page.content"></div>
            <div class="row justify-content-md-center">
                <div class="col-12">
                    <iframe v-if="page.src" :src="page.src" frameborder="none"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'embed',
    props: ['page']
  }
</script>

<style type="text/scss" lang="scss" scoped>
    .nopadding {
        padding-top: 0 !important;

        .col-12 {
            padding: 0;
        }
    }
</style>
