import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import parser from 'ua-parser-js'

export const useDevice = defineStore('device', () => {
  const navigatorState = ref(parser(window.navigator.userAgent))

  const browser = computed(() => navigatorState.value.browser)
  const cpu = computed(() => navigatorState.value.cpu)
  const device = computed(() => navigatorState.value.device)
  const engine = computed(() => navigatorState.value.engine)
  const os = computed(() => navigatorState.value.os)
  const ua = computed(() => navigatorState.value.ua)

  return { 
    navigatorState,
    browser,
    cpu,
    device,
    engine,
    os,
    ua
  }
})