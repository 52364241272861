<template>
    <div class="widget-attachments">
        <widget-title :title="title"></widget-title>
        <div class="container">
            <AttachmentOverview :attachments="attachments"></AttachmentOverview>
        </div>

        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import AttachmentOverview from "../../../components/Attachments/AttachmentOverview"

  export default {
    name: 'attachments-v1',
    components: {
      AttachmentOverview
    },
    mixins: [
      widgetMixin
    ],
    computed: {
      title () {
        return this.widget.values.title
      },
      attachments () {
        return this.widget.values.attachments
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>
