import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useAppUi = defineStore('appUi', () => {
  const breakingPointState = ref({})
  const footerState = ref(true)
  const scrollToTopState = ref(true)
  const forceShowScrollToTopState = ref(false)
  const surveyFooterState = ref(false)
  const ajaxLoadingState = ref(false)
  
  const breakingPoint = computed(() => breakingPointState.value)
  const footer = computed(() => footerState.value)
  const scrollToTop = computed(() => scrollToTopState.value)
  const forceShowScrollToTop = computed(() => forceShowScrollToTopState.value)
  const surveyFooter = computed(() => surveyFooterState.value)
  const ajaxLoading = computed(() => ajaxLoadingState.value)

  function setCurrentBreakingPoint(data) {
    breakingPointState.value = data
  }

  function setAjaxLoading(data) {
    ajaxLoadingState.value = data
  }

  function setFooter(data) {
    footerState.value = data
  }

  function setScrollToTop(data) {
    scrollToTopState.value = data
  }

  function setForceShowScrollToTop(data) {
    forceShowScrollToTopState.value = data
  }

  function setSurveyFooter(data) {
    surveyFooterState.value = data
  }

  return { 
    breakingPointState,
    footerState,
    scrollToTopState,
    forceShowScrollToTopState,
    surveyFooterState,
    ajaxLoadingState,
    breakingPoint,
    footer,
    scrollToTop,
    forceShowScrollToTop,
    surveyFooter,
    ajaxLoading,
    setCurrentBreakingPoint,
    setAjaxLoading,
    setFooter,
    setScrollToTop,
    setForceShowScrollToTop,
    setSurveyFooter
  }
})