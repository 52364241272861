<template>
    <div :class="{ 'container': fullWidth === false }" class="widget-divider">
        <div class="row" v-if="!fullWidth">
            <div class="col-12">
                <div class="line"></div>
            </div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.widget-divider
  padding: 0

.container
  @include media-breakpoint-up(sm)
    .col-12
      padding-left: 0
      padding-right: 0

  .line
    width: 100%
    height: 2px
    background-color: #FF0000
</style>
<script>
  import widgetMixin from '../../../mixins/widgetMixin'

  export default {
    name: 'divider-v1',
    mixins: [widgetMixin],
    data () {
      return {
      }
    },
    computed: {
      fullWidth () {
        return this.widget.values.width === 'full'
      }
    }
  }
</script>
