import http from "src/http";

class NewsService {
  getNewsItem(newsId) {
    return new Promise((resolve, reject) => {
      http.get(`/news/${newsId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new NewsService();
