<template>
    <div class="row widget-button-container" :class="{ 'margin': margin}" v-if="active">
        <div class="col-12 text-center">
            <router-link v-if="type === 'intern'" :target="target" :to="{ name: 'page', params: { pageId: link }}" class="btn">{{ title }}</router-link>

            <a v-if="type === 'extern'" :target="target" :href="link" class="btn">{{ title }}</a>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.widget-button-container.margin
  margin-top: 40px
@include media-breakpoint-down(md)
  .widget-button-container.margin
    margin-top: 50px
</style>
<script>
  export default {
    props: {
      button: Object,
      margin: {
        default: true,
        type: Boolean
      }
    },
    data () {
      return {}
    },
    computed: {
      active () {
        if (this.button && this.button.active) {
          return this.button.active
        } else {
          return false
        }
      },
      target () {
        return this.button.target || '_self'
      },
      title () {
        return this.button.title
      },
      type () {
        return this.button.type
      },
      link () {
        return this.button.link
      }
    }
  }
</script>
