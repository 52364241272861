<template>
  <div class="widget-program">
    <!-- nav tab -->
    <div
      id="nav-tab"
      class="nav nav-tabs nav-fill"
      role="tablist"
    >
      <a
        :class="'nav-item nav-link' + (program_tab ? ' active' : '')"
        data-toggle="tab"
        role="tab"
        @click="switchTab('program_tab')"
      >
        <h2>{{ title }}
          <i
            v-if="icon && template.menu.icons === true"
            :class="icon"
          />
        </h2>
      </a>
      <a
        :class="'nav-item nav-link' + (mysessions_tab ? ' active' : '')"
        data-toggle="tab"
        role="tab"
        @click="switchTab('mysessions_tab')"
      >
        <h2> {{ my_sessions_title }}
          <i class="icon-bookmark">0</i>
        </h2>
      </a>
    </div>
    <div class="lounge-widget-content">
      <!-- full program -->
      <div v-if="program_tab">
        <div
          v-if="totItems > 0"
          ref="programContainer"
          class="container program-container"
        >
          <div
            ref="dayList"
            class="day-list"
          >
            <div
              v-for="(day, timestamp, index) in program"
              :key="index"
            >
              <div class="row">
                <div
                  :id="uniqueId + timestamp"
                  class="col-12 date-header text-center"
                  :style="`background-color: ${colors(2)}`"
                >
                  {{ day.date }}
                </div>
              </div>
              <ProgramItemWidget
                v-for="(item, i) in day.items"
                :key="i"
                :item="item"
                @bookmark="bookmarkItem"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          style="display: contents;"
        >
          <div
            class="lounge-widget-no-content mm-html"
            v-html="translate('lounge_program_widget_no_items')"
          />
        </div>
      </div>
      <!-- my sessions -->
      <div v-if="mysessions_tab">
        <div
          v-if="bookmarkedItems.length > 0"
          ref="programContainer"
          class="container program-container"
        >
          <div
            ref="dayList"
            class="day-list"
          >
            <template v-if="hasBookmarked(day.items)">
              <div
                v-for="(day, timestamp, index) in program"
                :key="index"
              >
                <div class="row">
                  <div
                    :id="uniqueId + timestamp"
                    class="col-12 date-header text-center"
                    :style="`background-color: ${colors(2)}`"
                  >
                    {{ day.date }}
                  </div>
                </div>
                <template v-if="isBookmarked(item.page_id)">
                  <ProgramItemWidget
                    v-for="(item, i) in day.items"
                    :key="i"
                    :item="item"
                    @bookmark="bookmarkItem"
                  />
                </template>
              </div>
            </template>
          </div>
        </div>
        <div
          v-else
          style="display: contents;"
        >
          <div
            class="lounge-widget-no-content mm-html"
            v-html="translate('lounge_program_mysessions_no_items')"
          />
        </div>
      </div>
    </div>
    <div class="lounge-widget-footer">
      <router-link
        v-slot="{ navigate }"
        :to="{ name: 'page', params: { pageId: page.page_id }}"
        custom
      >
        <div
          class="goto"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <span class="goto-text">{{ translate('go_to_program') }}</span>
          <span class="goto-icon"><i class="icon-arrow-right" /></span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import widgetMixin from '../../../mixins/loungeWidgetMixin'
  import ProgramItemWidget from './WidgetItem'
  import { mapStores } from 'pinia'
  import { useAppPages } from '@/stores/app-pages'
  import { useAppLanguages } from '@/stores/app-languages'
  import { useAppTemplate } from '@/stores/app-template'

  export default {
    name: 'LoungeProgramV1',
    components: {
      ProgramItemWidget
    },
    mixins: [widgetMixin],
    data () {
      return {
        icon: 'icon-calendar',
        program_tab: true, // default
        mysessions_tab: false,
        bookmarkedItems: []
      }
    },
    computed: {
      ...mapStores(useAppLanguages, useAppPages, useAppTemplate),
      language() {
        return this.appLanguagesStore.activeLanguage
      },
      page() {
        return this.appPagesStore.all.find(p => p.type === 'program' && p.language_id === this.language)
      },
      uniqueId () {
        return this.css_id
      },
      title () {
        return this.widget.values.title
      },
      titleColor () {
        return this.widget.values.title_color
      },
      program () {
        return this.widget.values.program
      },
      title_program () {
        return this.widget.values.title_program
      },
      button () {
        return this.widget.values.button
      },
      totItems () {
        return Object.keys(this.program).length
      },
      my_sessions_title () {
        return this.translate('lounge_my_sessions_title')
      },
      template () {
        return this.appTemplateStore.template
      }
    },
    created () {
      // init array from storage
      if(localStorage.getItem("program-bookmarked") && JSON.parse(localStorage.getItem("program-bookmarked")).length > 0) {
        this.bookmarkedItems = JSON.parse(localStorage.getItem("program-bookmarked"));
      }
    },
    methods: {
      switchTab(tab) {
        let tabs = ['program_tab','mysessions_tab'];
        tabs.forEach(t => {
          this[t] = false;
          if (t == tab){
            this[tab] = true;
          }
        })
      },
      bookmarkItem(item_id) {
        if(!this.bookmarkedItems.includes(item_id)) {
          this.bookmarkedItems.push(item_id);
          localStorage.setItem("program-bookmarked",JSON.stringify(this.bookmarkedItems));
        }else{
          let i = this.bookmarkedItems.indexOf(item_id);
          if (i > -1) {
            this.bookmarkedItems.splice(i, 1);
            localStorage.setItem("program-bookmarked",JSON.stringify(this.bookmarkedItems));
          }
        }
      },
      hasBookmarked (dayItems) {
        let found = false;
        dayItems.forEach(item => {
        let i = this.bookmarkedItems.indexOf(item.page_id);
          if (i > -1) {
            found = true;
          }
        });
        return found;
      },
      isBookmarked (item_id) {
        let i = this.bookmarkedItems.indexOf(item_id);
          if (i > -1) {
            return true;
          }
        return false;
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.nav
  border-radius: 4px 4px 0px 0px
  height: 56px
  border: 0

.nav-tabs
  font-weight: bold
  border-radius: 4px 4px 0px 0px

.nav-tabs .nav-link
  border: 0
  padding: 0
  background-color: transparent
  width: 50%
  cursor: pointer

  h2
    margin-left: 24px
    line-height: 56px
    text-align: left
    font-weight: 600
    font-size: 20px
    i
      position: absolute
      top: 16px
      margin-left: 18px
      color: inherit

.nav-link.active
  background-color: #ffffff
  border-radius: 4px 4px 0px 0px

.lounge-widget-content
  overflow-x: hidden
  overflow-y: auto

  .program-container
    width: 100%

    .date-header
      font-size: 17px !important
      height: 40px
      line-height: 40px
      font-weight: bold
      text-align: left !important

.lounge-widget-no-content
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  padding-top: 50px
  padding-left: 20px
  padding-right: 20px

.lounge-widget-footer
  height: 56px
  padding-top: 12px
  padding-bottom: 12px
  padding-right: 16px
  position: absolute
  bottom: 0px
  width: 100%
  text-align: right

  .goto
    float: right
    font-weight: 500
    font-size: 17px
    line-height: 35px
    cursor: pointer

    .goto-text
      padding-right: 32px

    .goto-icon
      position: absolute
      top: 19px
      right: 16px
      i
        color: inherit
</style>
