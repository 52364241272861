import http from "src/http";

class LoungeService {
  getLoungeWidgets() {
    return new Promise((resolve, reject) => {
      http.get('lounge')
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
  checkin() {
    return new Promise((resolve, reject) => {
      http.post('lounge/checkin')
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
}

export default new LoungeService();