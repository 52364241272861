<template>
    <div class="page-extra mm-page" :class="{ 'has-image': page.image }">
      <ImageHeader :imageUrl="page.image"></ImageHeader>
      <div class="container">
          <div class="row content mm-html" v-html="this.page.content"></div>

          <Subpage v-for="(subpage, index) in this.page.subpages" :subpage="subpage" :key="index" />
      </div>
    </div>
</template>

<script>
  import { Subpage } from 'components'
  import ImageHeader from "../../components/ImageHeader.vue";
  export default {
    props: ['page'],
    components: {
      ImageHeader,
      Subpage
    }
  }
</script>

<style lang="scss" type="text/scss">
.page-extra {
  padding-bottom: 72px;
  &.has-image {
    padding-top: 0 !important;
  }

  .image {
    width: 100%;
    max-height: 550px;
    background-size: cover;
    background-position: center center;
  }
  .subpage {
    padding-bottom: 20px;

    h2 {
      text-align: left;
      font-weight: 600;
    }
    img {
      padding: 20px 0 30px 0;
    }
  }
}
</style>
