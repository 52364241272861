<template>
  <div class="page-flex mm-page">
    <div class="container" v-if="readyForPayment">
      <div class="row justify-content-center">

        <div class="col-md-10 col-lg-8 col-12">

          <h2>{{ translate('eventsite_payment_page_payment_title') }}</h2>

          <Deadline class="text-center" :expire="purchase.expires" :visible="true" :isFreeTicket="false"></Deadline>

          <Ticket :ticket="activeTicket" :active="true"></Ticket>

          <div ref="payment-container"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { env } from "@/env"
import AdyenCheckout from '@adyen/adyen-web'
import Ticket from "./types/Registration/Ticket"
import Deadline from "./types/Registration/Ticket/Deadline"
import { mapStores } from "pinia"
import { useAppPayment } from '@/stores/app-payment'
import { useAppRegistration } from '@/stores/app-registration'

export default {
  name: "Payment",
  components: {
    Deadline,
    Ticket
  },
  data() {
    return {
      redirectResult: null
    }
  },
  async mounted() {
    this.redirectResult = this.$route.query.redirectResult ? this.$route.query.redirectResult : null

    if (localStorage.getItem('sessionData') && localStorage.getItem('sessionDataId') && this.redirectResult) {
      // Clear the query params for security reasons
      this.$router.replace(this.$route.path)

      await this.finishPayment()
      localStorage.removeItem('sessionData')
      localStorage.removeItem('sessionDataId')
    } else if (this.sessionData) {
      await this.startPayment()
    } else {
      this.$router.push({ name: 'home' })
    }
  },
  computed: {
    ...mapStores(useAppPayment, useAppRegistration),
    sessionData() {
      return this.appPaymentStore.sessionData
    },
    purchase() {
      return this.appRegistrationStore.purchase
    },
    tickets() {
      return this.appRegistrationStore.tickets
    },
    readyForPayment() {
      return this.sessionData !== null
    },
    activeTicket() {
      return this.tickets.find(t => t.ticket_id === this.purchase.ticket_id)
    }
  },
  methods: {
    async startPayment() {
      try {
        localStorage.setItem('sessionData', this.sessionData.sessionData)
        localStorage.setItem('sessionDataId', this.sessionData.id)

        const checkout = await AdyenCheckout(this.getConfiguration())

        checkout.create('dropin').mount(this.$refs['payment-container'])
      } catch (error) {
        console.error(error)
        window.handle_error(error, 'startPayment - payment')
        alert(this.translate('eventsite_payment_error_alert'))
      }
    },
    async finishPayment() {
      try {
        const checkout = await AdyenCheckout(this.getConfiguration())

        // Will trigger onPaymentCompleted() handler
        checkout.submitDetails({ details: { redirectResult: this.redirectResult } })
      } catch (error) {
        console.error(error)
        window.handle_error(error, 'finishPayment - payment')
        alert(this.translate('eventsite_payment_error_alert'))
      }
    },
    async handlePaymentOnComplete(res, component) {
      if (res.action) {
        component.handleAction(res.action)
      } else {
        this.appPaymentStore.setPaymentResponse(res)
        this.appPaymentStore.setRedirectResult(this.redirectResult)

        this.$router.push({ name: 'purchase-complete' })
      }
    },
    async handleError(error, component) {
      window.handle_error(error, 'onError Handler - payment')
      console.error('ON ERROR', error.name, error.message, error.stack, component)
    },
    getConfiguration() {
      return {
        environment: env.ADYEN_ENVIRONMENT,
        showPayButton: true,
        clientKey: env.ADYEN_CLIENT_KEY,
        analytics: {
          enabled: false
        },
        session: {
          id: localStorage.getItem('sessionDataId'),
          sessionData: localStorage.getItem('sessionData')
        },
        onPaymentCompleted: (result, component) => {
          this.handlePaymentOnComplete(result, component)
        },
        onError: (error, component) => {
          this.handleError(error, component)
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true
          }
        },
        openFirstPaymentMethod: false
      }
    }
  }
}
</script>

<style lang="sass" type="text/sass">
.page-flex
  padding: 100px 0
</style>
