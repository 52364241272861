<template>
    <div class="widget-plan">
        <widget-title :title="title"></widget-title>
        <div class="container">
            <PlanOverview :plan="plan"></PlanOverview>
        </div>
        <widget-button :button="button"></widget-button>
    </div>
</template>

<script>
  import widgetMixin from '../../../mixins/widgetMixin'
  import PlanOverview from "../../../components/Plan/PlanOverview"

  export default {
    name: 'plan-v1',
    components: {
      PlanOverview
    },
    mixins: [
      widgetMixin
    ],
    computed: {
      title () {
        return this.widget.values.title
      },
      plan () {
        return this.widget.values.plan
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>
