<template>
  <div class="my-account page-flex">
    <div
      v-if="inited && invite"
      class="container"
    >
      <div class="row">
        <div
          v-if="invite.image"
          class="col-lg-4"
        >
          <ImageOrInitials
            class="ImageOrInitials"
            :initials="invite.initials"
            :image="invite.image"
            :resource="{ details: invite.name }"
          />
        </div>
        <div
          class="details"
          :class="{'col-lg-8': !invite.image, 'col-lg-4': invite.image}"
        >
          <div class="box">
            <h2 class="mm-detail-title">
              {{ invite.name }}
            </h2>
            <p>{{ invite.function }}<span v-if="invite.function && invite.company">,</span> {{ invite.company }}</p>
            <SocialMediaIcons
              :social-media-links="invite.icons"
              :owner-name="invite.name"
            />
          </div>
          <Divider
            :hide-for="['lg']"
            :margin="30"
          />
          <div class="box">
            <ticket
              v-if="!invite.seating"
              :ticket="invite.ticket"
              :active="true"
            />

            <div
              v-for="(field, index) in invite.fields"
              :key="index"
            >
              <p class="fw-bold m-0">
                {{ field.description }}
              </p>
              <p v-if="field.type === 'checkbox' && field.value === '0'">
                {{ translate('field_checkbox_no') }}
              </p>
              <p v-else-if="field.type === 'checkbox' && field.value === '1'">
                {{ translate('field_checkbox_yes') }}
              </p>
              <div v-else-if="field.type === 'file' && field.value"
                class="pt-1 pb-1 ps-2 pe-2 d-flex align-items-center rounded-1"
                :style="`border: 1px solid ${colors(5)} !important; background-color: ${colors(6)} !important; margin-bottom: 1rem; width: max-content; max-width: 100%`"
              >
                <p
                  class="mb-0 text-truncate overflow-hidden"
                  :style="`color: ${colors(7)} !important`"
                >
                  {{ field.value.filename }}
                </p>
              </div>
              <p v-else>
                {{ field.value }}
              </p>
            </div>

            <div v-if="invite.seat">
              <p class="fw-bold m-0">
                {{ translate('seat') }}
              </p>
              <p>{{ invite.seat }}</p>
            </div>

            <div
              v-if="invite.seating"
              class="row"
            >
              <div class="col-12">
                <h2
                  style="color: #e8830e"
                  class="mt-3"
                >
                  BOARDINGPASS POSTILLION HOTELS
                </h2>
                <div
                  v-if="invite.seating.boarding_pass"
                  id="boarding-pass-info"
                >
                  <div>
                    <p class="m-0">
                      <span class="fw-bold">{{ translate('seat') }}: </span> {{ invite.seat }}
                    </p>
                  </div>
                  <div
                    v-for="(value, name) in invite.seating.boarding_pass"
                    :key="name"
                  >
                    <p class="m-0">
                      <span class="fw-bold">{{ translate('boardingpass_'+name) }}: </span> {{ value }}
                    </p>
                  </div>
                </div>
                <div class="clearfix" />
                <a
                  v-if="invite.boarding_pass_url"
                  :href="invite.boarding_pass_url"
                  class="btn btn-3 mt-4"
                  target="_blank"
                >
                  Download boarding pass
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 text-center">
          <strong>Check-in QR-code ({{ invite.name }})</strong>
          <QRCode
            v-if="invite.qrcode"
            class="qrImage"
            :value="invite.qrcode"
            :size="278"
          />
          <div class="buttons">
            <a
              v-if="invite.eticket_url"
              :href="invite.eticket_url"
              class="btn btn-3 float-end"
              target="_blank"
            >
              Download e-ticket
            </a>
            <a
              v-if="invite.invoice_url"
              :href="invite.invoice_url"
              class="btn btn-2 float-end"
              target="_blank"
            >
              {{ translate("download_invoice") }}
            </a>
            <router-link
              v-slot="{ navigate }"
              :to="{ name: 'register'}"
              custom
            >
              <button
                class="btn btn-2 float-end"
                @click="navigate"
                @keypress.enter="navigate"
              >
                {{ translate('my_account_edit_link') }}
              </button>
            </router-link>
            <router-link
              :to="{ name: 'unsubscribe', params: { hash: invite.hash}}"
              class="btn btn-2 float-end"
            >
              {{ translate('unsubscribe') }}
            </router-link>
            <button
              class="btn btn-1 float-end"
              @click="logout"
            >
              {{ translate('my_account_logout') }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="invite.introducees"
        class="row"
      >
        <div class="col-12">
          <hr>
          <h2 class="text-center text-lg-start">
            {{ translate('introducee_plural') }}
          </h2>
        </div>
        <div
          v-for="(introducee, index) in invite.introducees"
          :key="index"
          class="col-lg-3"
        >
          <div class="box">
            <p>{{ introducee.fullname }}</p>
            <p v-if="introducee.email">
              {{ introducee.email }}
            </p>
            <div
              v-for="(value, label) in introducee.summary"
              :key="label"
            >
              <p class="fw-bold m-0">
                {{ label }}
              </p>
              <p>{{ value }}</p>
            </div>

            <strong>Check-in QRcode ({{ introducee.fullname }})</strong>
            <QRCode
              class="qrImage mb-2"
              :value="introducee.qrcode"
              :size="190"
            />

            <a
              v-if="introducee.eticket_url"
              :href="introducee.eticket_url"
              class="btn btn-2"
              target="_blank"
            >
              Download e-ticket
            </a>

            <a
              class="btn btn-1 mt-2"
              @click="
                goToUnsubscribe({
                  guestHash: introducee.hash,
                  hash: invite.hash,
                })
              "
            >
              {{ translate("unsubscribe") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QRCode, Divider } from "@/components/Misc";
import { ImageOrInitials } from "@/components";
import Ticket from "./../pages/types/Registration/Ticket";
import SocialMediaIcons from "../components/SocialMedia/SocialMediaIcons";
import { mapStores } from "pinia";
import { useVisitor } from "@/stores/visitor";

export default {
  name: "MyAccount",
  components: {
    ImageOrInitials,
    QRCode,
    Divider,
    Ticket,
    SocialMediaIcons,
  },
  data() {
    return {};
  },
  computed: {
    ...mapStores(useVisitor),
    inited() {
      return this.visitorStore.inited;
    },
    invite() {
      return this.visitorStore.invite;
    },
  },
  created() {
    let hash = this.$route.params.hash;

    if (this.inited && !hash) {
      return;
    }
    this.visitorStore
      .loginByHash(hash)
      .then(() => {
        this.$router.replace("/my-account");
      })
      .catch(() => {
        // redirect to registration
        let url = "/register";

        if (hash) {
          url += "/" + hash;
        }

        this.$router.replace(url);
      });
  },
  methods: {
    logout() {
      this.visitorStore.logout().then(() => {
        this.$router.push("/");
      });
    },
    goToUnsubscribe(params) {
      this.$router.push({
        name: "unsubscribe",
        params,
      });
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped>
.page-flex
  padding: 100px 0
.my-account
  .ImageOrInitials, .qrImage
    max-width: 320px
    margin: 0 auto

  .buttons
    max-width: 320px
    margin: 0 auto

  .box
    text-align: left
    margin-bottom: 30px

    h2
      text-align: left

  @include media-breakpoint-down(lg)
    .box
      text-align: center
      margin: 30px 0
      h2
        text-align: center

  .placeholder
    width: 100%
    background-color: #999999
    padding-bottom: 100%

  .details
    h2
      font-weight: 600

  .buttons
    button, a
      width: 100%
      margin-top: 8px

  #boarding-pass-info
    p
      margin-top: 5px !important
      span
        min-width: 100px
        display: inline-block
</style>
