<template>
    <transition name="fade">
        <div class="alert alert-danger" v-if="hasError" data-cy="partial-errors">
            <span v-for="(e, index) in errors" :key="index">
                <span v-for="(ee, indexError) in e" :key="indexError">
                    {{ translate(ee) }}
                </span>
            </span>
        </div>
    </transition>
</template>

<script>
  export default {
    props: {
      errors: Object
    },
    data () {
      return {}
    },
    computed: {
      hasError () {
        return Object.keys(this.errors).length > 0
      }
    }
  }
</script>
