<template>
    <div class="widget-text">
        <widget-title :title="title"></widget-title>
        <div class="container">
            <div class="row content mm-html" v-if="content" v-html="content"></div>
            <widget-button :button="button"></widget-button>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
h1.widget-title
  margin-bottom: 0
</style>
<script>
  import widgetMixin from '../../../mixins/widgetMixin'

  export default {
    name: 'text-v1',
    mixins: [widgetMixin],
    data () {
      return {}
    },
    computed: {
      title () {
        return this.widget.values.title
      },
      content () {
        return this.widget.values.content
      },
      button () {
        return this.widget.values.button
      }
    }
  }
</script>
