<template>
  <div class="lounge-widget-news">
    <lounge-widget-title
      :title="title"
      :color="titleColor"
      :icon="icon"
    />
    <div class="lounge-widget-content">
      <div class="container lounge-widget-news-items">
        <template v-if="news_ready">
          <div
            v-for="n in news"
            :key="n.news_id"
          >
            <div class="row">
              <div class="col-lg-12 item-card">
                <router-link
                  v-slot="{ navigate }"
                  :to="{ name: 'news', params: { newsId: n.news_id }}"
                  custom
                >
                  <p
                    class="item-title"
                    @click="navigate"
                    @keypress.enter="navigate"
                  >
                    {{ n.title }}
                  </p>
                </router-link>
                <div
                  v-if="itemImage(n)"
                  class="item-image"
                  :style="{ backgroundImage: 'url('+itemImage(n)+')' }"
                />
                <p
                  v-else
                  class="item-content"
                >
                  {{ n.introduction }}
                </p>
                <p
                  v-if="n.type == 'regular' && n.external_url != ''"
                  class="item-ext-url"
                  @click.stop="goto(n.external_url)"
                >
                  {{ translate('more_information') }}
                </p>
              </div>
              <news-footer
                :data="n"
                :is-overview="true"
                :is-widget="true"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="lounge-widget-footer">
      <router-link
        v-slot="{ navigate }"
        :to="{ name: 'page', params: { pageId: page.page_id }}"
        custom
      >
        <div
          class="goto"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <span class="goto-text">{{ translate('go_to_news') }}</span>
          <span class="goto-icon"><i class="icon-arrow-right" /></span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import widgetMixin from "../../../mixins/loungeWidgetMixin"
import Moment from "moment"
import NewsFooter from "../../../components/News/NewsFooter"
import { mapStores } from "pinia"
import { useAppLanguages } from '@/stores/app-languages'
import { useAppPages } from '@/stores/app-pages'

export default {
  name: 'LoungeNewsV1',
  components: {
    'news-footer': NewsFooter
  },
  mixins: [widgetMixin],
  props: ['widget'],
  data () {
    return {
      news: [],
      news_ready: false
    }
  },
  computed: {
    ...mapStores(useAppLanguages, useAppPages),
    language() {
      return this.appLanguagesStore.activeLanguage
    },
    page() {
      return this.appPagesStore.all.find(p => p.type === 'news' && p.language_id === this.language)
    },
    uniqueId () {
      return this.css_id
    },
    title () {
      return this.widget.values.title
    },
    titleColor () {
      return this.widget.values.title_color
    },
    button () {
      return this.widget.values.button
    },
    icon() {
      return 'icon-star-colored'
    }
  },
  created () {
    if(this.widget.values.news.length > 0) {
      this.news = this.widget.values.news;
      this.news_ready = true;
    }
  },
  methods: {
    dateFormat(date) {
      return Moment(date).format('DD MMM');
    },
    goto(url) {
      window.open(url, '_blank');
    },
    itemImage(n) {
      return n.image != '' ? n.image : typeof n.extra !== 'undefined' && n.extra.image != '' ? n.extra.image : false;
    }
  }
}
</script>

<style lang="sass" type="text/sass" scoped>

.lounge-widget-content
  padding-bottom: 0px
  height: calc(100% - 112px)
  overflow: auto
  .lounge-widget-news-items
    .row
      overflow: hidden
      margin-top: 16px
      background: #FFFFFF
      border-radius: 4px
      margin-left: 5px
      margin-right: 5px

      .image-initials-container
        width: 32px
        height: 32px
        overflow: hidden
        border-radius: 20px
        .initials
          font-size: 16px

      .item-card
        text-align: left
        padding-left: 16px
        padding-top: 16px
        padding-bottom: 16px
        p
          font-size: 15px
          line-height: 20px
          margin-bottom: 0px
          overflow: hidden
          &.item-title
            font-weight: 800
            margin-bottom: 7px
            cursor: pointer
          &.item-content
            font-size: 16px
            line-height: 24px
          &.item-ext-url
            text-decoration: underline
            font-style: italic
            cursor: pointer
            margin-top: 5px
        .item-image
          min-height: 100px
          display: flex
          padding: 20px 12px
          background: #484b4d
          flex-direction: column
          background-size: cover
          background-repeat: no-repeat
          background-position: center
      .reactions
        text-align: left
        padding-bottom: 16px
        font-size: 13px

.lounge-widget-footer
  height: 56px
  padding-top: 12px
  padding-bottom: 12px
  padding-right: 16px
  position: absolute
  bottom: 0px
  width: 100%
  text-align: right

  .goto
    float: right
    font-weight: 500
    font-size: 17px
    line-height: 35px
    cursor: pointer

    .goto-text
      padding-right: 32px

    .goto-icon
      position: absolute
      top: 19px
      right: 16px
      i
        color: inherit
</style>
