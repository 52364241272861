import http from 'src/http'

class TranslationService {
  getTranslations () {
    return new Promise((resolve, reject) => {
      http.get('/translations').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new TranslationService()
