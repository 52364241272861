export default {
  name: 'loadScriptsMixin',
  methods: {
    loadScripts (scripts) {
      if (!scripts.length) {
        return
      }

      let dis = scripts.shift()
      let script = document.createElement('script')
      const scriptSrcAttribute = dis.getAttribute('src')
      if (scriptSrcAttribute) {
        script.src = scriptSrcAttribute
        script.onload = () => {
          this.loadScripts(scripts)
        }
        document.head.appendChild(script)
      } else {
        script.innerText = dis.innerHTML
        document.head.appendChild(script)
        this.loadScripts(scripts)
      }
    }
  }
}
