import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useAppCss = defineStore('appCss', () => {
  const cssState = ref('')
  const loadedState = ref(false)

  const css = computed(() => cssState.value)
  const loaded = computed(() => loadedState.value)

  function load(data) {
    cssState.value = data
  }

  function setLoaded() {
    loadedState.value = true
  }

  return { cssState, loadedState, css, loaded, load, setLoaded }
})