<template>
    <div v-if="show" class="text-center not-your-details">
        <a
          @click="logout()"
          @keyup.enter.prevent="logout()"
          v-html="text"
          tabindex="0"
        >
        </a>
    </div>
</template>

<style lang="sass" type="text/sass" scoped>
.not-your-details
  margin-top: 0
  margin-bottom: 1rem

  a
    cursor: pointer
    font-weight: bold
</style>
<script>
  import { mapStores } from 'pinia'
  import { useApp } from '@/stores/app'
  import { useVisitor } from '@/stores/visitor'
  import { useAppRegistration } from '@/stores/app-registration'
  import { useRoute } from 'vue-router'


  export default {
    setup () {
      const route = useRoute()
      return {
        route
      }
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapStores(useApp, useVisitor, useAppRegistration),
      show () {
        return this.event.registration_code === false && this.visitorData.invite
      },
      text () {
        if (this.translationExists('register_not_your_details_custom') && this.translate('register_not_your_details_custom') != '') {
          return this.translate('register_not_your_details_custom')
        } else {
          return this.event.salutation === 'informal' ? this.translate('register_not_your_details_informal_new') : this.translate('register_not_your_details_new')
        }
      },
      event () {
        return this.appStore.event
      },
      visitorData () {
        return this.visitorStore.data
      }
    },
    methods: {
      logout () {
        this.visitorStore.logout().then(() => {
          this.appRegistrationStore.init()
          this.$router.replace({
            name: this.route.name,
            params: {
              pageId: this.route.params.pageId
            }
          })
        }).catch((err) => {
          window.handle_error(err, 'not your details logout')
        })
      }
    }
  }
</script>
