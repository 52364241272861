<template>
  <div class="page-account">
    <div
      v-if="inited && invite"
      key="member"
      class="container"
    >
      <div class="row">
        <div class="col-lg-4">
          <ImageOrInitials
            class="ImageOrInitials"
            :initials="invite.initials"
            :image="invite.image"
          />
        </div>
        <div class="col-lg-4 details">
          <div class="box">
            <h2 class="mm-detail-title">
              {{ invite.name }}
            </h2>
            <p>{{ invite.function }}<span v-if="invite.function && invite.company">,</span> {{ invite.company }}</p>
            <SocialMediaIcons 
              :social-media-links="invite.icons"
              :owner-name="invite.name"
            />
          </div>
          <Divider
            :hide-for="['lg']"
            :margin="30"
          />
          <div class="ticket box">
            <h2>{{ invite.ticket.title }}</h2>
            <div
              v-for="(field, index) in invite.fields"
              :key="index"
            >
              <p class="fw-bold m-0">
                {{ field.description }}
              </p>
              <p v-if="field.type === 'checkbox' && field.value === '0'">
                {{ translate('field_checkbox_no') }}
              </p>
              <p v-else-if="field.type === 'checkbox' && field.value === '1'">
                {{ translate('field_checkbox_yes') }}
              </p>
              <p v-else>
                {{ field.value }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 text-end">
          <QRCode
            v-if="invite.qrcode"
            class="qrImage"
            :value="invite.qrcode"
            :size="620"
          />
          <div class="buttons">
            <router-link
              v-slot="{ navigate }"
              :to="{ name: 'register'}"
              custom
            >
              <button
                class="btn btn-2 float-end"
                @click="navigate"
                @keypress.enter="navigate"
              >
                {{ translate('my_account_edit_link') }}
              </button>
            </router-link>
            <button
              class="btn btn-1 float-end"
              @click="logout"
            >
              {{ translate('my_account_logout') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      key="guest"
    >
      <login :page="page" />
    </div>
  </div>
</template>

<script>
  // TODO: candidate to remove. Are we still using this component?
  import Login from './Account/Login.vue'
  import { QRCode, Divider } from '@/components/Misc'
  import { ImageOrInitials } from '@/components'
  import SocialMediaIcons from '../../components/SocialMedia/SocialMediaIcons'
  import { mapStores } from 'pinia'
  import { useVisitor } from '@/stores/visitor'

  export default {
    components: {
      Login,
      ImageOrInitials,
      QRCode,
      Divider,
      SocialMediaIcons
    },
    props: ['page'],
    data () {
      return {
        email: '',
        password: '',
        error: ''
      }
    },
    computed: {
      ...mapStores(useVisitor),
      inited () {
        return this.visitorStore.inited
      },
      invite () {
        return this.visitorStore.invite
      }
    },
    methods: {
      logout () {
        this.visitorStore.logout()
      }
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.page-account
  .ImageOrInitials, .qrImage
    max-width: 320px
    margin: 0 auto
  .buttons
    max-width: 320px
    margin: 0 auto
  .box
    text-align: left
    margin-bottom: 30px
    h2
      text-align: left

  @include media-breakpoint-down(lg)
    .box
      text-align: center
      margin: 30px 0px
      h2
        text-align: center

  .placeholder
    width: 100%
    background-color: #999999
    padding-bottom: 100%

  .details
    h2
      font-weight: 600

  .buttons
    button
      width: 100%
      margin-top: 8px
</style>
