import http from 'src/http'

class VisitorService {
  init () {
    return new Promise((resolve, reject) => {
      http.get('/visitor/init').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getInviteFromHash (hash) {
    return new Promise((resolve, reject) => {
      http.post('/visitor/hash', { 'hash': hash }).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error)
      })
    })
  }

  getInviteFromCode (code) {
    return new Promise((resolve, reject) => {
      http.post('/visitor/code', { code: code }).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  twofactor(payloadTwoFa) {
    return new Promise((resolve, reject) => {
      http.post('/visitor/twofactor', payloadTwoFa).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  postTeamCode (code) {
    return new Promise((resolve, reject) => {
      http.post('/visitor/team_code', code).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  setLanguage (language) {
    return new Promise((resolve, reject) => {
      http.post('/visitor/language', { language_id: language }).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  login (email, password) {
    return new Promise((resolve, reject) => {
      http.post('/visitor/login', { 'email': email, 'password': password }).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  }

  loginByHash (hash) {
    return new Promise((resolve, reject) => {
      http.post('/visitor/login_by_hash', { 'hash': hash }).then(function (response) {
        resolve(response.data)
      }).catch(function (error) {
        reject(error.response.data)
      })
    })
  }

  logout () {
    return new Promise((resolve, reject) => {
      http.post('/visitor/logout').then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new VisitorService()
