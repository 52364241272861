<template>
  <div id="lm-wrapper" v-if="ready">
    <!-- desktop view -->
    <div id="lm-desktop" v-if="device == 'desktop'">
      <!-- topbar -->
      <div id="lm-desktop-topbar" :style="`border-color: ${colors(5)}`">
        <div class="row">
          <div class="text-start align-self-center col-lg-3">
            <div id="logo">
              <img class="logo-img" alt="Logo">
            </div>
          </div>
          <div class="text-center align-self-center col-lg-6">
            <h2 v-html="programItem.title"></h2>
            <a href="javascript:;" v-on:click="goBackToProgram()" class="return-link" role="link" tabindex="0" @keypress.enter="goBackToProgram()"><span class="arrow" :style="`background-color: ${backgroundColor}`"></span>{{ translate('live_module_go_back') }}</a>
          </div>
          <div class="text-end col-lg-3" style="line-height: 90px;">
            <div style="margin-right: 100px">&nbsp;</div>
          </div>
        </div>
      </div>
      <div id="lm-session-rating-container" v-if="this.showRating">
        <rating :nextAction="this[this.actionAfterRating]" :streamHash="programItem.stream.hash" v-if="this.access_rating" />
      </div>
      <div id="lm-desktop-content-container" v-else-if="access_allowed">
        <!-- main content -->
        <div id="lm-desktop-main-content" :style="'float: left; width: ' + ((this.access_chat || this.access_voting || this.access_qa)  ? '80%' : '100%')" >
          <div class="image" v-if="this.program_item.background" :style="`background-image: url('${this.program_item.background}'); overflow: hidden;`"><img role="presentation" alt="" :src="`${this.program_item.background}`" style="opacity: 0; max-width: 100%;">
          </div>
          <div class="lm-desktop-stream">
            <stream :stream="programItem.stream" v-if="this.access_stream" />
          </div>
        </div>
        <!-- column right content -->
        <div class="lm-desktop-column-right" v-if="this.access_chat || this.access_voting || this.access_qa">
          <nav>
            <div class="nav nav-tabs nav-fill flex-nowrap" id="nav-tab" role="tablist">
              <a
                v-if="this.access_chat"
                :class="'lm-mobile-tab-nav nav-item nav-link' + (chat_tab ? ' active' : '') + (disabledTab ? ' disabled' : '')"
                v-on:click="switchTab('chat_tab')"
                data-toggle="tab"
                role="tab"
                :aria-label="translate('chat')"
              >
                <i class="icon-interaction-chat" aria-hidden="true"></i>
              </a>
              <a
                v-if="this.access_voting"
                :class="'lm-mobile-tab-nav nav-item nav-link' + (voting_tab ? ' active' : '') + (disabledTab ? ' disabled' : '')"
                v-on:click="switchTab('voting_tab')"
                data-toggle="tab"
                role="tab"
                :aria-label="translate('voting')"
              >
                <i class="icon-interaction-voting" aria-hidden="true"></i>
              </a>
              <a
                v-if="this.access_qa"
                :class="'lm-mobile-tab-nav nav-item nav-link' + (qa_tab ? ' active' : '') + (disabledTab ? ' disabled' : '')"
                v-on:click="switchTab('qa_tab')"
                data-toggle="tab"
                role="tab"
                :aria-label="translate('q_and_a')"
                style="border-right: 1px solid #fff !important"
              >
                <i class="icon-interaction-qa" aria-hidden="true"></i>
              </a>
            </div>
          </nav>
          <div class="column-right-container">
            <div class="column-right-content" v-show="chat_tab">
              <chat :programItem="programItem" :preview="disabledTab" v-if="this.access_chat" />
            </div>
            <div class="column-right-content" :style="`background-color: ${colors(6)}`" v-show="qa_tab">
              <qa-form :qa="programItem.qa" />
            </div>
            <div class="column-right-content" :style="`background-color: ${colors(6)}`" v-show="voting_tab">
              <div v-if="!hasJoinedVoting && device == 'desktop'">
                <div class="voting-tab-action">
                    <div
                      v-if="anonymous_voting"
                      v-html="translate('interaction_voting_is_anonymous')"
                      class="px-5 py-2"
                    ></div>
                    <button
                      type="button"
                      class="btn btn-1 voting-tab-action-join"
                      @click="joinVoting()"
                      >{{ translate('join_voting') }}</button>
                </div>
              </div>
              <div v-else >
                 <voting :voting="programItem.voting" :device="device" v-if="this.access_voting" />
              </div>
            </div>
          </div>
        </div>
        <!-- next program item snackbar -->
        <div v-if="this.show_next_program_item && this.program_item.next_program_item" id="lm-dekstop-next-item" style="margin: 0 auto; max-width: 600px; min-width: 600px; min-height: 100px; position: absolute; bottom: 0px; left: calc(50% - 300px);">
          <div style="width: 380px; float: left">
            <h3>{{ translate('live_module_go_to_next_session_title') }}</h3>
            <p v-html="this.nextProgramItemText"></p>
          </div>
          <div style="width: 180px; float: left">
            <button v-on:click="joinNext()" class="btn btn-3" style="margin-left: 30px; margin-top: 30px;">{{ translate('live_module_go_to_next_session_button') }}</button>
          </div>
          <button type="button" class="close" aria-label="Close" v-on:click="hideNextProgramItemPopup">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div v-else id="lm-dekstop-no-access-container">
        <div  class="container inner">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 mm-html" v-html="translate('live_module_no_access')"></div>
            <div class="col-lg-3"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile view -->
    <div class="lm-mobile" v-else>
      <!-- topbar -->
      <div id="lm-mobile-topbar">
        <span v-on:click="goBackToProgram()" role="link" tabindex="0" @keypress.enter="goBackToProgram()" class="icon-arrow-left lm-mobile-topbar-back"></span>
        <p id="lm-mobile-title" v-html="programItem.title"></p>
      </div>
      <div id="lm-mobile-content-container" v-if="access_allowed">
        <!-- tab to switch -->
        <div class="row" v-if="this.access_chat || this.access_voting || this.access_qa">
          <div class="col-lg-12 lm-mobile-tab">
            <nav>
              <div class="nav nav-tabs nav-fill flex-nowrap" id="nav-tab" role="tablist">
                <a
                  :class="'lm-mobile-tab-nav nav-item nav-link' + (livestream_tab ? ' active' : '')"
                  v-on:click="switchTab('livestream_tab')"
                  data-toggle="tab"
                  role="tab"
                  :aria-label="translate('source')"
                >
                  <i class="icon-interaction-livestream" aria-hidden="true"></i>
                </a>
                <a
                  v-if="this.access_chat"
                  :class="'lm-mobile-tab-nav nav-item nav-link' + (chatMobileTab ? ' active' : '')"
                  v-on:click="switchTab('chat_tab')"
                  data-toggle="tab"
                  role="tab"
                  :aria-label="translate('chat')"
                >
                  <i class="icon-interaction-chat" aria-hidden="true"></i></a>
                <a
                  v-if="this.access_voting"
                  :class="'lm-mobile-tab-nav nav-item nav-link' + (voting_tab ? ' active' : '')"
                  v-on:click="switchTab('voting_tab')"
                  data-toggle="tab"
                  role="tab"
                  :aria-label="translate('voting')"
                  style="border-right: 1px solid #fff !important"
                >
                  <i class="icon-interaction-voting" aria-hidden="true"></i></a>
                <a
                  v-if="this.access_qa"
                  :class="'lm-mobile-tab-nav nav-item nav-link' + (qa_tab ? ' active' : '')"
                  v-on:click="switchTab('qa_tab')"
                  data-toggle="tab"
                  role="tab"
                  :aria-label="translate('q_and_a')"
                >
                  <i class="icon-interaction-qa" aria-hidden="true"></i>
                </a>
              </div>
            </nav>
          </div>
        </div>
        <!-- main content -->
        <div id="lm-mobile-main-content" class="container inner" v-if="this.access_stream || this.access_voting || this.access_qa">
          <!-- Livestream -->
          <div class="row" v-show="livestream_tab">
            <div class="col-lg-12" v-if="this.access_stream">
                <stream :stream="programItem.stream" />
            </div>
          </div>
          <!-- Chat -->
          <div v-show="chatMobileTab" style="height: 100%">
            <div v-if="this.access_chat" style="height: 100%" class="chat-mobile-container">
              <chat :programItem="programItem" v-if="this.access_chat" />
            </div>
          </div>
          <!-- Q&A -->
          <div class="row" v-show="qa_tab">
            <div class="col-lg-12" v-if="this.access_qa">
              <qa-form :qa="programItem.qa" />
            </div>
          </div>
          <!-- voting -->
          <div class="row" v-show="voting_tab">
            <div class="col-lg-12" v-if="this.access_voting">
              <voting :voting="programItem.voting" :device="device" />
            </div>
          </div>
        </div>
        <div v-else class="container inner">
          <br />
          <h3 class="mt">{{ translate('live_module_no_mobile_access') }}</h3>
          <router-link :to="{ name: 'home' }" class="btn btn-3" style="margin-top: 30px;">{{ translate('live_module_go_back') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Chat from '../components/Chat/Chat.vue'
    import Voting from '../components/Voting/Voting.vue'
    import Stream from '../components/Stream/Stream.vue'
    import Qa from '../components/QA/Qa.vue'
    import StreamRating from '../components/Stream/StreamRating.vue'
    import QaAccess from '../components/QA/QaAccess.vue'
    import moment from 'moment'
    import { mapStores } from 'pinia'
    import { useAppTemplate } from '@/stores/app-template'
    import { useApp } from '@/stores/app'
    import { useAppLiveModule } from '@/stores/app-liveModule'
    import { useAppUi } from '@/stores/app-ui'
    import { useAppLanguages } from '@/stores/app-languages'
    import { useAppPages } from '@/stores/app-pages'

    export default {
      data () {
        return {
          // Loaded?
          ready: false,
          // Access
          access_allowed: false,
          access_chat: false,
          access_voting: false,
          access_stream: false,
          access_qa: false,
          access_rating: false,
          // Modules
          has_stream: false,
          has_chat: false,
          has_voting: false,
          has_qa: false,
          // Program item
          program_item: null,
          show_next_program_item: false,
          // Next event time
          timer: '',
          qa_tab: false,
          voting_tab: false,
          chat_tab: true, // default
          livestream_tab: true, // default
          // Session rating
          showRating: false,
          actionAfterRating: '',
          // Keep alive
          keep_alive_interval: null,
          hasJoinedVoting: false,
          // Preview mode
          disabledTab: false,
          anonymous_voting: false,
          livemodule_access_from: '',
          interactionAllowed: [
            'qa',
            'voting',
          ]
        }
      },
      computed: {
        ...mapStores(useAppTemplate, useApp, useAppLiveModule, useAppUi, useAppLanguages, useAppPages),
        event () {
          return this.appStore.event
        },
        programItem () {
          return this.appLiveModuleStore.getCurrentItem
        },
        device() {
          const breakPoint = this.appUiStore.breakingPoint
          return breakPoint.size <= 3 ? 'mobile' : 'desktop';
        },
        chatMobileTab() {
          return this.chat_tab && !this.livestream_tab;
        },
        programPage() {
          const lang = this.appLanguagesStore.activeLanguage
          return this.appPagesStore.all.find(p => p.type === 'program' && p.language_id === lang)
        },
        returnPath() {
          return '/page/' + this.programPage.page_id ;
        },
        nextProgramItemTimestamp() {
          var next_program_item_datetime = this.getDateTimeObject(this.program_item.next_program_item.date_start,this.program_item.next_program_item.time_start);
          return next_program_item_datetime;
        },
        nextProgramItemText() {
            return this.translate('live_module_go_to_next_session_body', {session: this.program_item.next_program_item.title});
        },
        currentRating() {
          return this.appLiveModuleStore.getCurrentRating
        },
        backgroundColor() {
          return this.appTemplateStore.get('topbar.color')
        }
      },
      created () {
        if(this.$route.params.hash){
          this.initPreview()
        }else{
          this.initLiveModule()
        }
      },
      mounted () {

        window.addEventListener('beforeunload', this.leaving)
      },
      beforeRouteLeave (to, from, next) {
        this.leaveStream();
        this.clearKeepAlive();
        next();
      },
      beforeUnmount () {
        this.clearKeepAlive();
        this.cancelPopUpWatcher()
      },
      methods: {
        getDateTimeObject(dateString, timeString) {
          var dateSplit = dateString.split("-");
          var timeSplit = timeString.split(":");
          return new Date(
            dateSplit[2],
            dateSplit[1] - 1,
            dateSplit[0],
            timeSplit[0],
            timeSplit[1]
          );
        },
        showNextProgramItemPopup () {
          this.show_next_program_item = true;
        },
        cancelPopUpWatcher () {
          clearTimeout(this.timer)
        },
        hideNextProgramItemPopup() {
          this.show_next_program_item = false;
        },
        leaveStream() {
          if(this.programItem.stream !== null) {
            this.appLiveModuleStore.leaveStream(this.programItem.stream.hash).then((result) => {
              console.log(result);
            });
          }
        },
        keepAlive(action = null) {

        },
        clearKeepAlive() {
          if(this.keep_alive_interval) {
            clearInterval(this.keep_alive_interval);
          }
        },
        leaving: function (event) {
          this.leaveStream()
          this.clearKeepAlive();
        },
        joinNext() {
          if(this.access_rating === true && this.device === 'desktop') {
            if(this.currentRating.length === 0) {
              this.showRating = true;
              this.actionAfterRating = 'joinNext';
            }else {
              let rating;
              for (rating of this.currentRating) {
                if(rating.hash === this.programItem.stream.hash) {
                  this.joinNextExecute();
                }
              }
              this.showRating = true;
              this.actionAfterRating = 'joinNext';
            }
          }else{
            this.joinNextExecute();
          }
        },
        joinNextExecute() {
          if(this.program_item.next_program_item.stream.platform === 'url'){
            this.appLiveModuleStore.connectToStream(this.program_item.next_program_item.stream.hash).then((result) => {
            })
            window.open(this.program_item.next_program_item.stream.platform_data.stream_url,'_blank');
          } else {
            this.$router.push({ name: 'live-module', params: { pageId: this.program_item.next_program_item.page_id }});
          }
        },
        goBackToProgram() {
          if(this.access_rating === true && this.device === 'desktop') {
            if(this.currentRating.length === 0) {
              this.showRating = true;
              this.actionAfterRating = 'goBackToProgram';
            }else {
              let rating;
              for(rating of this.currentRating) {
                if(rating.hash === this.programItem.stream.hash) {
                  this.$router.push({ name: 'page', params: { pageId: this.programPage.page_id }});
                }
              }
              this.showRating = true;
              this.actionAfterRating = 'goBackToProgram';
            }
          }else{
            this.$router.push({ name: 'page', params: { pageId: this.programPage.page_id }});
          }
        },
        switchTab(tab) {
          let tabs = ['qa_tab','voting_tab','livestream_tab','chat_tab'];
          tabs.forEach(t => {
            this[t] = false;
            if (t === tab && this.tabHasAccess(tab)) {
              this[tab] = true;
              localStorage.setItem(`liveModuleTab${this.program_item.page_id}`, tab)
            }
          })
        },
        tabHasAccess(tab) {
          if(tab === 'qa_tab') {
            return this.access_qa;
          } else if(tab === 'voting_tab') {
            return this.access_voting;
          } else if(tab === 'chat_tab') {
            return this.access_chat;
          } else if(tab === 'livestream_tab') {
            return this.access_stream;
          }
        },
        getRandomKeepAliveInt() {
          let min = 200000;
          let max = 300000;
          let rand = Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
          return rand;
        },
        getRandomKeepNextSessionLoadbalancedInt() {
          let min = 0;
          let max = 30000;
          let rand = Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
          console.log(rand);
          return rand;
        },
        joinVoting() {
          this.hasJoinedVoting = true;
        },
        initLiveModule() {
          new Promise((resolve) => {
            this.appLiveModuleStore.setCurrentItem(this.$route).then((result) => {
              this.ready = true;
              this.program_item = result;
              this.access_allowed = result.access_allowed;
              if(result.chat) {
                this.has_chat = true;
                this.access_chat = result.chat.access_allowed;
              }
              if(result.voting) {
                this.has_voting = true;
                this.access_voting = result.voting.access_allowed;
                this.anonymous_voting = result.voting.anonymous;
                if(!this.access_chat && this.device === 'desktop') {
                  this.switchTab('voting_tab');
                }
              }
              if(result.qa) {
                this.has_qa = true;
                this.access_qa = result.qa.access_allowed;
                if(!this.access_chat && !this.access_voting && this.device === 'desktop') {
                  this.switchTab('qa_tab');
                }
              }
              if(result.stream) {
                this.has_stream = true;
                this.access_stream = result.stream.access_allowed;
                this.access_rating = result.stream.rating === 'yes';
                this.keepAlive('start');
              }

              if(this.program_item.next_program_item) {

                const remainingTime = moment(this.nextProgramItemTimestamp).diff(moment(Date.now()), 'milliseconds')
                if (remainingTime > 120000) {
                  this.timer = setTimeout(
                    () => this.showNextProgramItemPopup(), remainingTime - (120000 - this.getRandomKeepNextSessionLoadbalancedInt())
                  )
                }
              }
              if(this.livemodule_access_from){
                this.switchTab(this.livemodule_access_from + '_tab')
              }

              const interaction = this.$route.query.interaction

              if (interaction && this.interactionAllowed.includes(interaction)) {
                this.switchTab(interaction + '_tab')
              } else {
                const tab = localStorage.getItem(`liveModuleTab${this.program_item.page_id}`)
                if (tab) { 
                  this.switchTab(tab)
                }
              }
            }, (err) => {
              window.handle_error(err, 'page init')
              this.ready = true;
            }).catch(() => {
              this.ready = true;
              this.appStore.set404({ route: this.$route })
            })
          })
        },
        initPreview(){
          new Promise((resolve) => {
            this.appLiveModuleStore.setCurrentItem(this.$route).then((result) => {
              this.ready = true;

              if(result.stream && this.$route.params.hash === result.stream.hash) {
                this.access_allowed = true;
                this.program_item = result;

                this.has_stream = true;
                this.access_stream = result.stream.access_allowed;
                this.access_rating = result.stream.rating === 'yes';

                if (result.chat) {
                  this.has_chat = true;
                  this.access_chat = true; // true just to show the disabled tab
                }
                if (result.voting) {
                  this.has_voting = true;
                  this.access_voting = true; // true just to show the disabled tab
                  this.anonymous_voting = result.voting.anonymous;
                }
                if (result.qa) {
                  this.has_qa = true;
                  this.access_qa = true; // true just to show the disabled tab
                }

                if(result.workshop_field) {
                  this.access_stream = true
                  this.access_chat = true;
                  this.access_voting = true;
                  this.access_qa = true;
                }

                this.disabledTab = true; // always disabled tabs when preview

              } else {
                this.appStore.set404({ route: this.$route })
              }
            }, (err) => {
              window.handle_error(err, 'page init')
              this.ready = true;
            }).catch(() => {
              this.ready = true;
              this.appStore.set404({ route: this.$route })
            })
          })
        }
      },
      components: {
        'chat': Chat,
        'stream' : Stream,
        'qaAccess' : QaAccess,
        'qa-form' : Qa,
        'voting': Voting,
        'rating': StreamRating
      }
    }
</script>

<style lang="sass" type="text/sass" scoped>

.chat-mobile-container
  padding-bottom: 64px

#lm-wrapper
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  z-index: 9000

#lm-desktop
  .container
    &.inner
      width: 100%
      @media (min-width: 1280px)
        max-width: 1240px
      @media (min-width: 1440px)
        max-width: 1360px
      @media (min-width: 1600px)
        max-width: 1560px

#lm-desktop-topbar
  width: 100%
  height: 91px
  position: fixed
  top: 0
  left: 0
  z-index: 9001
  border-bottom-width: 1px
  border-bottom-style: solid

  #logo
    margin-left: 100px
    cursor: pointer
    width: 100%

  h2
    font-size: 1.5rem
    padding-left: 0px
    padding: 0px
    text-align: center
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
    max-width: 100%
    color: inherit

  .arrow
    background: inherit
    height: 2px
    width: 31px
    margin: 0 auto
    position: relative
    cursor: pointer
    display: inline-block
    margin-right: 10px
    margin-bottom: 4px

    &:before,
    &:after
      content: ""
      background: inherit
      position: absolute
      height: 2px
      width: 15px

    &:before
      right: 20px
      bottom: -5px
      transform: rotate(-135deg)

    &:after
      right: 20px
      top: -5px
      transform: rotate(135deg)

#lm-session-rating-container
  position: absolute
  width: 100%
  padding-top: 10%
  margin-top: 91px
  text-align: center

#lm-mobile-topbar
  width: 100%
  height: 64px
  display: flex
  align-items: center
  border-bottom-width: 1px
  border-bottom-style: solid

@include media-breakpoint-up(lg)
  div #logo
    height: ($topbar-height-large)-26px
  .logo-img
    max-height: ($topbar-height-large)-26px
@include media-breakpoint-down(lg)
  div#logo
    height: ($topbar-height-small)-20px
  #logo.logo-align-top
    height: 90px !important
    background-position-y: top !important
    background-size: auto 76px !important
  .logo-img
    max-height: ($topbar-height-small)-20px

#lm-desktop-content-container
  display: flex
  margin-top: 91px

  #lm-dekstop-no-access-container
    margin-top: 155px

  #lm-desktop-main-content
    .image
      max-height: 400px
      position: absolute
      opacity: 0.7

  .lm-desktop-stream
    padding: 0px
    width: 100%
    height: calc(100vh - 91px)
    float: left
    position: relative

  .lm-desktop-voting
    padding: 0px
    height: 180px
    float: left
    width: 100%
    border-top:  1px solid

  .lm-desktop-column-right
    padding: 0px
    border: solid 0px
    height: 80%
    float: right
    width: 20%
    position: fixed
    right: 0px
    .column-right-container
      height: calc(100vh - 141px)
      .column-right-content
        height: 100%
        position: relative


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
  background-color: transparent
  border-color: transparent transparent #f3f3f3
  border-bottom: 4px solid !important
  font-size: 20px
  font-weight: bold

.nav-tabs .nav-link
  border: 1px solid transparent
  border-top-left-radius: .25rem
  border-top-right-radius: .25rem
  font-size: 20px
  display: flex
  align-items: center
  justify-content: center
  i
    color: inherit
    font-size: 34px

.lm-mobile
  height: 100%
  position: relative
  #lm-mobile-topbar
    #lm-mobile-title
      white-space: nowrap
      text-overflow: ellipsis
      text-align: center
      width: 100%
      display: block
      overflow: hidden
      padding-left: 50px
      padding-right: 50px
      margin-top: 18px
      font-weight: bold
    .lm-mobile-topbar-back
      position: absolute
      left: 6px
      top: 19px
      font-size: 24px
      color: inherit
      z-index: 100
  #lm-mobile-content-container
    height: calc(100vh - 64px)

#lm-mobile-main-content
  margin-top: 20px
  height: calc(100vh - 134px)

#lm-mobile-footer
  position: fixed
  bottom: 0px
  opacity: 0.6
  width: 100%
  margin-top: 10px
  margin-bottom: 10px
  z-index: -1
  p
    text-align: center
    margin-bottom: 0px
    font-size: 90%

.voting-tab-action
  padding-top: 50px
  text-align: center
  .voting-tab-action-join
    height: 100%
    width: 80%

/* IPAD PRO CUSTOM STYLING OF HEIGHT */
@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none)
  .lm-desktop-column-right
    height: calc(100vh - 156px)
</style>
