<script setup>
import { computed, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ValidationErrors from './Common/ValidationErrors'
import LabelField from './Common/LabelField'
import ValidationIcon from './Common/ValidationIcon'

const props = defineProps({
  field: {
    default: {},
    type: Object
  },
  validationErrors: {
    default: {},
    type: Object
  }
})

const emit = defineEmits(['setValue'])

const data = reactive({
  fieldData: {},
  checkboxValue: false
})

onMounted(() => {
  data.fieldData = { ...props.field }
  // TODO: temporary fix. The backend returns "" or "1" as a value for checkbox instead of true/false
  data.checkboxValue = data.fieldData.value ? Boolean(Number(data.fieldData.value)) : false
})

const rules = computed(() => ({
  checkboxValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}))
const v$ = useVuelidate(rules, data)

const checkboxDescription = computed(() => {
  return data.fieldData.type_config && data.fieldData.type_config.text ? data.fieldData.type_config.text : null
})

watch(() => data.checkboxValue, () => {
  emit('setValue', {
    value: data.checkboxValue,
    fieldHandle: data.fieldData.handle
  })
})
</script>

<template>
  <div v-if="data.fieldData && v$.checkboxValue" class="mm-custom-form">
    <div class="form-group" v-validation-class="{ responseErrors: validationErrors, vuelidateField: v$.checkboxValue, field: data.fieldData, fieldValue: data.checkboxValue }">
      <LabelField
        :required="data.fieldData.required"
        :forTag="data.fieldData.handle"
        :text="data.fieldData.description"
      ></LabelField>
      <div class="span-input">
        <div class="form-check mm-form-checkbox">
          <input 
            ref="checkbox"
            class="form-check-input"
            :id="data.fieldData.handle"
            :name="data.fieldData.handle"
            type="checkbox"
            :disabled="data.fieldData.disabled"
            v-model="data.checkboxValue"
            @blur="v$.checkboxValue.$touch"
          >
          <label :for="data.fieldData.handle" class="form-check-label" v-html="checkboxDescription"></label>
        </div>
        <ValidationIcon></ValidationIcon>
      </div>
      <ValidationErrors :validationErrors="validationErrors" :fieldHandle="data.fieldData.handle"></ValidationErrors>
    </div>
  </div>
</template>

<style scoped>

</style>