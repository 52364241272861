<template>
    <div class="widget-map">
        <div class="map-container">
            <div :id="mapId" class="map"></div>
        </div>
    </div>
</template>
<style lang="sass" type="text/sass" scoped>
.widget-map
  padding: 0

.map-container
  width: 100%

  .map
    width: 100%
    height: 100%
</style>
<script>
  import widgetMixin from '../../../mixins/widgetMixin'

  var mapsapi = require('google-maps-api')(process.env.ANON_CONFIG_1)

  export default {
    name: 'map-v1',
    mixins: [widgetMixin],
    computed: {
      locations () {
        return this.widget.values.locations
      },
      zoomFactor () {
        return this.widget.values.zoomFactor
      },
      zoomControl () {
        return this.widget.values.zoomControl
      },
      draggable () {
        return this.widget.values.draggable
      },
      streetViewControl () {
        return this.widget.values.streetViewControl
      },
      mapId () {
        return this.css_id + '-map'
      }
    },
    mounted () {
      mapsapi().then((maps) => {
        // use the google.maps object as you please
        let place = new maps.LatLng(this.locations[0].latitude, this.locations[0].longitude)

        let map = new maps.Map(document.getElementById(this.mapId), {
          center: place,
          zoom: this.zoomFactor,
          zoomControl: this.zoomControl,
          disableDoubleClickZoom: true,
          mapTypeControl: false,
          draggable: this.draggable,
          scrollwheel: false,
          scaleControl: false,
          streetViewControl: this.streetViewControl,
          styles: this.widget.values.mapStyles
        })

        // creeer een nieuw bounds object om alle locaties in op te slaan
        let bounds = new maps.LatLngBounds()
        for (let key in this.locations) {
          let location = this.locations[key]
          let latlng = new maps.LatLng(location.latitude, location.longitude)

          if (this.locations.length > 1) {
            bounds.extend(latlng)
            map.fitBounds(bounds)
          }

          /* eslint-disable no-new */
          let marker = new maps.Marker({
            position: latlng,
            map: map,
            title: location.title
          })

          maps.event.addListener(marker, 'click', function () {
            window.open(`https://www.google.com/maps/search/?api=1&query=${latlng.lat()},${latlng.lng()}`, '_blank')
          })
        }
      })
    }
  }
</script>
