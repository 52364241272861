<template>
  <div class="page-flex mm-page">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-8">
          <transition name="fade" mode="out-in" :duration="300">
            <div>
              <div key="a" class="mm-html content row" v-if="!isLoadingStatus && hasError">
                {{ translate('eventsite_payment_not_successful') }}
              </div>
              <div key="b" class="mm-html content row" v-if="isLoadingStatus">
                {{ translate('eventsite_payment_processing') }}
              </div>
              <div key="d" class="mm-html content row" v-if="!isLoadingStatus && !hasError" v-html="completeMessage"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import renderMustache from 'src/lib/mustache'
  import { mapStores } from 'pinia'
  import { useApp } from '@/stores/app'
  import { useVisitor } from '@/stores/visitor'
  import { useAppPayment } from '@/stores/app-payment'
  import { useAppRegistration } from '@/stores/app-registration'

  export default {
    name: 'complete',
    data () {
      return {
        isLoadingStatus: true,
        hasError: false,
        completeMessage: "",
        errorCodes: [
          'Authorised',
          'Cancelled',
          'Pending',
          'Received',
          'Refused'
        ]
      }
    },
    computed: {
      ...mapStores(useApp, useVisitor, useAppPayment, useAppRegistration),
      event() {
        return this.appStore.event
      },
      visitor() {
        return this.visitorStore.data
      },
      registration() {
        return this.appRegistrationStore.registration
      },
      textRegistrationSuccess() {
        const email = {
          email: this.registration && this.registration.email ? this.registration.email : ""
        }

        return this.translationExists('text_registration_success') ?
          this.translate('text_registration_success', email) :
          renderMustache(this.event.text_registration_success, email)
      },
      purchaseCompleteError() {
        return this.translate('purchase_complete_error', { uuid: this.visitor.id })
      },
      purchaseCompleteCancelled() {
        return this.translate('purchase_complete_cancelled', { uuid: this.visitor.id })
      },
      paymentResponse() {
        return this.appPaymentStore.paymentResponse
      },
      redirectResult() {
        return this.appPaymentStore.redirectResult
      }
    },
    mounted() {
      if (!this.paymentResponse) {
        this.$router.push({ name: 'home' })
      } else {
        this.completePayment()
      }
    },
    methods: {
      cancelRefusePayment(payment) {
        this.appPaymentStore.cancelOrRefused(payment)
      },
      restartSessionData() {
        this.appPaymentStore.restartSessionData()
      },
      setLoading(status) {
        setTimeout(() => {
          this.isLoadingStatus = status
        }, 800)
      },
      getCancelledOrRefusedPayload() {
        return {
          resultCode: this.paymentResponse.resultCode,
          redirectResult: this.redirectResult
        }
      },
      completePayment() {
        switch (this.paymentResponse.resultCode) {
          case "Authorised":
            this.hasError = false
            this.completeMessage = this.textRegistrationSuccess
            break;
          case "Pending":
          case "Received":
            this.hasError = false
            break;
          case "Refused":
            if (this.redirectResult) {
              this.cancelRefusePayment(this.getCancelledOrRefusedPayload())
            }
            this.hasError = true
            this.completeMessage = this.purchaseCompleteError
            break;
          case "Cancelled":
            if (this.redirectResult) {
              this.cancelRefusePayment(this.getCancelledOrRefusedPayload())
            }
            this.hasError = false
            this.completeMessage = this.purchaseCompleteCancelled
            break;
          default:
            const errorMessage = `Purchase status "${this.paymentResponse.resultCode}" is not any of: ${this.errorCodes.join(',')}`
            this.completeMessage = this.purchaseCompleteError
            this.hasError = true
            window.handle_error(errorMessage, 'purchase/complete')
            break;
        }

        this.restartSessionData()
        localStorage.removeItem('sessionData')
        localStorage.removeItem('sessionDataId')

        this.setLoading(false)
      }
    }
  }
</script>

<style lang="sass" type="text/sass">
.page-flex
  padding-top: 100px
</style>
