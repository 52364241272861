<script setup>
import { computed, reactive, onMounted, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import ValidationErrors from "./Common/ValidationErrors";
import LabelField from "./Common/LabelField";
import FileUpload from "primevue/fileupload";
import { useTranslations } from "@/composables/translations";

const { translate } = useTranslations();
const props = defineProps({
  field: {
    default: () => {},
    type: Object,
  },
  validationErrors: {
    default: () => {},
    type: Object,
  },
});

const emit = defineEmits(["setValue"]);

const data = reactive({
  fieldData: {},
  fileValue: null,
});

onMounted(() => {
  data.fieldData = { ...props.field };
  data.fileValue = data.fieldData.value ?? null; //Check
});

const rules = computed(() => ({
  fileValue: {
    requiredIfFieldRequired: requiredIf(data.fieldData.required),
  },
}));
const v$ = useVuelidate(rules, data);

const accept = computed(() => {
  if (data.fieldData.accept) {
    return data.fieldData.accept.join(",");
  }
  return "";
});

watch(
  () => data.fileValue,
  (newValue, oldValue) => {
    emit("setValue", {
      value: data.fileValue,
      oldValue: oldValue,
      fieldHandle: data.fieldData.handle,
    });
  }
);

const customBase64Uploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = function () {
        const base64data = reader.result;
        data.fileValue = {
          filename: file.name,
          type: file.type,
          data: base64data
        };
    };
};
</script>

<template>
  <div
    v-if="data.fieldData && v$.fileValue"
    class="mm-custom-form"
  >
    <div
      v-validation-class="{
        responseErrors: validationErrors,
        vuelidateField: v$.fileValue,
        field: data.fieldData,
        fieldValue: data.fileValue,
      }"
      class="form-group"
    >
      <LabelField
        :required="data.fieldData.required"
        :for-tag="data.fieldData.handle"
        :text="data.fieldData.description"
      />

      <p class="mb-0">
        {{ data.fieldData.file_field_description }}
      </p>

      <div
        v-if="data.fieldData.value && data.fieldData.value === data.fileValue"
        class="d-flex gap-2 mt-3 flex-column align-items-start"
      >
        <div
          class="pt-1 pb-1 ps-2 pe-2 border rounded-1 mb-2 mw-100"
          :style="`border: 1px solid ${colors(5)} !important; background-color: ${colors(6)} !important`"
        >
          <p
            class="mb-0 text-truncate overflow-hidden"
            :style="`color: ${colors(7)} !important`"
          >
            {{ data.fieldData.value.filename }}
          </p>
        </div>
        <button
          class="btn btn-2"
          @click="data.fileValue = null"
        >
          {{ translate("remove_existing_file") }}
        </button>
      </div>

      <div v-else>
        <p class="fst-italic">
          {{ data.fieldData.allowed_files_description }}
        </p>

        <FileUpload
          customUpload 
          ref="fileupload"
          v-model="data.fileValue"
          class="file-upload-file-field"
          :pt="{
            input: {
              class: 'd-none',
            },
            root: {
              class: 'd-flex flex-column-reverse',
            },
            buttonbar: {
              class: 'gap-2 d-flex',
            },
            cancelButton: {
              root: {
                class: 'btn btn-2 d-flex align-items-center',
              },
            },
            chooseButton: {
              class: `btn btn-2 d-flex align-items-center ${data.fileValue ? 'd-none' : ''}`,
            },
            chooseButtonLabel: {
              class: 'ps-1',
            },
            thumbnail: {
              style: 'd-none',
            },
            content: {
              class: 'd-flex',
            },
          }"
          :accept="accept"
          :max-file-size="26214400"
          :custom-upload="true"
          :multiple="false"
          mode="advanced"
          :show-upload-button="false"
          name="demo[]"
          :show-cancel-button="!!data.fileValue"
          :choose-label="translate('choose_file')"
          :cancel-label="translate('remove_existing_file')"
          @select="customBase64Uploader"
          @clear="data.fileValue = null"
          @remove="data.fileValue = null"
        >
          <template #content="{messages}" >
            <div
              v-if="data.fileValue"
              class="pt-1 pb-1 ps-2 pe-2 d-flex border rounded-1 mb-3 mw-100"
              :style="`border: 1px solid ${colors(5)} !important; background-color: ${colors(6)} !important`"
            >
              <p
                class="mb-0 text-truncate overflow-hidden"
                :style="`color: ${colors(7)} !important`"
              >
                {{ data.fileValue.filename }}
              </p>
            </div>
            <div v-else-if="messages">
              <p v-for="message in messages" :key="message" class="text-danger">
                {{ message }}
              </p>
            </div>
          </template>
          <template #cancelicon>
           <div></div>
          </template>
        </FileUpload>
        <ValidationErrors
          :validation-errors="validationErrors"
          :field-handle="data.fieldData.handle"
        />
      </div>
    </div>
  </div>
</template>
