<template>
    <div>
        <div class="page-video mm-page" v-if="!details || numVids === 1">
            <div class="container">
              <div class="row content mm-html" v-if="page.content" v-html="page.content"></div>
            </div>
            <VideoOverview v-if="!details" :videos="navigationObjects"></VideoOverview>
        </div>
        <div v-if="details" class="page-video-detail">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8">
                        <div class="video-wrapper video-wrapper-16-9">
                            <div ref="youtube-player" id="youtube-player" v-if="details.type === 'youtube'"></div>
                            <div ref="vimeo-player" id="vimeo-player" v-if="details.type === 'vimeo'" :data-vimeo-id="details.value"></div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-8">
                        <div class="video-title h3">{{ details.title }}</div>
                        <p>{{ details.description }}</p>
                    </div>
                    <div class="col-12 col-lg-8">
                        <hr/>
                    </div>
                </div>

                <detail-navigation v-if="page.videos.length > 1"
                                   :indexRoute="{ name: 'page', params: { pageId: page.page_id }}"
                                   :nextRoute="nextRoute"
                                   :previousRoute="previousRoute">
                </detail-navigation>
            </div>
        </div>
    </div>
</template>

<script>
  import detailNavigationMixin from '../../mixins/detailNavigationMixin'
  import YouTubePlayer from 'youtube-player'
  import Vimeo from '@vimeo/player'
  import VideoOverview from "../../components/Video/VideoOverview"

  export default {
    components: {
      VideoOverview
    },
    props: [
      'page'
    ],
    mixins: [
      detailNavigationMixin
    ],
    data () {
      return {
        vimeoPlayer: Object
      }
    },
    methods: {
      initVideo () {
        this.$nextTick(() => {
          if (this.details) {
            if (this.details.type === 'youtube' && this.$refs['youtube-player']) {
              if (this.ytPlayer) {
                this.ytPlayer.destroy()
              }

              this.ytPlayer = YouTubePlayer('youtube-player', {
                videoId: this.details.value,
                playerVars: {
                  'rel': 0,
                  'disablekb': 1
                }
              })
            }

            if (this.details.type === 'vimeo') {
              if(this.ytPlayer) {
                this.ytPlayer.destroy();
              }
              if(Object.keys(this.vimeoPlayer).length !== 0) {
                this.vimeoPlayer.destroy();
              }

              let options = {
                id: this.details.value,
                dnt: true,
              }
              /* eslint-disable no-new */
              this.vimeoPlayer = new Vimeo('vimeo-player', options)
            }
          }
        })
      }
    },
    computed: {
      navigationObjects () {
        return this.page.videos
      },
      details () {
        if (this.page.videos.length === 1) {
          return this.page.videos[0]
        }

        return this.page.videos.find(p => p.id === parseInt(this.$route.params.param1))
      },
      numVids () {
        return this.page.videos.length
      }
    },
    watch: {
      '$route.path' () {
        this.initVideo()
      }
    },
    mounted () {
      this.initVideo()
    }
  }
</script>

<style lang="sass" type="text/sass">
.video-wrapper
  margin-bottom: 30px

.video-wrapper-16-9
  position: relative
  padding-bottom: 56.25% /* 16:9 */
  padding-top: 25px
  height: 0

  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.page-video-detail
  .video-title
    font-weight: 600
</style>
