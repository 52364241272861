import axios from 'axios'
import { env } from './env'
import { useAppUi } from '@/stores/app-ui'
import { useVisitor } from '@/stores/visitor'

function getHeaders () {
  let headers = {
    'x-momice-url': window.location.hostname,
    'x-momice-version': env.X_MOMICE_VERSION ? env.X_MOMICE_VERSION : 'dev'
  }

  if (window.location.search.match(/tool=true/)) {
    headers['x-from-tool'] = true
  }

  return headers
}

const http = axios.create({
  baseURL: env.BASE_URL,
  headers: getHeaders()
})

http.interceptors.request.use(async (config) => {
  const appUiStore = useAppUi()
  // Do something before request is sent
  let AUTH_TOKEN = localStorage.getItem('x-momice-token')
  appUiStore.setAjaxLoading(true)
  if (AUTH_TOKEN) {
    config.headers['x-momice-token'] = AUTH_TOKEN
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

http.interceptors.response.use((response) => {
  const appUiStore = useAppUi()
  const visitorStore = useVisitor()
  // force refresh if applicable
  if (response.headers['x-force-refresh']) {
    window.location.reload(true)
  } else {
    let AUTH_TOKEN = response.headers['x-momice-token']
    appUiStore.setAjaxLoading(false)
    if (AUTH_TOKEN !== undefined && AUTH_TOKEN !== null) {
      localStorage.setItem('x-momice-token', AUTH_TOKEN)
      visitorStore.token(AUTH_TOKEN)
    }

    return response
  }
}, (error) => {
  const appUiStore = useAppUi()
  appUiStore.setAjaxLoading(false)
  if (typeof error === 'object' && error.response) {
    let { status } = error.response
    // removed 'data' field check for survey validation
    // should check 'data'?! for Axios network error
    if (!status) {
      window.handle_error(error.response, 'axios http.interceptor')
    } else {
      return Promise.reject(error)
    }
  } else {
    return Promise.reject(error)
  }
})

export default http
